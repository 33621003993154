import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { CloudArrowUp } from "phosphor-react";
import WatchLoader from "@images/watch_loader.svg";

export const FileUploadButton = ({ upload, bottomContent, label }) => {
  const [loading, setLoading] = useState(false);
  const onDrop = async (acceptedFiles) => {
    if (loading) return;

    setLoading(true);
    // Need to do this synchronously to prevent more than two images being uploaded.

    for (let idx = 0; idx < acceptedFiles.length; idx++) {
      await upload(acceptedFiles[idx]);
    }

    setLoading(false);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className="file-upload-button">
      <input {...getInputProps()} />
      {loading ? <img src={WatchLoader} /> : <CloudArrowUp size={28} />}
      <div>
        {label || (
          <>
            <span className="font-bold">Choose a file</span> or drag here
          </>
        )}
      </div>
      <div>{bottomContent}</div>
    </div>
  );
};
