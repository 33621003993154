import React from "react";
import { ActionInfo } from "components";
import { dateToDisplay } from "global-helpers/listing";

/*
Once we have a form that contains many fields. To avoid duplicating the whole forme,
I added this component to centralize the isShowPage condition.
*/

const BOOL_AS_TEXT = ["No", "Yes"];

const InputWrapper = ({ children, isShowPage, label, value, action, type }) => {
  const getDisplayText = () => {
    if (value instanceof Array) return value.join(", ");
    if (type == "date") return dateToDisplay(value);
    if (type == "bool") return BOOL_AS_TEXT[value];

    return value;
  };

  if (type == "link") {
    action = value;
  }

  return isShowPage ? (
    <ActionInfo
      label={label.replace("*", "")}
      noAction={!action}
      href={action != "copy" && action}
      renderHTML={type == "html"}
      isShowPage
    >
      {getDisplayText()}
    </ActionInfo>
  ) : (
    React.Children.map(children, (child) =>
      // Using it be possible to declare the the label + value just once
      React.cloneElement(child, { ...child.props, label, value })
    )
  );
};

export default InputWrapper;
