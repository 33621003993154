import React from "react";
import classNames from "classnames";
import "./styles";
import { Link } from "react-router-dom";

export const Button = ({
  children,
  onClick,
  disabled,
  href,
  className,
  isSpa,
  variant = "secondary",
}) => {
  if (isSpa)
    return (
      <Link
        to={href}
        className={classNames(
          `form-button form-button--${variant}`,
          className,
          {
            "form-button--disabled": disabled,
          }
        )}
      >
        {children}
      </Link>
    );

  const handleOnclick = () => {
    if (disabled || !onClick) return;

    onClick();
  };

  return !href && variant === "primary" ? (
    <button
      type="submit"
      onClick={handleOnclick}
      className={classNames("form-button form-button--primary", className)}
    >
      {children}
    </button>
  ) : (
    <a
      href={href || "javascript:void(0);"}
      onClick={handleOnclick}
      className={classNames(`form-button form-button--${variant}`, className, {
        "form-button--disabled": disabled,
      })}
    >
      {children}
    </a>
  );
};
