<template>
  <div id="features-and-specs" class="card shadow-sm content-card">
    <h3 class="mb-4">
      Features and specs
    </h3>
    <b-row>
      <b-col md="12">
        <ListingFormMultiselect v-model="listing.alternate_reference_numbers"
          label="Alternate Reference Numbers"
          :disabled="hasSpec"
          :taggable="true"
          />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormSelect v-model="listing.approximate_age"
          label="Approximate Age"
          :options="options.approximate_age"
          :required="isRequired('approximate_age')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormSelect v-model="listing.year"
          label="Year"
          :blank_element="true"
          :options="options.year" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormSelect v-model="listing.gender"
          label="Gender"
          @input="generateDescriptionFields"
          :disabled="hasSpec"
          :options="options.gender"
          :required="isRequired('gender')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormMultiselect v-model="listing.dial_color"
          label="Dial Colors"
          :disabled="hasSpec"
          :options="options.dial_colors"
          :required="isRequired('dial_color')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormMultiselect v-model="listing.case_materials"
          label="Case Materials"
          :disabled="hasSpec"
          :options="options.case_materials"
          :required="isRequired('case_materials')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <NumericInput v-model.number="listing.case_size"
          label="Case Size (mm)"
          :disabled="hasSpec"
          :options="options.case_size"
          :required="isRequired('case_size')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormSelect v-model="listing.case_shape"
          label="Case Shape"
          :disabled="hasSpec"
          :options="options.case_shape"
          :blank_element="true"
          :required="isRequired('case_shape')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormSelect v-model="listing.case_back"
          label="Case Back"
          :disabled="hasSpec"
          :options="options.case_back"
          :required="isRequired('case_back')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <NumericInput label="Case Thickness (mm)"
          :disabled="hasSpec"
          v-model="listing.case_thickness"
          :required="isRequired('case_thickness')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormMultiselect v-model="listing.band_materials"
          label="Band Materials"
          @input="generateDescriptionFields"
          :disabled="hasSpec"
          :options="options.band_materials"
          :required="isRequired('band_materials')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormSelect v-model="listing.bracelet_name"
          label="Bracelet Name"
          :disabled="hasSpec"
          :blank_element="true"
          :options="options.bracelet_name" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <NumericInput v-model="listing.lug_width"
          label="Lug Width (mm)"
          :disabled="hasSpec"
          :required="isRequired('lug_width')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormSelect v-model="listing.crystal_material"
          label="Crystal Material"
          :disabled="hasSpec"
          :options="options.crystal_material"
          :required="isRequired('crystal_material')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormMultiselect v-model="listing.bezel_materials"
          label="Bezel Materials"
          @input="generateDescriptionFields"
          :disabled="hasSpec"
          :options="options.bezel_materials"
          :required="isRequired('bezel_materials')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormMultiselect v-model="listing.bezel_features"
          label="Bezel Features"
          :disabled="hasSpec"
          :options="options.bezel_features" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormMultiselect v-model="listing.bezel_insert_materials"
          label="Bezel Insert Materials"
          :disabled="hasSpec"
          :options="options.bezel_insert_materials" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormMultiselect v-model="listing.bezel_insert_colors"
          label="Bezel Insert Colors"
          :disabled="hasSpec"
          :options="options.bezel_insert_colors" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormSelect v-model="listing.movement_type"
          label="Movement Type"
          :disabled="hasSpec"
          :options="options.movement_type"
          :required="isRequired('movement_type')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormInput label="Manufacturer Caliber"
          v-model="listing.manuf_caliber"
          :disabled="hasSpec"
          :required="isRequired('manuf_caliber')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormInput label="Base Caliber"
          v-model="listing.base_caliber"
          :disabled="hasSpec" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <NumericInput label="Power Reserve (hours)"
          :disabled="hasSpec"
          v-model="listing.power_reserve" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormInput label="Jewels"
          type="text"
          :disabled="hasSpec"
          v-model="listing.jewels" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormMultiselect v-model="listing.complications"
          label="Complications"
          :disabled="hasSpec"
          :options="options.complications" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormSelect v-model="listing.calendar_type"
          label="Calendar Type"
          :disabled="hasSpec"
          :blank_element="true"
          :options="options.calendar_type" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <NumericInput label="Water Resistance (m)"
          :disabled="hasSpec"
          v-model="listing.water_resistance"
          :required="isRequired('water_resistance')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormMultiselect v-model="listing.style"
          label="Style"
          :disabled="hasSpec"
          :options="options.style" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormSelect v-model="listing.analog_digital"
          label="Display Type"
          :disabled="hasSpec"
          :options="options.analog_digital"
          :blank_element="true"
          :required="isRequired('analog_digital')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormSelect v-model="listing.edition_type"
          label="Edition Type"
          @input="handleEditionTypeChange"
          :disabled="hasSpec"
          :blank_element="true"
          :options="['', ...options.edition_type]" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <NumericInput v-model="listing.limited_quantity"
          label="Limited Quantity"
          :disabled="hasSpec"
          :required="isRequired('limited_quantity')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormSelect v-model="listing.years_released"
          label="Year Released"
          :disabled="hasSpec"
          :blank_element="true"
          :options="options.year" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormSelect v-model="listing.years_stopped"
          label="Year Stopped"
          :disabled="hasSpec"
          :blank_element="true"
          :options="options.year" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <b-form-group label="Aftermarket Replacements">
          <toggle-button
            :width="75"
            :height="44"
            :color="{checked: '#75C791', unchecked: '#F2F2F2', disabled: '#F2F2F2'}"
            :switch-color="{checked: '#FFFFFF', unchecked: '#CCCCCC', disabled: '#CCCCCC'}"
            :margin="6"
            :value="listing.aftermarket_replacements || false"
            @change="listing.aftermarket_replacements = $event.value"
            :disabled="hasSpec"/>
      </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <b-form-group label="Aftermarket Additions">
          <toggle-button
            :width="75"
            :height="44"
            :color="{checked: '#75C791', unchecked: '#F2F2F2', disabled: '#F2F2F2'}"
            :switch-color="{checked: '#FFFFFF', unchecked: '#CCCCCC', disabled: '#CCCCCC'}"
            :margin="6"
            :value="listing.aftermarket_additions || false"
            @change="listing.aftermarket_additions = $event.value"
            :disabled="hasSpec"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <b-form-group label="Manufacturer Diamonds">
          <toggle-button
            :width="75"
            :height="44"
            :color="{checked: '#75C791', unchecked: '#F2F2F2', disabled: '#F2F2F2'}"
            :switch-color="{checked: '#FFFFFF', unchecked: '#CCCCCC', disabled: '#CCCCCC'}"
            :margin="6"
            :value="listing.manuf_diamonds || false"
            @change="listing.manuf_diamonds = $event.value"
            :disabled="hasSpec"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <b-form-group label="Diamond Dial">
          <toggle-button
              :width="75"
              :height="44"
              :color="{checked: '#75C791', unchecked: '#F2F2F2', disabled: '#F2F2F2'}"
              :switch-color="{checked: '#FFFFFF', unchecked: '#CCCCCC', disabled: '#CCCCCC'}"
              :margin="6"
            :value="listing.diamond_dial || false"
            @change="listing.diamond_dial = $event.value"
            :disabled="hasSpec" />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '../stores/listingFormStore';
import NumericInput from './NumericInput';
import ListingFormInput from './ListingFormInput';
import ListingFormSelect from './ListingFormSelect';
import ListingFormMultiselect from './ListingFormMultiselect';
import formUtils from '../services/formUtils';
import utils from '../services/utils';

export default {
  name: 'features-and-specs',
  components: {
    NumericInput,
    ListingFormInput,
    ListingFormSelect,
    ListingFormMultiselect,
  },
  data() {
    return {
      state: store.state,
    };
  },
  computed: {
    listing() { return store.state.listing; },
    options() { return store.state.globalAttributes; },
    hasSpec() { return formUtils.hasSpec(store.state) },
  },
  methods: {
    generateDescriptionFields: utils.debounce(() => {
      store.generateDescriptionFields;
    }, 800),
    handleEditionTypeChange: store.handleEditionTypeChange,
    isRequired(field) { return formUtils.isRequired(field, store.state) }
  },
};

</script>
