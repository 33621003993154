export default class ImageValidator{
  constructor(images, imageType, numberOfAddedFiles = 0, addedImageNames, lifestyle_picture){
    this.images = images;
    this.imageType = imageType;
    this.numberOfAddedFiles = numberOfAddedFiles;
    this.imageInfos = [];
    this.addedImageNames = addedImageNames;
    this.lifestyle_picture = lifestyle_picture;
    this.validatingData = { status: true };
  }

  async perform() {
    await this.gatherInfo();
    this.validateDimensions();
    this.validateNumberOfImages();
    if (this.lifestyle_picture)
      this.validateImageNames();

    return this.validatingData;
  }

  async gatherInfo(){
    const filePromises = this.images.map((image) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async () => {
          const tempImage = new Image();
          tempImage.src = reader.result;
          tempImage.onload = function() {
            resolve({
              name: image.name,
              size: (image.size / 1048576).toFixed(2),
              width: this.width,
              height: this.height
            })
          }
        };
        reader.readAsDataURL(image);
      });
    });
  
    this.imageInfos = await Promise.all(filePromises);
  }

  validateDimensions() {
    for (const { width, height, size } of this.imageInfos) {    
      if(width > 4472 || height > 4472 || size > 20) {
        this.validatingData = { status: false, errorMessage: 'Image can’t be larger than 4472 x 4472 px or 20 MB' };
        break;
      }
    }
  }

  validateNumberOfImages() {
    if(this.imageType === 'lifestyle' && (this.images.length + this.numberOfAddedFiles) > 2) {
      this.validatingData = { status: false, errorMessage: 'You cannot have more than 2 lifestyle photos' };
    }
  }

  validateImageNames() {
    for (const { name } of this.imageInfos) {
      if(this.addedImageNames.includes(name)) {
        this.validatingData = { status: false, errorMessage: 'Duplicate file names detected. Please add another image.' };
        break;
      } else {
        this.addedImageNames.push(name);
      }
    }
  };
}
