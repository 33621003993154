import cloudinaryServiceFactory from '../services/cloudinaryServiceFactory';
import listingMapper from '../services/listingMapper';
import portalService from '../services/portalService';
import priceUtils from '../services/priceUtils';
import specsService from '../services/specsService';
import validator from '../services/listingFormValidator';
import utils from '../services/utils';

let state = {};

const cloudinary = {};

const store = {
  state,
  cloudinary,

  appendImage,
  displayDescription,
  fetchCcSpecData,
  generateDescriptionFields,
  handleBrandChange,
  handleFileSelect,
  handleUploadError,
  cleanUploadError,
  handleDropshipCostChange,
  handleImageCrop,
  handleListPriceChange,
  handleModelNameChange,
  handleModelNumberChange,
  handleMerchModelNumberChange,
  handleEditionTypeChange,
  handleNicknameChange,
  handleRemoveImage,
  handleSelectToCrop,
  hasErrors,
  setInitialState,
  submitForm,
  populateConditionNotes,
  updateImages,
  removeCcspec,
};

function appendImage({ data }, uploadType) {
  const img = cloudinary.mapResponse(data);
  const imageType = uploadType == 'lifestyle' ? 'lifestyleImages' : 'images';
  if (imageType === 'lifestyleImages') {
    const { original_filename, original_extension, format } = data;
    img.source_file_name = `${original_filename}.${original_extension || format}`;
  }
  state[imageType].push(img);
  updateState({
    [imageType]: state[imageType]
      .sort((a, b) => (a.source_file_name || '') > (b.source_file_name || ''))
      .map((x, i) => Object.assign(x, { sort_order: i + 1 })),
  });
}

function brandId(brandName) {
  return brandName && state.brands.find(b => b.name === brandName).id || null;
}

function defaultAttributes(attrs = {}) {
  return listingMapper.fromModel(attrs);
}

function descriptionRequiredFields() {
  const requiredFields = [
    state.modelName,
    state.modelNumber,
    state.brandName,
    state.listing.condition,
  ];
  return requiredFields.reduce((p, c) => p && !!c, true);
}

function displayDescription() {
  if (state.listing.custom_description)
    return state.listing.custom_description;

  return state.generatedDescription && [
    state.generatedDescription,
    state.listing.secondary_description,
  ].filter(x => x).join(' ');
}

function fetchListerNotes() {
  specsService.ccspec(state.ccspecNumber, (res) => {
    const notes = res.ccspec.check_for;
    if (notes)
      updateState({ listerNotes: { check_for: notes } });
  });
}

function fetchCcSpecData() {
  specsService.ccspec(state?.listing?.ccspec_number, (res) => {
    if (!res || !res.ccspec || typeof res.ccspec !== 'object') return;
    const { model_name } = res.ccspec;
    delete res.ccspec.model_name;
    updateListing({
      ...res.ccspec,
      "_model_name": model_name,
      manuf_caliber: res.ccspec.manuf_caliber.join(", "),
      base_caliber: res.ccspec.base_caliber.join(", "),
      jewels: res.ccspec.jewels[0],
    });
  })
}

function generateDescriptionFields() {
  portalService.generatedDescription(listingMapper.toModel(state), (res) => {
    if (descriptionRequiredFields() && res && res.description) {
      updateState({ generatedDescription: res.description });
    } else {
      updateState({ generatedDescription: '' });
    }

    if (res && res.merchandising_name) {
      updateState({ merchandisingName: res.merchandising_name });
      updateListing({ merchandising_name: res.merchandising_name });
    }
  });
}

function populateConditionNotes() {
  const notes = `This ${state.listing.merchandising_name} is in overall ${state.listing.condition.toLowerCase()} pre-owned condition. `;
  updateState({ conditionNotes: notes });
  updateListing({ condition_notes: notes });
}

function globalAttributes(attrs) {
  return Object.assign(attrs, {
    approximate_age: ['', '1950s', '1960s', '1970s', '1980s', '1990s', '2000s', '2010s', '2020 - Present'],
    condition: ['', 'Excellent', 'Very Good'],
    dial_color: attrs.dial_colors,
    crystal_material: attrs.crystal_materials,
    year: yearOptions(),
  });
}

function handleBrandChange(brandName) {
  updateState({
    ccspec: null,
    ccspecs: [],
    modelName: null,
    modelsLoaded: false,
  });
  updateListing({ merchandising_name: null });
  generateDescriptionFields();
  specsService.models(brandId(brandName), setModels);
}

function handleFileSelect(files, uploadType, successCallback = () => {}) {
  updateState({
    [`${uploadType}Uploading`]: true,
    [`${uploadType}UploadErrors`]: [],
  });

  files && cloudinary.uploadFiles(files)
    .then(res => res.forEach((file) => { appendImage(file, uploadType); successCallback() }))
    .catch((e) => { handleUploadError(e, uploadType) })
    .finally(() => updateState({ [`${uploadType}Uploading`]: false }));
}

function handleUploadError(e, uploadType) {
  if ((typeof e.response !== 'undefined' && e.response !== null) && e.response.status === 400) {
    updateState({ [`${uploadType}UploadErrors`]: [...state[`${uploadType}UploadErrors`], e.response.data.error.message] });
  } else {
    updateState({ [`${uploadType}UploadErrors`]: [...state[`${uploadType}UploadErrors`], e.toString()] });
  }
}

function cleanUploadError(uploadType) {
  updateState({ [`${uploadType}UploadErrors`]: [] });
}


function handleDropshipCostChange(newVal) {
  state.lastPriceAdjusted = 'dropship';
  updateListing({ dropship_cost_precise: priceUtils.formatToNumber(newVal) });
}

async function handleImageCrop(cropData) {
  // We'll check if image link was updated on server (they are renamed on upload)
  let data = await fetch('listings/' + state.lid + '?images_only=true', {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  })
  .then((response) => {
    return response.json();
  })
  .catch(() => {
    return null;
  });

  let reloadedImage
  if (data) {
    reloadedImage = data.images.concat(data.lifestyle_images).find(i => i.id === state.imageToCrop.id);
  }

  const pathSourceImage = reloadedImage ? reloadedImage : state.imageToCrop;

  Object.assign(state.imageToCrop, {
    cropped_path: cloudinary.cropImage(pathSourceImage, cropData),
  });

  handleSelectToCrop(null);
}

function handleListPriceChange(newVal) {
  state.lastPriceAdjusted = 'list';
  updateListing({ list_price_precise: priceUtils.formatToNumber(newVal) });
}

function handleModelNameChange(modelName) {
  updateState({
    modelNumber: null,
    modelNumbersLoaded: false,
  });
  generateDescriptionFields();
  const model = state.models.find(x => x.name === modelName);

  model && model.id ? specsService.modelNumbers(model.id, setModelNumbers) : setModelNumbers();
}

function handleModelNumberChange() {
  generateDescriptionFields();
}

function handleMerchModelNumberChange() {
  generateDescriptionFields();
}

function handleEditionTypeChange() {
  state.listing.limited_quantity = null
}

function handleNicknameChange() {
  generateDescriptionFields();
}

function handleRemoveImage(img, imageType = 'product') {
  if (imageType == 'product' && state.listing.status === 'listed' && state.images.length <= 2)
    return false;

  const imageStore = imageType == 'lifestyle' ? 'lifestyleImages' : 'images';

  updateState({
    [imageStore]: state[imageStore].filter(x => x !== img)
      .map((x, i) => Object.assign(x, { sort_order: i + 1 })),
  });
  return true;
}

function handleSelectToCrop(img) {
  updateState({ imageToCrop: img });
  const headerOffset = 100;

  window.scrollTo(0, window.scrollY - headerOffset);
}

function hasErrors() {
  return !!Object.keys(state.errors).length;
}

function hasSpec(listing) {
  return !!listing.ccspec_number && /CCS-...-.*/.test(listing.ccspec_number);
}

function initialImages(images) {
  return images.sort((a, b) => a.sort_order - b.sort_order)
    .map(img => Object.assign(img, { url: cloudinary.url(img.path) }));
}

function prepShops(shops) {
  return Object.keys(shops).map(s => ({
    value: s,
    text: shops[s]
  }));
}

function setModelNumbers(res) {
  const modelNumbers = (res || { model_numbers: [] }).model_numbers;
  updateState({ modelNumbers, modelNumbersLoaded: true });
}

function setInitialState(data) {
  Object.assign(cloudinary, cloudinaryServiceFactory(data.cloudinaryAuth));
  specsService.init(data.specsUrl, data.specsCredsUsername, data.specsCredsPassword);

  const listing = defaultAttributes(data.listing);

  updateState({
    brandName: listing.brand_name,
    brands: data.specsData.brands,
    ccspecNumber: data.listing.ccspec_number,
    displayDescription: 'loading...',
    errors: { messages: [], maxWristSize: [] },
    globalAttributes: globalAttributes(data.globalValues),
    generatedDescription: 'loading...',
    ccspecHodinkeeIneligible: getCcspecHodinkeeIneligible(data.specsData.ccspecs, data.listing.ccspec_number),
    imageToCrop: { url: null },
    images: initialImages(data.images),
    lifestyleImages: initialImages(data.lifestyleImages),
    userManager: data.userManager,
    lid: data.lid,
    listing,
    listerNotes: [],
    modelName: listing._model_name,
    modelNumber: listing.model_number,
    modelNumbers: data.specsData.modelNumbers,
    nickname: listing.nickname,
    editionType: listing.edition_type,
    models: data.specsData.models,
    modelsLoaded: true,
    modelNumbersLoaded: false,
    newListing: !data.lid,
    requiredFields: data.requiredFields,
    shops: prepShops(data.shops),
    productUploading: false,
    lifestyleUploading: false,
    productUploadErrors: [],
    lifestyleUploadErrors: [],
    user: data.user,
    valid: true,
    waiting: false,
    warnings: data.warnings || {},
  });

  generateDescriptionFields();

  if (hasSpec(listing)) {
    fetchListerNotes();
  }
}

function setModels({ models }) {
  updateState({ models, modelsLoaded: true });
}

function submissionSuccess({ data }) {
  if (data.errors) {
    submissionFail({ data });
  } else {
    updateState({ waiting: false });
    data.lid && window.location.replace(`/listings/${data.lid}`);
  }
}

function submissionFail(res = {}) {
  const { data } = res;

  if (data) {
    const { errors = {} } = data;
    const fullMessages = Object.entries(errors)
      .filter(([k]) => k !== 'base')
      .map(([k, v]) => `${utils.humanize(k)}: ${v}`);

    updateState({
      errors: Object.assign({ messages: data.errors.base }, {
        messages: [
          'There was a problem processing submission',
          ...fullMessages,
        ],
      }),
    });
  } else {
    updateState({
      errors: Object.assign({}, state.errors, {
        messages: ['There was a problem processing submission'],
      }),
    });
  }

  updateState({ waiting: false });
  window.scrollTo(0, 0);
}

function submitForm() {
  updateState({ waiting: true });
  // validator assigns values to state.errors
  state.valid = validator.validateModel(state);

  if (state.valid) {
    updateState({ errors: {} });
    const payload = listingMapper.toModel(state);
    return portalService.listing(payload)
      .then(submissionSuccess, submissionFail)
      .catch(submissionFail);
  }

  return submissionFail();
}

function updateImages(newOrder, imageType) {
  const imageStore = imageType == 'lifestyle' ? 'lifestyleImages' : 'images';

  updateState({
    [imageStore]: newOrder.map((img, i) => Object.assign(img, { sort_order: i + 1 })),
  });
}

function removeCcspec() {
  Object.assign(state.listing, { ccspec_number: null });
}

function updateListing(attrs) {
  Object.assign(state.listing, attrs);
}

function updateState(attrs) {
  Object.assign(state, attrs);
}

function getCcspecHodinkeeIneligible(ccspecs, current_ccspec_number ) {
  if (!ccspecs) return false;
  const current_ccspec = ccspecs.find(({ ccspec_number }) => ccspec_number == current_ccspec_number)

  if (!current_ccspec) return false;
  return current_ccspec.hodinkee_ineligible
}

function yearOptions(startYear = 1900) {
  const year = (new Date()).getFullYear();
  const years = Array.from(new Array(year - startYear))
    .map((_, i) => (year - i).toString());
  return ['', ...years];
}

export default store;
