import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { sortFn, mapFn, reduceFn } from "./helpers";
import ImageItem from "./photo_gallery_image_item";
import { ResetEditsButton } from "components";
import PhotoGalleryUpload from "./photo_gallery_upload";
import { uploadToCloudinary } from "global-helpers/listing";
import { ListManager } from "react-beautiful-dnd-grid";
import { protectedStates } from "../../util";

const MAX_MB_SIZE = 20;
const MAX_IMAGE_SIZE = MAX_MB_SIZE * 1024 * 1024; // 20MB in bytes
const MAX_IMAGE_DIMENSION = 4472; // 4472 x 4472 px

const addUrlAndSort = (arr) => (arr || []).sort(sortFn).map(mapFn);

const imageWithinSizeConstraints = async (file) => {
  // Check file size in bytes.
  if (file.size > MAX_IMAGE_SIZE) {
    alert(
      `The image you have selected is too large. Images must be smaller than ${MAX_MB_SIZE}MB.`
    );
    return false;
  }
  // We must create an image element to check length x width dimensions.
  const img = new Image();
  img.src = URL.createObjectURL(file);
  await new Promise((resolve) => (img.onload = resolve));

  if (img.width > MAX_IMAGE_DIMENSION || img.height > MAX_IMAGE_DIMENSION) {
    alert(
      `Image dimensions are too large. Must be smaller than ${MAX_IMAGE_DIMENSION}x${MAX_IMAGE_DIMENSION}`
    );
    return false;
  }
  // All checks passed, return true.
  return true;
};

export const PhotoGallery = forwardRef(
  (
    {
      images,
      lifestyleImages,
      merchandisingName,
      state,
      ccSpecNumber,
      isShowPage,
      customizedFields = [],
      setCustomizedFields = () => {},
      setFeaturedImage = () => {},
    },
    ref
  ) => {

    const [gallery, setGallery] = useState(addUrlAndSort(images));
    const [lifestyleGallery, setLifestyleGallery] = useState(
      addUrlAndSort(lifestyleImages)
    );

    const onDragEnd = (sourceIndex, destinationIndex, isLifestyle = false) => {
      // Do nothing if the item was dropped in the same place it started.
      if (sourceIndex == destinationIndex) {
        return;
      }
      // Else, move
      moveCard(sourceIndex, destinationIndex, isLifestyle);
    };

    const remove = (index, lifeStyle) => {
      const setGalleryFn = (oldGallery) => {
        return [...oldGallery]
          .filter((item) => item.sort_order !== index)
          .map((item, i) => {
            item.sort_order = i;
            return item;
          });
      };

      if (lifeStyle) return setLifestyleGallery(prev => setGalleryFn(prev));

      setGallery(prev => setGalleryFn(prev));
    };

    const moveCard = (sourceIndex, destinationIndex, lifeStyle) => {
      const setGalleryFn = (oldGallery) => {
        const result = Array.from(oldGallery);
        const [removed] = result.splice(sourceIndex, 1);
        result.splice(destinationIndex, 0, removed);

        return result.map((item, index) => ({
          ...item,
          sort_order: index,
        }));
      };

      if (lifeStyle) return setLifestyleGallery(prev => setGalleryFn(prev));

      setGallery(prev => setGalleryFn(prev));
    };

    useEffect(() => {
      setFeaturedImage(gallery[0]);
    }, [gallery[0]?.path]);

    const upload = async (file, lifeStyle) => {
      const isValidSize = await imageWithinSizeConstraints(file);
      if (!isValidSize) return;

      const newImage = await uploadToCloudinary(file, gallery.length);
      const setGalleryFn = (oldGallery) => {
        if (!lifeStyle || oldGallery.length < 2) {
          return addUrlAndSort([...oldGallery, newImage]);
        }
        alert("You can only upload 2 lifestyle images");
        return oldGallery;
      };

      if (lifeStyle) return setLifestyleGallery(prev => setGalleryFn(prev));

      setGallery(prev => setGalleryFn(prev));
    };

    const onResetCustomImages = () => {
      customizedFields.splice(customizedFields.indexOf("lifestyle_images"), 1);
      setCustomizedFields([...customizedFields]);
    };

    useImperativeHandle(ref, () => ({
      getFormState: () => ({
        images: gallery,
        lifestyle_images: lifestyleGallery,
      }),
    }));

    const isCustom = customizedFields.includes("lifestyle_images");

    return (
      <div className="edit-form__container__content">
        <h2>Photo Gallery</h2>
        <div className="mb-5">
          <h3>Product Photos</h3>
          {isShowPage && gallery.length === 0 && (
            <p>No product images added yet</p>
          )}
          {!isShowPage && gallery.length < 2 && (
            <p>You must add at least two product images.</p>
          )}
          <div className="gallery-grid">
            <ListManager
              items={gallery}
              direction="horizontal"
              maxItems={2}
              render={({ path, url, sort_order }) => (
                <ImageItem
                  sortOrder={sort_order}
                  path={path}
                  url={url}
                  state={state}
                  merchandisingName={merchandisingName}
                  onRemove={() => remove(sort_order)}
                />
              )}
              onDragEnd={(source, destination) => onDragEnd(source, destination, false)}
            />
          </div>
          {!isShowPage && !protectedStates.includes(state) && <PhotoGalleryUpload upload={upload} />}
        </div>
        <div>
          <h3>
            Lifestyle Photos
            {ccSpecNumber && isCustom && (
              <ResetEditsButton
                resetKey="lifestyle_pictures"
                onReset={onResetCustomImages}
                text="Re-enable sync without resetting images"
              />
            )}
          </h3>
          {isShowPage && lifestyleGallery.length === 0 && (
            <p>No lifestyle images added yet</p>
          )}
          <div className="gallery-grid">
            <ListManager
              items={lifestyleGallery}
              direction="horizontal"
              maxItems={2}
              render={({ id, path, url, sort_order }, idx) => (
                <ImageItem
                  sortOrder={sort_order}
                  path={path}
                  url={url}
                  state={state}
                  merchandisingName={merchandisingName}
                  onRemove={() => remove(sort_order, true)}
                />
              )}
              onDragEnd={(source, destination) => onDragEnd(source, destination, true)}
            />
          </div>
          {!isShowPage && lifestyleGallery.length < 2 && !protectedStates.includes(state) && (
            <PhotoGalleryUpload upload={(file) => upload(file, true)} />
          )}
        </div>
      </div>
    );
  }
);
