import React from "react";
import { UserCircle, SignOut } from "phosphor-react";

const BottomLinks = ({ fullName }) => (
  <>
    <li>
      <div className="item-element">
        <UserCircle size={24} />
        {fullName}
      </div>
    </li>
    <li>
      <a rel="nofollow" data-method="delete" href="/users/sign_out">
        <SignOut size={24} />
        Log Out
      </a>
    </li>
  </>
);

export default BottomLinks;
