<template>
  <div :id="`${imageType}-gallery`" class="card shadow-sm content-card">
    <span>
      <span v-if="customized" class="inline"><i class="fa fa-pencil fa-sm green-text p-b-0-75 m-r-sm"></i></span>
      <span class="inline">
        <h3 class="mb-4">
          {{ imageType == 'product' ? 'Product Gallery' : 'Lifestyle Photography' }}
        </h3>
      </span>
    </span>
    <span v-if="customized"
      class="field-dropdown-icon field-dropdown-icon-card-title"
      id="lifestyle-images-customized-dropdown"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false">
      <i class="fa fa-ellipsis-v"></i>
    </span>
    <div v-if="customized" class="dropdown-menu" aria-labelledby="lifestyle-images-customized-dropdown">
      <a class="dropdown-item"
        href="#"
        @click.prevent="reenableSync()">Re-enable sync without resetting images</a>
    </div>
    <b-row>
      <b-col md="12">
        <b-form-group>
          <b-row v-if="images.length">
            <b-col sm="12" class="padding">
              <draggable v-model="images">
                <transition-group>
                  <div v-for="img in images" :key="img.public_id">
                    <ImageCard :img="img"
                    :select="handleSelectToCrop"
                    :remove="handleRemoveImage"
                    :showFeatured="imageType == 'product'"
                    :editable="!listed" />
                  </div>
                </transition-group>
              </draggable>
            </b-col>
          </b-row>
          <b-row v-if="!listed">
            <b-col sm="12" class="padding">
              <b-form-file
                ref="imageFileUploader"
                choose-label="Upload Images"
                class="image-upload"
                @input="upload"
                multiple />
              <br  />
              <b-progress :value="100" :max="100" animated v-if="state[`${imageType}Uploading`]" class="mt-3" />
              <b-alert v-if="uploadErrors.length" show variant="danger">
                <ul>
                  <li v-for="error in uploadErrors"> {{ error }} </li>
                </ul>
              </b-alert>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="modal-window" v-if="state.imageToCrop && state.imageToCrop.url">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-body">
              <ImageCropper v-if="state.imageToCrop && state.imageToCrop.url"
                :image="state.imageToCrop"
                :onCrop="handleImageCrop"
                :onCancel="handleCancelCrop" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import ImageCard from './ImageCard';
import ImageCropper from './ListingImageCropper';
import store from '../stores/listingFormStore';
import formUtils from '../services/formUtils';
import ImageValidator from '../services/imageValidator';

export default {
  name: 'image-gallery',
  components: {
    draggable,
    ImageCard,
    ImageCropper,
  },
  props: {
    imageType: {
      type: String,
      validator: propValue => ['product', 'lifestyle'].includes(propValue)
    }
  },
  data() {
    return {
      state: store.state,
      cloudinary: store.cloudinary,
      uploadedImagesFilenames: [],
    };
  },
  computed: {
    images: {
      get() { return this.imageType == 'product' ? this.state.images : this.state.lifestyleImages },
      set(data) { store.updateImages(data, this.imageType) },
    },
    uploadErrors() {
      return this.state[`${this.imageType}UploadErrors`];
    },
    customized() {
      return this.imageType == 'product' ? false : formUtils.isCustomized('lifestyle_images', this.state);
    },
    listed() {
      return ['listed', 'ready_to_list'].includes(this.state.listing.status);
    },
  },
  methods: {
    handleCancelCrop() { store.handleSelectToCrop(null) },
    async handleImageCrop(data) { await store.handleImageCrop(data) },
    handleRemoveImage,
    handleSelectToCrop: store.handleSelectToCrop,
    async upload(data) {
      store.cleanUploadError(this.imageType);
      const validator = new ImageValidator(
        data, this.imageType, this.images.length, this.uploadedImagesFilenames, this.imageType === 'lifestyle'
      );
      const { status, errorMessage } = await validator.perform();
      if(status) {
        if (this.imageType === 'lifestyle')
          validateBynderUniqueness(data);
        this.$emit('imageUploaded');
        store.handleFileSelect(data, this.imageType,
          this.$refs.imageFileUploader.reset
        );
      } else {
        store.handleUploadError(errorMessage, this.imageType);
      }
    },
    reenableSync() {
      for (let i = 0; i < this.state.listing.customized_fields.length; i++){
        if (this.state.listing.customized_fields[i] === 'lifestyle_images') {
          this.state.listing.customized_fields.splice(i, 1);
        }
      }
    }
  },
  watch: {
    images() {
      this.$emit('rebuildLayout');
    }
  }
}

async function validateBynderUniqueness(images) {
  for (const { name } of images) {
    const response = await axios.get(
      '/listings/validate_bynder_uniqueness',
      { params: { picture_filename: name }  },
      { headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        } }
    )
    if (!response.data.status)
      alert(`A file with name ${name} already exists in Bynder. Picture will be saved on this listing record, but will not be uploaded to Bynder.`);
  }
}

function handleRemoveImage(img) {
  store.cleanUploadError(this.imageType);
  if (this.imageType === 'lifestyle')
    this.uploadedImagesFilenames = this.uploadedImagesFilenames.filter(value => {
      return value !== img.source_file_name;
    });
  const removed = store.handleRemoveImage(img, this.imageType);
  this.$refs.imageFileUploader.reset();
  if (this.imageType == 'product')
    !removed && alert("Listed watches must maintain at least 2 images");
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); /* for IE 9 */
  -webkit-transform: translate(-50%, -50%); /* for Safari */
}

.modal-container {
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
  margin: 20px 0;
}
</style>
