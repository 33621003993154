'use strict'

import Masonry from 'masonry-layout'

let msnry

function build(reload = false) {
  if (!msnry || reload) {
    msnry = new Masonry( '.grid', {
      itemSelector: '.grid-item',
      columnWidth: '.grid-sizer',
      gutter: '.gutter-sizer',
      percentPosition: true,
    });
  }

  msnry.layout();
}

export default {
  build
};
