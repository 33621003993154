<template>
  <b-card class="mb-3">
    <i class="fa fa-2x fa-bars grip" v-if="editable" aria-hidden="true" />
    <b-badge pill v-if="img.sort_order==1 && showFeatured">Featured Image</b-badge>
    <img class="thumb" v-lazy="thumb" />
    <b-button-group class="image-action-buttons">
      <a href="javascript:void(0);" @click.prevent="downloadClImage(img.url, img.public_id)" class="btn btn-secondary material-icons">
        cloud_download
      </a>
      <b-button v-if="editable" @click="select(img)">
        <span class="material-icons">crop</span>
      </b-button>
      <b-button v-if="editable" @click="remove(img)">
        <span class="material-icons">close</span>
      </b-button>
    </b-button-group>
  </b-card>
</template>

<script>

export default {
  name: 'image-card',
  props: ['img', 'remove', 'select', 'showFeatured', 'editable'],
  computed: {
    thumb() {
      return this.img.cropped_path || this.img.url;
    },
  },
}
</script>

<style scoped>
.badge {
  font-size: 1em;
  position: absolute;
}
.badge::after {
  display: none;
}
.grip {
  cursor: grab;
}
.grip:active {
  cursor: grabbing;
}
.thumb {
  width: 225px;
  height: 225px;
  object-fit: contain;
}
</style>
