import React, { useMemo } from "react";
import { MultiSelect } from "components";
import CoverComponent from "./cover_component";
import StateChange from "./state_change";
import { MODERN_STATES } from "global-helpers/listing";

import "./styles";

const EVENTS = {
  to_new_listing: "To New",
  to_unfinished: "To Unfinished",
  to_ready_for_live: "To Not Live",
  to_live: "To Live",
  archive: "Archive",
  unarchive: "Unarchive",
};
const StateSelectComponent = ({
  value,
  onChange,
  singleValue,
  possibleEvents,
  stateChange,
  readOnly,
}) => {
  if (stateChange && !possibleEvents.length)
    return <CoverComponent item={value} />;

  const items = possibleEvents
    ? Object.keys(EVENTS).reduce(
        (acc, event) =>
          possibleEvents.includes(event)
            ? { ...acc, [event]: EVENTS[event] }
            : acc,
        {}
      )
    : Object.keys(MODERN_STATES).reduce(
        (acc, state) => ({ ...acc, [state]: MODERN_STATES[state] }),
        {}
      );

  return stateChange ? (
    <StateChange
      readOnly={readOnly}
      items={items}
      value={value}
      onChange={onChange}
    />
  ) : (
    <MultiSelect
      label="Status"
      name="states"
      value={value}
      singleValue={singleValue}
      coverComponent={stateChange && MODERN_STATES[value]}
      coverVariant={stateChange && value}
      hideSearchInput={!!stateChange}
      onChange={onChange}
      items={items}
      readOnly={readOnly}
      hideCaret={stateChange && !possibleEvents.length}
      labelCover
    />
  );
};

export default ({
  value,
  onChange,
  singleValue,
  possibleEvents,
  stateChange,
  readOnly,
}) =>
  useMemo(
    () => (
      <StateSelectComponent
        value={value}
        onChange={onChange}
        singleValue={singleValue}
        possibleEvents={possibleEvents}
        stateChange={stateChange}
        readOnly={readOnly}
      />
    ),
    [value, onChange, singleValue, possibleEvents]
  );
