<template>
  <div v-if="primaryImage">
    <a @click.prevent="togglePopup">
      <div id="primary-image" class="card shadow-sm">
        <img v-lazy="primaryImage.url" @load="$emit('rebuildLayout')"/>
        <i class="fa fa-2x fa-up-right-and-down-left-from-center expand-primary-image text-muted" />
      </div>
    </a>

    <div class="modal-window" v-if="popupOpen">
      <a @click.prevent="togglePopup">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-body">
                <img :src="primaryImage.url" />
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import store from '../stores/listingFormStore';

export default {
  name: 'primary-image',
  data() {
    return {
      state: store.state,
      popupOpen: false,
    };
  },
  computed: {
    primaryImage() {
      return this.state.images.find(obj => {
        return obj.sort_order === 1
      });
    },
  },
  methods: {
    togglePopup() {
      this.popupOpen = !this.popupOpen;
    }
  }
}

</script>

<style scoped>
  #primary-image {
    width: auto;
    height: auto;
  }

  #primary-image img{
    width: 100%;
    height: auto;
    border-radius: 0.4rem;
  }

  @supports(object-fit: contain){
    #primary-image img{
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }

  .modal-mask {
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal-wrapper {
    margin: auto;
  }

  .modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-body img{
    width: 100%;
    height: auto;
  }

  @supports(object-fit: cover){
    .modal-body img{
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }

  .expand-primary-image {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
</style>
