<template>
  <div id="image-uploads">
    <div class="dashboard-header">
      <h1>
        Bulk Image Uploads
      </h1>
    </div>
    <div class="card padding">
      <b-form-file
        choose-label="Upload Images"
        class="image-select"
        @input="select"
        directory
        multiple
      />

      <div class="padding">
        <b-progress :max="100" v-if="state.uploadStatus">
          <b-progress-bar :value="state.progressPercent" />
          <span class="progress-label">{{ state.uploadStatus }}</span>
        </b-progress>
      </div>

      <div v-if="!state.uploadStatus && state.listings.some(l => l.errors !== undefined)" class="padding">
        SKUs in red failed to update. Images were not added to these listings.
        Please ensure that these are not in the Ready to List or Listed status.
      </div>

      <ul class="pending-images" v-if="!state.uploadStatus">
        <b-row>
          <b-col xs="auto" v-for="l in state.listings" :key="l.sku" v-if="l.link">
            <li>
              <b-button
                :variant="l.errors !== undefined ? 'danger' : ''"
                :href="`${l.link}/edit#product-gallery`"
                target="_blank">
                {{l.sku}}
              </b-button>
            </li>
          </b-col>
        </b-row>
      </ul>
    </div>

  </div>
</template>

<script>
import imageUploadsStore from '../stores/imageUploadsStore.js';

let store = new imageUploadsStore();

export default {
  name: 'image-uploads',
  data() { return { state: store.state } },
  methods: {
    applyData: store.applyData,
    select: store.selectFiles,
  },
}
</script>

<style>
#image-uploads .col {
  flex-grow: 0.1;
}

#image-uploads .pending-images li {
  display: inline-block;
  min-width: 200px;
  margin: 0.5rem 0;
}

#image-uploads .progress-label {
  position: absolute;
  left: 0;
  color: #000000;
  font-weight: bold;
  font-size: 0.9em;
  text-align: center;
  width: 100%;
  line-height: 1.5rem;
}

#image-uploads .btn {
  padding: 0.375rem 0.5rem;
  width: 100%;
}
</style>
