import React, { useRef, useState } from "react";
import { DotsThreeVertical } from "phosphor-react";

import { detectClickingOutside } from "global-helpers/click-handler";
import { crossDownload } from "global-helpers/listing";
import { protectedStates } from "../../util";

import "./image_item.scss";

const ImageItem = ({
  sortOrder,
  path,
  url,
  merchandisingName,
  state,
  onRemove,
  product,
  ...rest
}) => {
  const [isOpened, setIsOpened] = useState(false);

  const collapseRef = useRef();

  const close = () => setIsOpened(false);
  const handleOnRemove = () => {
    close();
    onRemove();
  };
  const handleOnDownload = () => {
    close();
    crossDownload(url);
  };

  detectClickingOutside(collapseRef, () => close());

  return (
    <div
      className="edit-form__image"
      {...rest}
    >
      <div className="edit-form__image__actions">
        <div>{product && sortOrder === 0 && <h6>Featured Image</h6>}</div>
        <div>
          <button onClick={() => setIsOpened(!isOpened)}>
            <DotsThreeVertical size={16} />
          </button>
          {isOpened && (
            <div className="absolute">
              <div
                ref={collapseRef}
                className="edit-form__image__actions__collapse"
              >
                <button onClick={handleOnDownload}>Download</button>
                {!protectedStates.includes(state) && (
                  <button onClick={handleOnRemove}>Remove Photo</button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="edit-form__image__body">
        <img src={url} alt={`Watch ${sortOrder}`} />
      </div>
    </div>
  );
};

export default ImageItem;
