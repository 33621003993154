import React, { useRef, useState, useEffect } from "react";
import WatchDetails from "./watch_details";
import {
  getModernListing,
  dateToDatabase,
  putModernListing,
  searchParamsToJson,
} from "global-helpers/listing";
import WatchLoader from "@images/watch_loader.svg";
import { LoadingModal, Button, PhotoGallery } from "components";

import { useParams } from "react-router-dom";

import FeatureSpec from "./features_specs";
import EvergreenContent from "./evergreen_content";
import RelatedContent from "./related_content";
import Header from "./header";
import Log from "./log";
import ValidationMsg from "./validation_msg";
import { useLocation, useNavigate, Navigate } from "react-router-dom";

import "./styles";

const Edit = ({
  brands = [],
  globalValues = {},
  modernPDPEnabled = {},
  policy = {},
  userRole = "viewer",
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isShowPage = !location.pathname.endsWith("/edit");
  const validateForEvent = searchParamsToJson(
    location.search
  ).validate_for_event;

  const { id } = useParams();
  const [modernListing, setModernListings] = useState();
  const [versions, setVersions] = useState([]);
  const [msg, setMsg] = useState([]);

  const loadingModalRef = useRef(null);
  const featuresAndSpecsRef = useRef(null);
  const watchDetailsRef = useRef(null);
  const evergreenContentRef = useRef(null);
  const relatedContentRef = useRef(null);
  const productImagesRef = useRef(null);
  const isUnauthorized = !policy.edit && !isShowPage;

  useEffect(async () => {
    if (isUnauthorized) return;

    let { modern_listing, versions, msg } = await getModernListing(
      id,
      validateForEvent
    );

    setModernListings(modern_listing);
    setVersions(versions);
    setMsg(msg);
  }, [id, isShowPage]);

  const saveListing = async () => {
    const watchDetailsState = watchDetailsRef.current.getFormState();
    const featuresAndSpecsState = featuresAndSpecsRef.current.getFormState();
    let evergreenContentState = {};
    let relatedContentState = {};
    let photoGalleryState = {};
    if (modernPDPEnabled) {
      evergreenContentState = evergreenContentRef.current.getFormState();
      relatedContentState = relatedContentRef.current.getFormState();
      photoGalleryState = productImagesRef.current.getFormState();
    }

    const modernListingData = {
      ...featuresAndSpecsState,
      ...watchDetailsState,
      ...evergreenContentState,
      ...relatedContentState,
      ...photoGalleryState,
      launch_date: dateToDatabase(watchDetailsState.launch_date),
      id: modernListing.id,
    };

    try {
      loadingModalRef?.current?.setLoading(true);
      const res = await putModernListing(modernListingData);
      if (await res === true) {
        navigate(`/modern/listings/${modernListing.id}`);
      } else {
        setMsg(res);
        console.error("Error occured while updating listing.");
      }
      loadingModalRef?.current?.setLoading(false);
    } catch (e) {
      console.error("Error occured while updating listing.");
      console.error(e);
    } finally {
      setTimeout(() => {
        loadingModalRef?.current?.setLoading(false);
      }, 2000);
    }
  };

  if (isUnauthorized) return <Navigate to={`/modern/listings/${id}`} />;

  if (!modernListing)
    return (
      <LoadingModal loading>
        <div className="card">
          <img src={WatchLoader} />
        </div>
      </LoadingModal>
    );

  return (
    <div className="edit">
      <LoadingModal ref={loadingModalRef}>
        <div className="card">
          <img src={WatchLoader} />
        </div>
      </LoadingModal>
      <Header
        modernListing={modernListing}
        isShowPage={isShowPage}
        onSave={saveListing}
        policy={policy}
      />
      {!isShowPage && (
        <ValidationMsg
          title={validateForEvent && "Error on state changing:"}
          messages={msg}
        />
      )}
      <div className="edit-form">
        <div className="edit-form__container edit-form__container--main">
          <WatchDetails
            ref={watchDetailsRef}
            listing={modernListing}
            brands={brands}
            isShowPage={isShowPage}
            userRole={userRole}
          />
          <EvergreenContent
            ref={evergreenContentRef}
            listing={modernListing}
            isShowPage={isShowPage}
            comingSoon={!modernPDPEnabled}
          />
          <RelatedContent
            ref={relatedContentRef}
            listing={modernListing}
            isShowPage={isShowPage}
            comingSoon={!modernPDPEnabled}
          />
          <PhotoGallery
            ref={productImagesRef}
            images={modernListing?.images}
            lifestyleImages={modernListing?.lifestyle_images}
            state={modernListing?.state}
            isShowPage={isShowPage}
            merchandisingName="Merchandising Name"
          />
          {isShowPage ? (
            <Log versions={versions} />
          ) : (
            <Button variant="primary" onClick={saveListing}>
              save
            </Button>
          )}
        </div>
        <div className="edit-form__container edit-form__container--side">
          <FeatureSpec
            ref={featuresAndSpecsRef}
            listing={modernListing}
            globalValues={globalValues}
            isShowPage={isShowPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Edit;
