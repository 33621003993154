import React from "react";
import classNames from "classnames";

import "./warning";

const ValidationMsg = ({ messages, title, variant, className }) =>
  messages?.length ? (
    <ul className={classNames("edit__msg", variant, className)}>
      {title && <li className="font-bold">{title}</li>}
      {(messages instanceof Array ? messages : messages.split(",")).map(
        (item, key) => (
          <li key={key}>{item}</li>
        )
      )}
    </ul>
  ) : (
    <></>
  );

export default ValidationMsg;
