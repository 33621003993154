import React from "react";
import classNames from "classnames";
import ReactQuill from "react-quill";
import { Flask, WarningCircle } from "phosphor-react";
import { isEmpty } from "global-helpers/listing";

import "./styles";

export const RichEditor = ({
  customizedFields,
  name,
  text,
  onChange,
  value,
  className,
  readOnly,
  isRequired,
  label = null,
}) => {
  const title = `${text}${isRequired ? "*" : ""}`;
  const isCustom = customizedFields?.includes(name);
  const hasError = isRequired && isEmpty(value);

  return (
    <div className="w-full">
      {label && (
        <span className="rich-editor__label">
          {isCustom && <Flask size={16} className="mr-1" />}
          {title}
        </span>
      )}
      <div
        className={classNames("rich-editor", {
          "rich-editor--disabled": readOnly,
          "rich-editor--error": hasError,
        })}
      >
        <ReactQuill
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          className={className}
        />
      </div>
      {hasError && (
        <div className="rich-editor__error">
          <WarningCircle size={14} />
          This field is required
        </div>
      )}
    </div>
  );
};
