<template>
  <div id="condition-report" class="card shadow-sm content-card">
    <h3 class="mb-4">
      Condition report
    </h3>
    <b-row>
      <b-col cols="12" xl="4" class="mb-3">
        <ListingFormSelect
          v-model="listing.condition"
          label="Condition Rating"
          :options="options.condition"
          @input="generateDescriptionFields"
          :required="isRequired('condition')" />
      </b-col>
      <b-col cols="12" xl="4" class="mb-3">
        <b-form-group label="Paper Date">
          <b-form-input id="paper_date"
            type="text"
            v-model="listing.paper_date"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" xl="4" class="mb-3">
        <b-form-group label="Max Wrist Size (in.)">
            <b-form-input id="max_wrist_size"
            type="number"
            min="0"
            :state="wristSizeInvalid"
            v-model="listing.max_wrist_size" />
            <b-alert show
              variant="danger"
              v-if="wristSizeErrors"
              >
              {{ wristSizeErrors }}
            </b-alert>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="8" class="mb-3">
        <ListingFormText
          v-model="listing.additional_accessories"
          label="Additional Accessories"
          :rows="2"
          :disabled="true"
          @mouseup="$emit('rebuildLayout')" />
      </b-col>
      <b-col xl="4" class="mb-3">
        <span class="inline mr-3">
          <b-form-group label="Box">
            <toggle-button
              :width="75"
              :height="44"
              :color="{checked: '#75C791', unchecked: '#F2F2F2', disabled: '#F2F2F2'}"
              :switch-color="{checked: '#FFFFFF', unchecked: '#CCCCCC', disabled: '#CCCCCC'}"
              :margin="6"
              :value="listing.box || false"
              @change="listing.box = $event.value" />
          </b-form-group>
        </span>
        <span class="inline mr-3">
          <b-form-group label="Papers">
            <toggle-button
              :width="75"
              :height="44"
              :color="{checked: '#75C791', unchecked: '#F2F2F2', disabled: '#F2F2F2'}"
              :switch-color="{checked: '#FFFFFF', unchecked: '#CCCCCC', disabled: '#CCCCCC'}"
              :margin="6"
              :value="listing.papers || false"
              @change="listing.papers = $event.value" />
          </b-form-group>
        </span>
        <span class="inline">
          <b-form-group label="Manual">
            <toggle-button
              :width="75"
              :height="44"
              :color="{checked: '#75C791', unchecked: '#F2F2F2', disabled: '#F2F2F2'}"
              :switch-color="{checked: '#FFFFFF', unchecked: '#CCCCCC', disabled: '#CCCCCC'}"
              :margin="6"
              :value="listing.manual || false"
              @change="listing.manual = $event.value" />
          </b-form-group>
        </span>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col md="12">
        <ListingFormText
          v-model="listing.qc_notes"
          label="QC Notes"
          :rows="3"
          :required="isRequired('qc_notes')"
          @mouseup="$emit('rebuildLayout')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <ListingFormText
          v-model="listing.condition_notes"
          label="Condition Notes"
          :rows="4"
          :required="isRequired('condition_notes')"
          @mouseup="$emit('rebuildLayout')" />
        <button v-if="!listed" @click="populateConditionNotes">
          Fill condition notes based on condition rating
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '../stores/listingFormStore';
import ListingFormSelect from './ListingFormSelect';
import ListingFormText from './ListingFormText';
import formUtils from '../services/formUtils';
import utils from '../services/utils';

export default {
  name: 'condition-report',
  components: {
    ListingFormSelect,
    ListingFormText,
  },
  data() {
    return {
      state: store.state,
    };
  },
  computed: {
    listing() { return store.state.listing },
    listed() {
      return ['listed', 'ready_to_list'].includes(this.state.listing.status);
    },
    options() { return store.state.globalAttributes },
    wristSizeErrors() {
      const size = this.listing.max_wrist_size;
      const browserValid = (!size && size !== 0) || size > 0;
      if(!browserValid)
        return 'must be positive if defined';
    },
    wristSizeInvalid() {
      return this.wristSizeErrors && 'invalid';
    }
  },
  methods: {
    generateDescriptionFields: utils.debounce(() => {
      store.generateDescriptionFields;
    }, 800),
    populateConditionNotes() {
      store.populateConditionNotes();
    },
    isRequired(field) { return formUtils.isRequired(field, store.state) }
  }
}
</script>
