import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { WarningCircle } from "phosphor-react";
import MultiBox from "./multi_box";
import MultiSelectSearch from "./multi_select_search";
import LabelCover from "./label_cover";

export const MultiSelect = ({
  items,
  value,
  name,
  label,
  variant,
  helperText,
  readOnly,
  onChange,
  singleValue,
  empty,
  required,
  coverComponent,
  coverVariant,
  labelCover,
  hideSearchInput,
  hideCaret,
}) => {
  const thisRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const handleItemSelect = (item) => {
    if (singleValue) {
      setExpanded(false);
      return onChange(name, item);
    }

    if (value.includes(item)) {
      onChange(
        name,
        value.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      onChange(name, [...value, item]);
    }
  };

  const mappedItems =
    items instanceof Array
      ? items.reduce((acc, item) => ({ ...acc, [item]: item }), {})
      : items;
  // If any other part of the page is clicked, close MultiSelect.
  useEffect(() => {
    const close = (e) => {
      if (!thisRef?.current?.contains(e.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener("mousedown", close);
    return () => {
      document.removeEventListener("mousedown", close);
    };
  });

  return (
    <div
      className={classNames("multi-select", {
        "read-only": readOnly,
        error: !readOnly && required && value.length === 0,
        variant,
      })}
      ref={thisRef}
    >
      {labelCover ? (
        <LabelCover
          label={label}
          coverComponent={coverComponent}
          readOnly={readOnly}
          expanded={expanded}
          setExpanded={setExpanded}
          coverVariant={coverVariant}
          hideCaret={hideCaret}
        />
      ) : (
        <MultiBox
          readOnly={readOnly}
          expanded={expanded}
          value={value}
          mappedItems={mappedItems}
          singleValue={singleValue}
          label={label}
          setExpanded={setExpanded}
          handleItemSelect={handleItemSelect}
        />
      )}
      {helperText && (
        <div className="helper-text">
          <WarningCircle size={14} />
          {helperText}
        </div>
      )}
      {expanded && (
        <MultiSelectSearch
          label={label}
          items={mappedItems}
          onCheckboxClick={handleItemSelect}
          selectedItems={singleValue ? [value] : value}
          close={() => setExpanded(false)}
          hideSearchInput={hideSearchInput}
          singleValue={singleValue}
        />
      )}
    </div>
  );
};
