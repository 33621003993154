import React, {
  forwardRef,
  useImperativeHandle,
  useReducer,
  useRef,
} from "react";
import { ImageModal, WatchImageCard } from "components";
import { Checkbox, Select, TextInput, Option } from "@hodinkee/lume-ui";
import { MultiSelect } from "./index";

// Because multiple <Select> entries use the same values (date range), generate random uuid for key.
const generateOptions = (items) =>
  items.map((item) => (
    <Option key={crypto.randomUUID()} value={item}>
      {item}
    </Option>
  ));

const FirstSection = forwardRef((props, _ref) => {
  const { listing, yearOptions, globalValues, hasSpec, generateRequiredProps } =
    props;
  const [form, setForm] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      alternate_reference_numbers: listing.alternate_reference_numbers || [],
      approximate_age: listing.approximate_age || "",
      year: listing.year || "",
      gender: listing.gender || "",
      dial_color: listing.dial_color.split(",") || [],
      case_materials: listing.case_materials || [],
      case_size: listing.case_size || "",
      case_shape: listing.case_shape || "",
      case_back: listing.case_back || "",
      case_thickness: listing.case_thickness || "",
    }
  );
  const handleFormEdit = (event) => {
    const { name, value } = event.target;
    setForm({ [name]: value });
  };
  const handleMultiSelectEdit = (name, value) => {
    setForm({ [name]: value });
  };

  useImperativeHandle(_ref, () => ({
    getFormState: () => {
      form.dial_color = form.dial_color.join(",");
      return form;
    },
  }));
  return (
    <>
      <MultiSelect
        readOnly={hasSpec}
        label="Alternate Ref. Numbers"
        name="alternate_reference_numbers"
        items={form.alternate_reference_numbers}
        value={form.alternate_reference_numbers}
        onChange={handleMultiSelectEdit}
      />
      <Select
        {...generateRequiredProps(
          "approximate_age",
          form.approximate_age,
          true
        )}
        onChange={handleFormEdit}
      >
        {/* TODO: Should we refactor this be in @global_values? */}
        {[
          "",
          "1950s",
          "1960s",
          "1970s",
          "1980s",
          "1990s",
          "2000s",
          "2010s",
          "2020 - Present",
        ].map((condition) => (
          <Option value={condition} key={condition}>
            {condition}
          </Option>
        ))}
      </Select>
      <Select
        label="Year"
        name="year"
        value={form.year}
        onChange={handleFormEdit}
      >
        {yearOptions}
      </Select>
      <Select
        {...generateRequiredProps("gender", form.gender, true)}
        readOnly={hasSpec}
        disabled={hasSpec}
        onChange={handleFormEdit}
      >
        <Option value="mens">Men's</Option>
        <Option value="womens">Women's</Option>
        <Option value="unisex">Unisex</Option>
      </Select>
      <MultiSelect
        {...generateRequiredProps(
          "dial_color",
          form.dial_color,
          true
        )}
        readOnly={hasSpec}
        disabled={hasSpec}
        items={globalValues.dial_colors}
        onChange={handleMultiSelectEdit}
      />
      <MultiSelect
        {...generateRequiredProps("case_materials", form.case_materials, true)}
        readOnly={hasSpec}
        disabled={hasSpec}
        items={globalValues.case_materials}
        onChange={handleMultiSelectEdit}
      />
      <TextInput
        {...generateRequiredProps("case_size", form.case_size, true)}
        readOnly={hasSpec}
        disabled={hasSpec}
        label="Case Size (mm) (Required)"
        onChange={handleFormEdit}
      />
      <Select
        {...generateRequiredProps("case_shape", form.case_shape, true)}
        readOnly={hasSpec}
        disabled={hasSpec}
        onChange={handleFormEdit}
      >
        {generateOptions(globalValues.case_shape)}
      </Select>
      <Select
        {...generateRequiredProps("case_back", form.case_back, true)}
        readOnly={hasSpec}
        disabled={hasSpec}
        onChange={handleFormEdit}
      >
        {generateOptions(globalValues.case_back)}
      </Select>
      <TextInput
        label="Case Thickness"
        value={form.case_thickness}
        name="caseThickness"
        readOnly={hasSpec}
        disabled={hasSpec}
        onChange={handleFormEdit}
      />
    </>
  );
});

const SecondSection = forwardRef((props, _ref) => {
  const { listing, globalValues, hasSpec, generateRequiredProps } = props;
  const [form, setForm] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      band_materials: listing.band_materials || [],
      bracelet_name: listing.bracelet_name || "",
      lug_width: listing.lug_width || "",
      crystal_material: listing.crystal_material || "",
      bezel_materials: listing.bezel_materials || [],
      bezel_features: listing.bezel_features || [],
      bezel_insert_materials: listing.bezel_insert_materials || [],
      bezel_insert_colors: listing.bezel_insert_colors || [],
    }
  );
  const handleFormEdit = (event) => {
    const { name, value } = event.target;
    setForm({ [name]: value });
  };
  const handleMultiSelectEdit = (name, value) => {
    setForm({ [name]: value });
  };
  useImperativeHandle(_ref, () => ({
    getFormState: () => form,
  }));
  return (
    <>
      <MultiSelect
        {...generateRequiredProps("band_materials", form.band_materials, true)}
        readOnly={hasSpec}
        disabled={hasSpec}
        items={globalValues.band_materials}
        onChange={handleMultiSelectEdit}
      />
      <Select
        readOnly={hasSpec}
        disabled={hasSpec}
        name="bracelet_name"
        label="Bracelet Name"
        value={form.bracelet_name}
        onChange={handleFormEdit}
        required
      >
        {generateOptions(globalValues.bracelet_name)}
      </Select>
      <TextInput
        readOnly={hasSpec}
        disabled={hasSpec}
        name="lug_width"
        label="Lug Width (mm)"
        value={form.lug_width}
        onChange={handleFormEdit}
      />
      <Select
        {...generateRequiredProps(
          "crystal_material",
          form.crystal_material,
          true
        )}
        readOnly={hasSpec}
        disabled={hasSpec}
        onChange={handleFormEdit}
      >
        {generateOptions(globalValues.crystal_materials)}
      </Select>
      <MultiSelect
        {...generateRequiredProps(
          "bezel_materials",
          form.bezel_materials,
          true
        )}
        readOnly={hasSpec}
        disabled={hasSpec}
        items={globalValues.bezel_materials}
        onChange={handleMultiSelectEdit}
      />
      <MultiSelect
        readOnly={hasSpec}
        disabled={hasSpec}
        label="Bezel Features"
        name="bezel_features"
        items={globalValues.bezel_features}
        value={form.bezel_features}
        onChange={handleMultiSelectEdit}
      />
      <MultiSelect
        readOnly={hasSpec}
        disabled={hasSpec}
        label="Bezel Insert Materials"
        name="bezel_insert_materials"
        items={globalValues.bezel_insert_materials}
        value={form.bezel_insert_materials}
        onChange={handleMultiSelectEdit}
      />
      <MultiSelect
        readOnly={hasSpec}
        disabled={hasSpec}
        label="Bezel Insert Colors"
        name="bezel_insert_colors"
        items={globalValues.bezel_insert_colors}
        value={form.bezel_insert_colors}
        onChange={handleMultiSelectEdit}
      />
    </>
  );
});

const ThirdSection = forwardRef((props, _ref) => {
  const { listing, yearOptions, globalValues, hasSpec, generateRequiredProps } =
    props;
  const [form, setForm] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      movement_type: listing.movement_type || "",
      manuf_caliber: listing.manuf_caliber || "",
      base_caliber: listing.base_caliber || "",
      power_reserve: listing.power_reserve || "",
      jewels: listing.jewels || "",
      complications: listing.complications || [],
      calendar_type: listing.calendar_type || "",
      water_resistance: listing.water_resistance || "",
      style: listing.style || [],
      analog_digital: listing.analog_digital || "",
      edition_type: listing.edition_type || "",
      limited_quantity: listing.limited_quantity || "",
      years_released: listing.years_released || "",
      years_stopped: listing.years_stopped || "",
      aftermarket_replacements: listing.aftermarket_replacements || false,
      aftermarket_additions: listing.aftermarket_additions || false,
      manuf_diamonds: listing.manuf_diamonds || false,
      diamond_dial: listing.diamond_dial || false,
    }
  );
  const handleFormEdit = (event) => {
    const { name, value } = event.target;
    setForm({ [name]: value });
  };
  const toggleCheckboxTrueFalse = (name) => {
    setForm({ [name]: !form[name] });
  };
  const handleMultiSelectEdit = (name, value) => {
    setForm({ [name]: value });
  };
  useImperativeHandle(_ref, () => ({
    getFormState: () => form,
  }));
  return (
    <>
      <Select
        {...generateRequiredProps("movement_type", form.movement_type, true)}
        readOnly={hasSpec}
        disabled={hasSpec}
        onChange={handleFormEdit}
      >
        {generateOptions(globalValues.movement_type)}
      </Select>
      <TextInput
        {...generateRequiredProps("manuf_caliber", form.manuf_caliber, true)}
        readOnly={hasSpec}
        disabled={hasSpec}
        label="Manufacturer Caliber (Required)"
        onChange={handleFormEdit}
      />
      <TextInput
        readOnly={hasSpec}
        disabled={hasSpec}
        name="base_caliber"
        label="Base Caliber"
        value={form.base_caliber}
        onChange={handleFormEdit}
        required
      />
      <TextInput
        readOnly={hasSpec}
        disabled={hasSpec}
        name="power_reserve"
        label="Power Reserve (hours)"
        value={form.power_reserve}
        onChange={handleFormEdit}
      />
      <TextInput
        readOnly={hasSpec}
        disabled={hasSpec}
        name="jewels"
        label="Jewels"
        value={form.jewels}
        onChange={handleFormEdit}
      />
      <MultiSelect
        readOnly={hasSpec}
        disabled={hasSpec}
        label="Complications"
        name="complications"
        items={globalValues.complications}
        value={form.complications}
        onChange={handleMultiSelectEdit}
      />
      <Select
        readOnly={hasSpec}
        disabled={hasSpec}
        name="calendar_type"
        label="Calendar Type"
        value={form.calendar_type}
        onChange={handleFormEdit}
      >
        {generateOptions(globalValues.calendar_type)}
      </Select>
      <TextInput
        {...generateRequiredProps(
          "water_resistance",
          form.water_resistance,
          true
        )}
        label="Water Resistance (m) (Required)"
        readOnly={hasSpec}
        disabled={hasSpec}
        onChange={handleFormEdit}
      />
      <MultiSelect
        readOnly={hasSpec}
        disabled={hasSpec}
        name="style"
        label="Style"
        items={globalValues.style}
        value={form.style}
        onChange={handleMultiSelectEdit}
      >
        {generateOptions(globalValues.style)}
      </MultiSelect>
      <Select
        {...generateRequiredProps("analog_digital", form.analog_digital, true)}
        label="Display Type (Required)"
        readOnly={hasSpec}
        disabled={hasSpec}
        onChange={handleFormEdit}
      >
        {generateOptions(["", "Analog", "Digital"])}
      </Select>
      <Select
        readOnly={hasSpec}
        disabled={hasSpec}
        name="edition_type"
        label="Edition Type"
        value={form.edition_type}
        onChange={handleFormEdit}
      >
        {generateOptions(globalValues.edition_type)}
      </Select>
      <TextInput
        readOnly={hasSpec}
        disabled={hasSpec}
        name="limited_quantity"
        label="Limited Quantity"
        value={form.limited_quantity}
        onChange={handleFormEdit}
      />
      <Select
        readOnly={hasSpec}
        disabled={hasSpec}
        name="years_released"
        label="Year Released"
        value={form.years_released}
        onChange={handleFormEdit}
      >
        {yearOptions}
      </Select>
      <Select
        readOnly={hasSpec}
        disabled={hasSpec}
        name="years_stopped"
        label="Year Stopped"
        value={form.years_stopped}
        onChange={handleFormEdit}
      >
        {yearOptions}
      </Select>
      <Checkbox
        readOnly={hasSpec}
        disabled={hasSpec}
        name="aftermarket_replacements"
        value={`${form.aftermarket_replacements}`}
        onChange={() => toggleCheckboxTrueFalse("aftermarket_replacements")}
        checked={form.aftermarket_replacements}
      >
        Aftermarket Replacements?
      </Checkbox>
      <Checkbox
        readOnly={hasSpec}
        disabled={hasSpec}
        name="aftermarket_additions"
        value={`${form.aftermarket_additions}`}
        onChange={() => toggleCheckboxTrueFalse("aftermarket_additions")}
        checked={form.aftermarket_additions}
      >
        Aftermarket Additions?
      </Checkbox>
      <Checkbox
        readOnly={hasSpec}
        disabled={hasSpec}
        name="manuf_diamonds"
        value={`${form.manuf_diamonds}`}
        onChange={() => toggleCheckboxTrueFalse("manuf_diamonds")}
        checked={form.manuf_diamonds}
      >
        Manufacturer Diamonds?
      </Checkbox>
      <Checkbox
        readOnly={hasSpec}
        disabled={hasSpec}
        name="diamond_dial"
        value={`${form.aftermarket_replacements}`}
        onChange={() => toggleCheckboxTrueFalse("diamond_dial")}
        checked={form.diamond_dial}
      >
        Diamond Dial?
      </Checkbox>
    </>
  );
});

const generateArrayOfYears = () => {
  const years = [];
  for (let i = new Date().getFullYear(); i >= 1900; i--) {
    years.push(i);
  }
  return years;
};

export const FeaturesAndSpecsForm = forwardRef((props, _ref) => {
  const firstSectionRef = useRef(null);
  const secondSectionRef = useRef(null);
  const thirdSectionRef = useRef(null);
  const imageModalRef = useRef(null);
  const yearOptions = generateOptions(generateArrayOfYears());
  const { featuredImage, merchandisingName } = props;

  props = { ...props, yearOptions };

  useImperativeHandle(_ref, () => ({
    getFormState: () => {
      return {
        ...firstSectionRef.current.getFormState(),
        ...secondSectionRef.current.getFormState(),
        ...thirdSectionRef.current.getFormState(),
      };
    },
  }));

  return (
    <div className="edit-form__container__content">
      <ImageModal
        ref={imageModalRef}
        path={featuredImage?.path}
        alt={merchandisingName}
      />
      <WatchImageCard
        path={featuredImage?.path}
        merchandisingName={merchandisingName}
        onExpand={() => imageModalRef.current.setVisible(true)}
      />
      <h2>Features and Specs</h2>
      <div className="edit-form__container__content__rows">
        <FirstSection ref={firstSectionRef} {...props} />
        <SecondSection ref={secondSectionRef} {...props} />
        <ThirdSection ref={thirdSectionRef} {...props} />
      </div>
    </div>
  );
});
