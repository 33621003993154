import React from "react";
import classNames from "classnames";

export const Chip = ({ children, className, ...rest }) => {
  return (
    <button className={classNames("chip", className)} {...rest}>
      {children}
    </button>
  )
};
