import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueLazyload from 'vue-lazyload'
import ToggleButton from 'vue-js-toggle-button';
import TurbolinksAdapter from 'vue-turbolinks';
import '../../assets/stylesheets/application.scss';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'focus-visible';
import axios from 'axios';

import ListingForm from './components/ListingForm';
import ImageUploads from './components/ImageUploads';
import store from './stores/listingFormStore';

Vue.prototype.downloadClImage = window.downloadClImage;
Vue.use(TurbolinksAdapter);
Vue.use(BootstrapVue);
Vue.use(ToggleButton);
Vue.use(VueLazyload, {
  loading: 'https://res.cloudinary.com/crownandcaliber/image/upload/v1639478009/listings_portal/production/loading-spinner.svg',
})
window.ListingsPortal = {}
ListingsPortal.initImageUploads = (containerId) => {
  initVue(containerId, ImageUploads);
}
ListingsPortal.initListingForm = (containerId) => {
  initVue(containerId, ListingForm, store.setInitialState);
}
window.axios = axios;

function initVue(containerId, component, initStore) {
  const container = $(containerId);

  if (!container.length)
    return;

  initStore && initStore(container.data());
  var mountedComponent = new Vue(component).$mount(containerId);
  mountedComponent.applyData && mountedComponent.applyData(container.data());
}

// React initialization
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
