import React, { forwardRef, useImperativeHandle, useReducer } from "react";
import { TextInput, TextLink } from "@hodinkee/lume-ui";
import { getInitialRelatedArticles } from "global-helpers/listing";
import InputWrapper from "./input_wrapper";

const RelatedContent = forwardRef(({ listing, isShowPage, comingSoon }, ref) => {
  if (comingSoon) {
    return (
      <div className="edit-form__container__content">
        <h2>Related Content</h2>
        <div className="coming-soon">
          <span>Coming Soon...</span>
        </div>
      </div>
    );
  }

  const [form, setForm] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      related_articles: getInitialRelatedArticles(listing.related_articles),
      video_id: listing.video_id || "",
    }
  );

  const handleRelatedArticleEdit = (event, index) => {
    const { value } = event.target;
    const related_articles = form.related_articles;
    related_articles[index] = value; // Get last char of name, which is index.
    setForm({ related_articles });
  };

  const onVideoInputChange = (e) => {
    const id = parseVideoLink(e.target.value);
    console.log(`link : ${id}`);
    setForm({ video_id: id });
  };

  const parseVideoLink = (input) => {
    if ([11, 13].includes(input.length)) {
      return input;
    }
    const youtubeParse = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm.exec(input);
    const brightcoveParse = /^.*brightcove.com.*\/([^/]+)\/?$/gm.exec(input);
    if (youtubeParse) {
      return youtubeParse[3];
    } else if (brightcoveParse) {
      return brightcoveParse[1];
    }
    return null;
  }

  const validVideoId = (id) => {
    return id && (id.length === 11 || id.length === 13);
  }

  const generateVideoUrl = (id) => {
    if (!validVideoId(id)) return null;
    return id.length === 11
      ? `https://www.youtube.com/watch?v=${id}`
      : `https://studio.brightcove.com/products/videocloud/media/videos/${id}`;
  }

  useImperativeHandle(ref, () => ({
    getFormState: () => form,
  }));

  const videoHref = generateVideoUrl(form.video_id);
  const isValidId = validVideoId(form.video_id);

  return (
    <div className="edit-form__container__content">
      <h2>Related Content</h2>
      <h3 className="stories-about-this-watch-title">
        Stories About This Watch
      </h3>
      <div className="edit-form__container__content__rows mb-5">
        <div className="edit-form__container__content__rows__grid-row">
          {form.related_articles.map((relatedArticle, index) => (
            <InputWrapper
            label={`Article ${index + 1}`}
            value={relatedArticle}
            type="link"
            isShowPage={isShowPage}
            >
              <TextInput
                key={`relatedArticle${index}`}
                name={`relatedArticle${index}`}
                onChange={(e) => handleRelatedArticleEdit(e, index)}
            />
            </InputWrapper>
          ))}
        </div>
      </div>
      <h3>Video Embed</h3>
      <div className="edit-form__container__content__rows">
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
          label={"Video Embed ID"}
          value={form.video_id}
          isShowPage={isShowPage}
          >
            <TextInput
              name="video_id"
              onChange={onVideoInputChange}
              helperText="You can either use the full URL, short URL, or just the video ID"
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          {isValidId && (
            <TextLink href={videoHref} target="_blank">
              {videoHref}
            </TextLink>
          )}
        </div>
      </div>
    </div>
  );
});

export default RelatedContent;
