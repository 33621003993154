import axios from 'axios';

const specsService = {
  init,
  baseURI: '',
  username: '',
  password: '',
  ccspec,
  ccspecs,
  models,
  modelNumbers,
};

export default specsService;

function specsServiceRequest(endpoint, callback, error) {
  const creds = Buffer.from(`${specsService.username}:${specsService.password}`).toString('base64');
  const req = {
    credentials: 'same-origin',
    headers: {
      Accept: '*/*',
      'Content-Type': 'text/plain;charset=UTF-8',
      Authorization: `Basic ${creds}`,
    },
  };

  axios.get(`${specsService.baseURI}${endpoint}`, req)
    .then(({ data }) => callback(data))
    .catch(error);
}

function ccspec(ccspecNumber, success, error) {
  specsServiceRequest(`/ccspecs/${ccspecNumber}.json`, success, error);
}

function ccspecs(modelNumber, success, error) {
  specsServiceRequest(`/ccspecs.json?model_number_id=${encodeURI(modelNumber)}`, success, error);
}

function init(uri, username, password) {
  specsService.baseURI = uri || '';
  specsService.username = username;
  specsService.password = password;
}

function models(brandId, success, error) {
  specsServiceRequest(`/brands/${brandId}/models.json`, success, error);
}

function modelNumbers(modelId, success, error) {
  specsServiceRequest(`/models/${modelId}/model_numbers.json`, success, error);
}
