import React, { forwardRef, useImperativeHandle, useState } from "react";

export const LoadingModal = forwardRef((props, _ref) => {
  const [loading, setLoading] = useState(!!props.loading);
  useImperativeHandle(_ref, () => ({ loading, setLoading }));
  if (loading === false) {
    return null;
  }
  return (
    <div className="loading-modal" {...props}>
      {props.children}
    </div>
  );
});
