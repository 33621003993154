<template>
  <div id="brand-details" class="card shadow-sm content-card">
    <h3 class="mb-4">
      Brand Details
    </h3>
    <b-row>
      <b-col sm="12">
        <ListingFormEditor
          v-model="listing.about_brand"
          label="About the brand"
          v-on="$listeners"
          :disabled="true"
          :required="isRequired('about_brand')" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '../stores/listingFormStore';
import ListingFormEditor from './ListingFormEditor.vue';
import formUtils from '../services/formUtils';

export default {
  name: 'watch-story',
  components: {
    ListingFormEditor,
  },
  data() {
    return {
      state: store.state,
    };
  },
  computed: {
    listing() { return store.state.listing }
  },
  methods: {
    isRequired(field) { return formUtils.isRequired(field, store.state) }
  }
}

</script>
