import { decodeEvergreen } from "global-helpers/listing";

const specsService = {
  init,
  baseURI: "",
  username: "",
  password: "",
  getEvergreenDefaultValue,
  getSpecHodinkeeIneligibility,
};

export default specsService;

const evergreenFields = ["dek", "wwli", "backstory", "about_brand"];

async function specsServiceRequest(endpoint) {
  const creds = Buffer.from(`${specsService.username}:${specsService.password}`).toString('base64');
  try {
    const res = await fetch(`${specsService.baseURI}${endpoint}`, {
      credentials: "same-origin",
      headers: {
        Accept: "*/*",
        "Content-Type": "text/plain;charset=UTF-8",
        Authorization: `Basic ${creds}`,
      },
    });
    return res.json();
  } catch (e) {
    console.error("Error making request to Specs Service", e);
  }
}

async function getSpecHodinkeeIneligibility() {
  const res = await specsServiceRequest(
    `/ccspecs/${specsService.ccSpecNumber}.json`
  );
  return res?.ccspec?.hodinkee_ineligibility ?? false;
}

async function getEvergreenDefaultValue(resetKey) {
  const res = await specsServiceRequest(
    `/ccspecs/${specsService.ccSpecNumber}.json`
  );
  if (!res) return "";
  const defaultValue = res?.ccspec[resetKey] ?? null;
  if (!defaultValue) return "";
  return evergreenFields.includes(resetKey) ? decodeEvergreen(defaultValue) : defaultValue;
}

function init({ baseURI, username, password, ccSpecNumber }) {
  specsService.baseURI = baseURI || "";
  specsService.username = username;
  specsService.password = password;
  specsService.ccSpecNumber = ccSpecNumber;
}
