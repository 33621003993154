import React, { forwardRef, useImperativeHandle, useReducer } from "react";
import { Flask } from "phosphor-react";
import { TextInput, TextLink } from "@hodinkee/lume-ui";
import {
  generateVideoUrl,
  getInitialRelatedArticles,
  parseVideoLink,
  validVideoId,
} from "./util";
import { ResetEditsButton } from "../index";

export const RelatedContentForm = forwardRef((props, _ref) => {
  const { listing, customizedFields, setCustomizedFields, ccSpecNumber } =
    props;
  const [form, setForm] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      related_articles: getInitialRelatedArticles([
        ...listing.related_articles,
      ]),
      video_id: listing.video_id || "",
    }
  );
  const appendCustomField = (field) => {
    if (!customizedFields.includes(field)) {
      setCustomizedFields([...customizedFields, field]);
    }
  };
  const handleRelatedArticleEdit = (event, index) => {
    const { value } = event.target;
    const related_articles = form.related_articles;
    related_articles[index] = value; // Get last char of name, which is index.
    setForm({ related_articles });
    appendCustomField("related_articles");
  };
  const onVideoInputChange = (e) => {
    const input = e.target.value;
    setForm({ video_id: parseVideoLink(input) ?? input });
    appendCustomField("video_id");
  };
  const removeCustomField = (field) => {
    customizedFields.splice(customizedFields.indexOf(field), 1);
    setCustomizedFields([...customizedFields]);
  };
  const onResetRelatedArticles = (_, specArticles) => {
    setForm({ related_articles: getInitialRelatedArticles(specArticles) });
    customizedFields.splice(customizedFields.indexOf("related_articles"), 1);
    removeCustomField("related_articles");
  };
  const onResetVideo = (_, specVideo) => {
    setForm({ video_id: specVideo });
    removeCustomField("video_id");
  };
  useImperativeHandle(_ref, () => ({
    getFormState: () => form,
  }));
  const videoHref = generateVideoUrl(form.video_id);
  const isValidId = validVideoId(form.video_id);
  const articlesCustom = customizedFields?.includes("related_articles");
  const videoCustom = customizedFields?.includes("video_id");

  return (
    <div className="edit-form__container__content">
      <h2>Related Content</h2>
      <div className="edit-form__container__content__rows">
        <div className="custom-options">
          <div className="custom-options__title-container">
            {articlesCustom && <Flask size={20} className="mr-1" />}
            <h3>Stories About This Watch</h3>
          </div>
          {ccSpecNumber && articlesCustom && (
            <ResetEditsButton
              resetKey="related_articles"
              onReset={onResetRelatedArticles}
            />
          )}
        </div>
        <div className="edit-form__container__content__rows__row edit-form__container__content__rows__row--two-columns">
          {form.related_articles.map((relatedArticle, index) => (
            <TextInput
              key={`relatedArticle${index}`}
              name={`relatedArticle${index}`}
              variant={index === 0 && !relatedArticle ? "error" : null}
              label={`Article ${index + 1} ${index === 0 ? "(Required)" : ""}`}
              value={relatedArticle}
              onChange={(e) => handleRelatedArticleEdit(e, index)}
            />
          ))}
        </div>
        <div className="custom-options">
          <div className="custom-options__title-container">
            {videoCustom && <Flask size={20} className="mr-1" />}
            <h3>Media</h3>
          </div>
          {ccSpecNumber && videoCustom && (
            <ResetEditsButton resetKey="video_id" onReset={onResetVideo} />
          )}
        </div>
        <TextInput
          name="video_id"
          label="Video ID"
          value={form.video_id}
          onChange={onVideoInputChange}
          helperText="You can either use the URL, or just the video ID"
        />
        {isValidId && (
          <TextLink href={videoHref} target="_blank">
            {videoHref}
          </TextLink>
        )}
      </div>
    </div>
  );
});
