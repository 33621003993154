import React, { forwardRef, useImperativeHandle, useReducer } from "react";
import { Calendar, MultiSelect } from "components";
import { formatToCurrency, formatToNumber } from "global-helpers/currency";
import { TextInput } from "@hodinkee/lume-ui";
import InputWrapper from "./input_wrapper";

const WatchDetails = forwardRef(({ listing, brands, isShowPage, userRole }, ref) => {
  const [form, setForm] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      sku: listing.sku || "",
      nickname: listing.nickname || "",
      brand: listing.brand || "",
      shopify_id: listing.shopify_id || "",
      _model_name: listing._model_name || "",
      model_number: listing.model_number || "",
      merchandising_model_number: listing.merchandising_model_number || "",
      cogs_cents: listing.cogs_cents || "0",
      price_cents: listing.price_cents || "0",
      launch_date: listing.launch_date,
    }
  );
  const handleFormEdit = (event) => {
    const { name, value } = event.target;
    setForm({ [name]: value });
  };
  const handleChangeData = (value) =>
    handleFormEdit({
      target: { name: "launch_date", value },
    });

  const handlePriceEdit = (event) => {
    const { name, value } = event.target;
    setForm({ [name]: formatToNumber(value) * 100 });
  };

  useImperativeHandle(ref, () => ({
    getFormState: () => form,
  }));

  const handleBrandSelect = (_, value) => {
    setForm({ brand: value });
  };

  return (
    <div className="edit-form__container__content">
      <h2>Watch Details</h2>

      <div className="edit-form__container__content__rows">
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Brand*"
            value={form.brand}
            isShowPage={isShowPage}
          >
            <MultiSelect
              name="brand"
              items={brands.map(({ name }) => name).sort()}
              onChange={handleBrandSelect}
              singleValue
              required
            />
          </InputWrapper>
          <InputWrapper
            label="Model Name*"
            value={form._model_name}
            isShowPage={isShowPage}
            required
          >
            <TextInput name="_model_name" onChange={handleFormEdit} />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Nickname"
            value={form.nickname}
            isShowPage={isShowPage}
          >
            <TextInput name="nickname" onChange={handleFormEdit} />
          </InputWrapper>
          <InputWrapper
            value={form.model_number}
            label="Model Number*"
            isShowPage={isShowPage}
            required
          >
            <TextInput name="model_number" onChange={handleFormEdit} />
          </InputWrapper>
          <InputWrapper
            label="Merchandising Model Number*"
            value={form.merchandising_model_number}
            isShowPage={isShowPage}
          >
            <TextInput
              name="merchandising_model_number"
              onChange={handleFormEdit}
              required
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="SKU*"
            value={form.sku}
            isShowPage={isShowPage}
            action="copy"
          >
            <TextInput name="sku" onChange={handleFormEdit} readOnly required />
          </InputWrapper>
          <InputWrapper
            label="Shopify ID"
            value={form.shopify_id}
            isShowPage={isShowPage}
            action={listing.store_admin_url}
          >
            {/* Only allow 'managers' or 'admins' to edit this field. */}
            <TextInput name="shopify_id" onChange={handleFormEdit} readOnly={!['manager', 'admin'].includes(userRole)} />
          </InputWrapper>
          <InputWrapper
            label="Launch Date"
            value={form.launch_date}
            isShowPage={isShowPage}
            type="date"
          >
            <Calendar name="launch_date" onChange={handleChangeData} />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="COGS"
            value={formatToCurrency(form.cogs_cents, true)}
            isShowPage={isShowPage}
          >
            <TextInput name="cogs_cents" onChange={handlePriceEdit} readOnly />
          </InputWrapper>
          <InputWrapper
            label="Retail Price"
            value={formatToCurrency(form.price_cents, true)}
            isShowPage={isShowPage}
          >
            <TextInput
              name="retail_price_cents"
              onChange={handlePriceEdit}
              readOnly
            />
          </InputWrapper>
        </div>
      </div>
    </div>
  );
});

export default WatchDetails;
