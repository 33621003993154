function any(arr){
  if(!arr.length)
    return 'can\'t be blank';
}

function validateImages(arr) {
  if(arr.length < 2)
    return 'should contain more than one image';
}

function applyErrors(errors, error) {
  return Object.assign({}, errors, {
    [error.label]: error.messages,
  });
}

function positive(value) {
  if(value && value < 0)
    return 'must be positive';
}

function presence(value) {
  if(!value)
    return 'is required';
}

function validators(label, value, validations) {
  return {
    label,
    messages: validations.map(f => f(value, label)).filter(x => x),
  };
}

function validateModel(state) {
  const validations = [
    validators('brand', state.brandName, [presence]),
    validators('maxWristSize', state.listing.max_wrist_size, [positive]),
    validators('modelName', state.modelName, [presence]),
    validators('modelNumber', state.modelNumber, [presence]),
    validators('sku', state.listing.sku, [presence]),
    validators('listPrice', (state.listing.list_price_precise || state.listing.net_price_precise), [presence]),
  ];

  const shopifyValidations = [
    validators('band_materials', state.listing.band_materials, [any]),
    validators('bezel_materials', state.listing.bezel_materials, [any]),
    validators('condition', state.listing.condition, [presence]),
    validators('images', state.images, [validateImages]),
  ];

  const errors = validations.reduce(applyErrors, {});
  const warnings = shopifyValidations.reduce(applyErrors, {});
  const valid = !Object.keys(errors).reduce((e, k) => [...e, ...errors[k]], []).length;
  Object.assign(state, { warnings });

  if (!valid) {
    const msg = 'Some fields did not meet validations. Please check below.'
    Object.assign(state.errors, {messages: [msg]}, errors);
  }

  return valid;
}

export default {
  validateModel,
};
