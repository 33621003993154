<template>
  <b-form-group :label="labelName" v-bind:class="{ required: required }">
    <i v-if="disabled" class="fa fa-lock field-icon locked-field-icon" />
    <i v-if="required && !localValue"
      class="fa fa-exclamation-triangle orange-font tt-error field-icon"
      :id="`tooltip-target-${inputId}`"
      v-b-tooltip.html
      title="Can't be blank" />
    <b-input-group>
      <b-form-input :id="inputId"
        v-model="localValue"
        :type="type"
        :disabled="disabled"
        :reset-after="true"
        :required="required"
        :state="validCheck"
        :class="customClassIncluded"
        @change="$emit('change', localValue)"
        @keyup="$emit('keyup', localValue)"
        @keydown="handleKeydown" />
      <b-tooltip
        :show="!!warning"
        :disabled="!warning"
        :target="inputId"
        variant="danger"
        triggers="manual">
        {{ warning }}
      </b-tooltip>
      <template #append v-if="$slots.default">
        <slot />
      </template>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: 'listing-form-input',
  props: {
    value: {},
    id: {},
    label: {},
    options: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {},
    warning: {},
    required: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Boolean,
      default: false,
    },
    customClass: '',
  },
  data() { return {localValue: this.value} },
  computed: {
    inputId() {
      if (this.id)
        return this.id;
      return `listing_${this.label}`.toLowerCase().replace(/\s/g, '_');
    },
    labelName() {
      if (!this.label)
        return '';

      const asterisk = this.required ? ' *' : '';
      return this.label + asterisk;
    },
    validCheck() {
      if (this.validate && this.required && !this.value)
        return 'invalid'
    },
    customClassIncluded() {
      let desiredClass = this.customClass || '';
      if (this.required && !this.localValue)
        desiredClass += ' field-warning-border'
      if (!this.$slots.default)
        desiredClass += ' no-slot-field'
      return desiredClass;
    }
  },
  methods: {
    handleKeydown(event) {
      const input = String.fromCharCode(event.keyCode);
      if (/[a-zA-Z0-9-_ ]/.test(input))
        this.$emit('textInput');
    }
  },
  watch: {
    value(newVal, _oldVal) {
      this.localValue = newVal;
    },
    localValue(newVal, oldVal) {
      this.$emit('input', this.localValue);
    },
  },
}

</script>
