import {
  Camera,
  ChartBar,
  CurrencyCircleDollar,
  FileCsv,
  Gauge,
  Key,
  ListDashes,
  UsersThree,
} from "phosphor-react";

export const topLinks = [
  {
    text: "Pre-owned",
    href: "/",
    children: [
      {
        href: "/",
        text: "Dashboard",
        Icon: Gauge,
      },
      {
        href: "/listings",
        text: "Listings",
        Icon: ListDashes,
      },
      {
        href: "/image_uploads",
        text: "Upload Images",
        Icon: Camera,
        hiddenForViewers: true,
      },
      {
        href: "/listings/dropship_bulk_updates",
        text: "Dropship Bulk Updates",
        Icon: FileCsv,
        hiddenForViewers: true,
      },
      {
        href: "/reports",
        text: "Reports",
        Icon: ChartBar,
        hiddenForViewers: true,
      },
      {
        href: "/listing_price_updates",
        text: "Price Update",
        Icon: CurrencyCircleDollar,
      },
    ],
  },
  {
    text: "Modern",
    href: "/modern",
    children: [
      {
        href: "/modern/dashboard",
        text: "Dashboard",
        Icon: Gauge,
      },
      {
        href: "/modern/listings",
        text: "Listings",
        Icon: ListDashes,
      },
    ],
  },
  {
    href: "/users",
    text: "Users",
    Icon: UsersThree,
  },
  {
    href: "/api_docs",
    text: "API Docs",
    Icon: Key,
    hiddenForViewers: true,
  },
];

export const linkIsActive = (href) => {
  const path = window.location.pathname;
  if (href === "/") return path === "/";

  const withoutGetParams = path.split(/[#?]/)[0];

  return withoutGetParams.split("/").slice(0, 3).join("/") === (href);
};

export const isAnyChildrenActive = (children) =>
  !!children.find(({ href }) => linkIsActive(href));

export const getVisibleLinksData = (links, hiddenLinks = []) =>
  (links || topLinks).filter(({ href }) => !hiddenLinks.includes(href));
