export default {
  determineMarkup,
  determineNet,
  formatToCurrency,
  formatToNumber,
  markupPrice,
  priceFromCents,
}

function determineMarkup(list, net) {
  return Math.round((list - net) / list * 100);
}

function determineNet(value, markupPercentage=15) {
  return toPrecision2(formatToNumber(value) * (1 - (markupPercentage / 100)));
}

function formatToCurrency(price=0){
  return `$${Math.round(formatToNumber(price)).toLocaleString("en-US")}`;
}

function formatToNumber(price){
  return Number((price || 0).toString().replace(/[^0-9.]+/g, ''));
}

function markupPrice(value, markupPercentage=15) {
  return toPrecision2(formatToNumber(value) / (1 - (markupPercentage / 100)));
}

function toPrecision2(value) {
  return Math.round(value * 100) / 100;
}

function priceFromCents(price) {
  return price / 100;
}
