import React from "react";
import classNames from "classnames";
import {
  Article,
  WarningOctagon,
  CheckCircle,
  Circle,
  ArrowBendDoubleUpLeft,
  Money,
  CircleHalf,
  TrafficSignal,
  Eye,
  Storefront,
  GraduationCap,
  ListChecks,
  PencilSimpleLine,
  Check,
  XCircle,
  ThumbsUp,
  Skull,
  CircleWavyWarning,
} from "phosphor-react";

const iconMap = {
  "Needs Content": { Icon: PencilSimpleLine, background: "#000" },
  "Update to Dual Listed": { Icon: Check, background: "#000" },
  "Content Completed": { Icon: ListChecks, background: "#5C5ABB" },
  "Content Needed": { Icon: Article, background: "#AD7A14" },
  Delisted: { Icon: ArrowBendDoubleUpLeft, background: "#393E43" },
  Incomplete: { Icon: Circle, background: "#BB3D00" },
  Listed: { Icon: Storefront, background: "#2773BA" },
  "Pending Review": { Icon: Eye, background: "#824AB2" },
  "Pending Sale": { Icon: CircleHalf, background: "#007C1C" },
  "Ready To List": { Icon: TrafficSignal, background: "#686868" },
  Sold: { Icon: Money, background: "#567300" },
  "Spec Completed": { Icon: CheckCircle, background: "#007E76" },
  "Spec Needed": { Icon: WarningOctagon, background: "#BB3D00" },
  "Training Review": { Icon: GraduationCap, background: "#BB326F" },
};

const modernIconMap = {
  "New": { Icon: CircleWavyWarning, background: "#BB3D00" },
  Unfinished: { Icon: CircleHalf, background: "#BB326F" },
  "Not Live": { Icon: Skull, background: "#2773BA" },
  Live: { Icon: ThumbsUp, background: "#007C1C" },
  Archived: { Icon: XCircle, background: "#393E43" },
};

const CardLink = ({ children, className, disabled, ...rest }) => (
  <a
    className={classNames(
      "d-card-link block lume-button-card-modern modern-dashboard-font p-3",
      {
        disabled,
        "bg-white": !disabled,
      },
      className
    )}
    {...rest}
  >
    {children}
  </a>
);

CardLink.Title = ({ children }) => (
  <h5 className="dashboard-card-title mt-3">{children}</h5>
);

CardLink.Separator = () => <div className="bar my-2" />;

CardLink.Icon = ({ cardTitle, modernLayout }) => {
  const { Icon, background } = modernLayout
    ? modernIconMap[cardTitle]
    : iconMap[cardTitle];

  return (
    <div className="icon-wrap modern-icon" style={{ background }}>
      <Icon color="#fff" size={24} />
    </div>
  );
};

export { CardLink };
