import React, { useState, useRef } from "react";
import CoverComponent from "./cover_component";
import { CaretDown, CaretUp } from "phosphor-react";
import { detectClickingOutside } from "global-helpers/click-handler";

const StateChange = ({ items, value, onChange, readOnly }) => {
  const itemsRef = useRef();
  const [show, setShow] = useState(false);

  const close = () => setShow(false);

  detectClickingOutside(itemsRef, close);

  const Caret = () =>
    show ? (
      <CaretUp size={16} weight="bold" />
    ) : (
      <CaretDown size={16} weight="bold" />
    );

  return (
    <div className="state-change">
      <CoverComponent item={value} onClick={() => !readOnly && setShow(!show)}>
        {!readOnly && <Caret />}
      </CoverComponent>
      {show && (
        <ul ref={itemsRef} className="state-change__items">
          {Object.keys(items).map((item) => (
            <li
              key={item}
              onClick={() => onChange("states", item) && close()}
              className="state-change__items__item"
            >
              {items[item]}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
export default StateChange;
