import React, { forwardRef, useImperativeHandle, useReducer } from "react";
import { RichEditor } from "components";
import classNames from "classnames";
import { Flask } from "phosphor-react";
import { ResetEditsButton } from "./index";
import { decodeEvergreen, isEmpty } from "global-helpers/listing";
import InputWrapper from "../pages/modern/input_wrapper";

import "react-quill/dist/quill.snow.css";

export const EvergreenContentForm = forwardRef((props, _ref) => {
  const {
    listing,
    isRequired,
    hasSpec,
    customizedFields,
    setCustomizedFields,
    ccSpecNumber,
  } = props;
  const [form, setForm] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      dek: decodeEvergreen(listing.dek),
      wwli: decodeEvergreen(listing.wwli),
      backstory: decodeEvergreen(listing.backstory),
      about_brand: decodeEvergreen(listing.about_brand),
    }
  );
  useImperativeHandle(_ref, () => ({
    getFormState: () => form,
  }));
  const onResetCustomField = (field, specValue) => {
    setForm({ [field]: specValue });
    customizedFields.splice(customizedFields.indexOf(field), 1);
    setCustomizedFields([...customizedFields]);
  };
  const onTextChange = (field, value, source) => {
    // If text change initiated by Quill api, don't do anything.
    if (source !== "user") return;
    if (!customizedFields.includes(field) && form[field] !== value) {
      setCustomizedFields([...customizedFields, field]);
    }
    setForm({ [field]: value });
  };

  const QuillLabel = ({ name, text }) => {
    const required = isRequired(name);
    const empty = isEmpty(form[name]);
    const title = `${text} ${required ? "(Required)" : ""}`;
    const isCustom = customizedFields?.includes(name);

    return (
      <span
        className={classNames("rich-editor__label quill-label", {
          "rich-editor__label--error": empty && required,
        })}
      >
        <div className="flex">
          {isCustom && <Flask size={16} className="mr-1" />}
          <div>{title}</div>
        </div>
        {ccSpecNumber && isCustom && (
          <ResetEditsButton resetKey={name} onReset={onResetCustomField} />
        )}
      </span>
    );
  };

  return (
    <div className="edit-form__container__content">
      <h2>Evergreen Content</h2>
      <div className="edit-form__container__content__rows">
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
              label="Dek"
              value={form.dek}
              type="html"
            >
              <RichEditor
                name="dek"
                text="Dek"
                isRequired={isRequired("dek")}
                onChange={(value, _, source) => onTextChange("dek", value, source)}
              />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
              label="Why We Love It"
              value={form.wwli}
              type="html"
            >
              <RichEditor
                isRequired={isRequired("wwli")}
                name="wwli"
                text="Why We Love It"
                onChange={(value, _, source) => onTextChange("wwli", value, source)}
              />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
              label="The Backstory"
              value={form.backstory}
              type="html"
            >
              <RichEditor
                isRequired={isRequired("backstory")}
                value={form.backstory}
                readOnly={hasSpec}
                text="The Backstory"
                onChange={(value, _, source) =>
                  onTextChange("backstory", value, source)
                }
              />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="About This Brand"
            value={form.about_brand}
            type="html"
          >
            <RichEditor
              name="about_brand"
              text="About This Brand"
              className="read-only"
              value={form.about_brand}
              readOnly
              onChange={(value, _, source) =>
                onTextChange("about_brand", value, source)
              }
            />
          </InputWrapper>
        </div>
      </div>
    </div>
  );
});
