import React from "react";
import Logo from "@images/small_logo.jpg";

const HodinkeeLogoLink = () => (
  <a href="/" className="hodinkee-logo-link">
    <img src={Logo} alt="HODINKEE Logo" className="h-10 w-10" />
  </a>
);

export default HodinkeeLogoLink;
