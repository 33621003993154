import he from "he";

const cloudinaryUrl =
  "https://api.cloudinary.com/v1_1/crownandcaliber/image/upload";

const toDataURL = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

export const isEmpty = (htmlStr) => !htmlStr || htmlStr === "<p><br></p>";
export const ageOptions = [
  "",
  "1950s",
  "1960s",
  "1970s",
  "1980s",
  "1990s",
  "2000s",
  "2010s",
  "2020 - Present",
];

export const getDimensions = (file) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
        size: (file.size / 1048576).toFixed(2),
      });
    };
    img.src = URL.createObjectURL(file);
  });
};

/**
 * As of 2018, cross origin download links are disabled by default in the browser. This is a workaround.
 * @param {*} merchandisingName
 */
export const crossDownload = async (href) => {
  const hrefSplit = href.split("/");
  const a = document.createElement("a");
  a.href = await toDataURL(href);
  a.download = hrefSplit[hrefSplit.length - 1];
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const uploadToCloudinary = async (file, galleryLength) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("cloud_name", "crownandcaliber");
  formData.append("folder", "listings_portal");
  formData.append("upload_preset", "d98lcgt7");
  const res = await fetch(cloudinaryUrl, { method: "POST", body: formData });
  const json = await res.json();
  const path = `v${json.version}/${json.public_id}`;
  return {
    cropped_path: null,
    format: json.format,
    id: null,
    path,
    url: `https://res.cloudinary.com/crownandcaliber/${path}`,
    public_id: json.public_id,
    resource_type: json.resource_type,
    sort_order: galleryLength + 1,
    version: `${json.version}`,
  };
};

/**
 * Let N be length of initial. If initial is null, undefined, or empty list, return new list of 4 empty strings.
 * Otherwise, return array of (N strings) + (4 - N empty strings).
 * @param {*} initial
 * @returns array of length 4
 */
export const getInitialRelatedArticles = (initial) => {
  if (initial?.length === 4) return initial;
  (initial ??= []).push(...Array(4 - initial.length).fill(""));
  return initial;
};

const getRequestOpts = () => ({
  credentials: "same-origin",
  mode: "cors",
  redirect: "follow",
  headers: {
    "Content-Type": "text/plain;charset=UTF-8",
    "x-csrf-token": document.querySelector('meta[name="csrf-token"]').content,
  },
});

export const putListing = async (requestBody) => {
  const response = await fetch(`/listings/${requestBody.lid}`, {
    ...getRequestOpts(),
    method: "PUT",
    body: JSON.stringify({
      listing: requestBody,
    }),
  });

  if (response.status === 200) {
    window.location.href = `/listings/${requestBody.lid}`;
  } else {
    console.error("Error occurred while updating listing.", response);
  }
};

export const putModernListing = async (requestBody) => {
  const response = await fetch(`/modern/listings/${requestBody.id}`, {
    ...getRequestOpts(),
    method: "PUT",
    body: JSON.stringify({
      modern_listing: requestBody,
    }),
  });

  if (response.status == 200) {
    return true;
  } else {
    return await response.json();
  }
};

export const getModernListing = async (id, validateForEvent) => {
  const response = await fetch(
    `/v4/modern/listings/${id}?${new URLSearchParams({
      validate_for_event: validateForEvent,
    })}`,
    {
      ...getRequestOpts(),
      method: "GET",
    }
  );

  if (response.status === 200) {
    return await response.json();
  } else {
    console.error("Error occurred while updating listing.", response);
  }
};

export const updateStateModernListing = async (id, event) => {
  const response = await fetch(`/modern/listings/${id}/update_state`, {
    ...getRequestOpts(),
    method: "PUT",
    body: JSON.stringify({ event }),
  });

  const jsonResponse = response.status == 422 ? {} : await response.json();

  return { status: response.status, response: jsonResponse };
};

export const searchParamsToJson = (search) => {
  if (!search) return {};
  const searchParams = search.substring(1);
  if (!searchParams) return {};
  const paramsJson = JSON.parse(
    '{"' +
      decodeURI(searchParams)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/%2C/g, ",")
        .replace(/\[\]/g, "")
        .replace(/=/g, '":"') +
      '"}'
  );

  return Object.entries(paramsJson).reduce(
    (reduced, [key, value]) => ({
      ...reduced,
      [key]: decodeURIComponent(value).replace(/\+/g, " "),
    }),
    {}
  );
};

const getExportStatus = async (fileId) => {
  const response = await fetch(
    `/v4/modern/listings/download_file_status?file_id=${fileId}`,
    {
      ...getRequestOpts(),
      method: "GET",
    }
  );

  const jsonResponse = await response.json();

  return response.status === 200 && jsonResponse.processed;
};

export const exportModernListing = async (params) => {
  const response = await fetch(
    `/v4/modern/listings/export${
      Object.keys(params).length > 0 ? `?${new URLSearchParams(params)}` : ""
    }`,
    {
      ...getRequestOpts(),
      method: "GET",
    }
  );

  const jsonResponse = await response.json();

  if (response.status === 201) {
    let processed = await getExportStatus(jsonResponse.file_id);
    let attempts = 0;

    while (!processed) {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      processed = await getExportStatus(jsonResponse.file_id);
      attempts++;
      if (attempts > 10) return false;
    }

    window.location.href = jsonResponse.file_path;
    return true;
  }
  return false;
};

export const applyGetParams = (params) =>
  (window.location.href = `${
    window.location.href.split("?")[0]
  }?${new URLSearchParams(params)}`);

export const getModernListings = async (params) => {
  const response = await fetch(
    `/v4/modern/listings${
      Object.keys(params).length > 0 ? `?${new URLSearchParams(params)}` : ""
    }`,
    {
      ...getRequestOpts(),
      method: "GET",
    }
  );

  if (response.status === 200) {
    return await response.json();
  } else {
    console.error("Error occurred while updating listing.", response);
  }
};

export const dateToDatabase = (date) => {
  if (!date) return "";
  if (!(date instanceof Date)) return date;

  const [_, m, d, y] = new Date(date).toDateString().split(" ");

  return `${y}-${m}-${d}`;
};
const convertDate = (date) => {
  if (!date) return;
  if (date instanceof Date) return date;

  return new Date(`${date}T12:00:00`);
};
export const dateToDisplay = (date) => {
  if (!date) return "";

  date = convertDate(date);

  const [_, m, d, y] = (date instanceof Date ? date : new Date(date))
    .toDateString()
    .split(" ");
  return `${m} ${d}, ${y}`;
};

export const getName = ({
  brand,
  _model_name,
  nickname,
  merchandising_model_number,
  model_number,
  edition_type,
}) =>
  [
    brand,
    _model_name,
    nickname,
    merchandising_model_number,
    edition_type?.length > 0 ? `${edition_type} Edition` : "",
  ].join(" ") || model_number;

export const decodeEvergreen = (str) => {
  return he
    .decode(str || "")
    .replace(/\s+<\//gm, "</")
    .trim();
};

export const MODERN_STATES = {
  new_listing: "New",
  unfinished: "Unfinished",
  ready_for_live: "Not Live",
  live: "Live",
  archived: "Archived",
};
