import React, { useMemo } from "react";
import { CaretLeft, CaretRight } from "phosphor-react";
import classNames from "classnames";

const PaginationComponent = ({ onClick, pageCount, page }) => {
  const currentPage = parseInt(page);
  const lastPageToShow = currentPage + 4;
  const showAll = pageCount < 10;

  const isActive = (index) => currentPage === index + 1;

  const showEllipsisLeft = (index) =>
    !showAll && currentPage > 2 && index === 1; // replace the second page link for ellipsis if the current page is not the second

  const showEllipsisRight = (index) =>
    !showAll &&
    pageCount > lastPageToShow && // show the right ellipsis if the current page is far from the last page to show
    index == lastPageToShow && // show the ellipsis in the last page to show place
    index + 1 < pageCount; // don't show the ellipsis after the last page link

  const showPageLink = (index) =>
    showAll ||
    index === 0 || // show the first page link
    isActive(index) || // show the current page link
    index === pageCount - 1 || // show the last page link
    (pageCount - 5 <= index + 1 && pageCount - 5 < currentPage) || // show at least 4 links if the users is in one of last pages
    (index >= currentPage && index < lastPageToShow); // show next 4 links

  return (
    <div className="pagination">
      {page > 1 && (
        <button
          className="pagination__item"
          onClick={() => onClick(currentPage - 1)}
        >
          <CaretLeft size={14} weight="bold" />
        </button>
      )}
      {[...new Array(pageCount)].map((_, index) => (
        <>
          {showEllipsisLeft(index) && "..."}
          {showPageLink(index) && (
            <button
              key={index}
              className={classNames("pagination__item", {
                "pagination__item--active": isActive(index),
              })}
              onClick={() => !isActive(index) && onClick(index + 1)}
            >
              {index + 1}
            </button>
          )}
          {showEllipsisRight(index) && "..."}
        </>
      ))}
      {page < pageCount && (
        <button
          className="pagination__item"
          onClick={() => onClick(currentPage + 1)}
        >
          <CaretRight size={14} weight="bold" />
        </button>
      )}
    </div>
  );
};

export const Pagination = ({ onClick, pageCount, page }) =>
  useMemo(
    () => (
      <PaginationComponent
        onClick={onClick}
        pageCount={pageCount}
        page={page}
      />
    ),
    [onClick, pageCount, page]
  );
