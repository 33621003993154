import specsService from '../services/specsService';
import he from "he";

export default {
  hasSpec,
  isRequired,
  isCustomized,
  resetFieldToDefaultValue,
  flagFieldAsCustomized
}

function hasSpec(state) {
  return !!state.listing.ccspec_number && /CCS-...-.*/.test(state.listing.ccspec_number);
}

function isRequired(field, state) {
  return state.requiredFields.common.includes(field) || state.requiredFields[state.listing.shop]?.includes(field);
}

function isCustomized(field, state) {
  return hasSpec(state) && state.listing.customized_fields.includes(field);
}

const evergreenFields = ['dek', 'wwli', 'backstory', 'about_brand'];

function resetFieldToDefaultValue(field, state) {
  if (!hasSpec(state))
    return;

  specsService.ccspec(state.listing.ccspec_number, (res) => {
    if (res) {
      let attrs = {};
      attrs[field] = res.ccspec[field];

      // HTML unescape EG fields
      for (const key in attrs) {
        if (evergreenFields.includes(key) && attrs[key] != null) {
          attrs[key] = he.decode(attrs[key]).replace(/\s+<\//gm, '</').trim();
        }
      }

      Object.assign(state.listing, attrs);

      for (let i = 0; i < state.listing.customized_fields.length; i++){
        if (state.listing.customized_fields[i] === field) {
          state.listing.customized_fields.splice(i, 1);
        }
      }
    }
  });
}

function flagFieldAsCustomized(field, state) {
  if (!state.listing.customized_fields.includes(field))
    state.listing.customized_fields.push(field);
}
