<template>
  <b-form-group :label="labelName" v-bind:class="{ required: required }">
    <i v-if="disabled" class="fa fa-lock field-icon locked-field-icon" />
    <i v-if="required && !localValue"
      class="fa fa-exclamation-triangle orange-font tt-error field-icon"
      :id="`tooltip-target-${inputId}`"
      v-b-tooltip.html
      title="Can't be blank" />
    <b-input-group>
      <b-form-textarea :id="inputId"
        v-model="localValue"
        :disabled="disabled"
        :required="required"
        :rows="rows"
        :class="customClassIncluded"
        @change="$emit('change', localValue)"/>
      <b-tooltip
        :show="!!warning"
        :disabled="!warning"
        :target="inputId"
        variant="danger"
        triggers="manual">
        {{ warning }}
      </b-tooltip>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: 'listing-form-text',
  props: {
    value: {},
    id: {},
    label: {},
    rows: {
      type: Number,
      default: 3,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    warning: {},
    required: {
      type: Boolean,
      default: false,
    },
    customClass: '',
  },
  data() { return {localValue: this.value} },
  computed: {
    inputId() {
      if (this.id)
        return this.id;
      return `listing_${this.label}`.toLowerCase().replace(/\s/g, '_');
    },
    labelName() {
      if (!this.label)
        return '';

      const asterisk = this.required ? ' *' : '';
      return this.label + asterisk;
    },
    customClassIncluded() {
      let desiredClass = this.customClass || '';
      if (this.required && !this.localValue)
        desiredClass += ' field-warning-border'
      if (!this.$slots.default)
        desiredClass += ' no-slot-field'
      return desiredClass;
    }
  },
  watch: {
    value(newVal, _oldVal) {
      this.localValue = newVal;
    },
    localValue(newVal, oldVal) {
      this.$emit('input', this.localValue);
    },
  },
}

</script>
