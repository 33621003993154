import React, { useState } from "react";
import TopLinks from "./top_links";
import HodinkeeLogoLink from "./hodinkee_logo_link";
import BottomLinks from "./bottom_links";
import { List, Minus } from "phosphor-react";

const MobileSidebar = ({ fullName, role, ...rest }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div {...rest}>
      <div className="static-section">
        <HodinkeeLogoLink />
        <button onClick={() => setExpanded(!expanded)}>
          {expanded ? <Minus size={24} /> : <List size={24} />}
        </button>
      </div>
      {expanded && (
        <>
          <TopLinks role={role} />
          <ul>
            <BottomLinks fullName={fullName} />
          </ul>
        </>
      )}
    </div>
  );
};

export default MobileSidebar;
