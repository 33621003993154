import React, { useRef, useEffect, useReducer, useState } from "react";
import { MagnifyingGlass, DownloadSimple } from "phosphor-react";
import { MultiSelect, ActionInfo, Button, Pagination } from "components";
import StateSelectComponent from "./state_select";
import SortByFilter from "./sort_by_filter";
import Edit from "./edit";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  dateToDisplay,
  updateStateModernListing,
  getName,
  exportModernListing,
  getModernListings,
  searchParamsToJson,
} from "global-helpers/listing";

import "./search.scss";

const prepareArrayFilters = (filter) => {
  if (!filter) return [];

  return filter instanceof Array ? filter : filter.split(",");
};

const Index = ({ brands, policy = {} }) => {
  const inputRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = searchParamsToJson(location.search);
  const [loadingExport, setLoadingExport] = useState(false);
  const [modernListings, setModernListings] = useState();
  const [totalCount, setTotalCount] = useState(null);
  const [pagination, setPagination] = useState();
  const [form, setForm] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      query: searchParams.query || "",
      brands: prepareArrayFilters(searchParams.brands),
      states: prepareArrayFilters(searchParams.states),
      order: searchParams.order || "",
    }
  );

  useEffect(async () => {
    const response = await getModernListings(searchParams);

    setModernListings(response.modern_listings);
    setTotalCount(response.total_count);
    setPagination(response.pagination);
  }, [location.search]);

  const handlePaginate = (page) => fetchModernListings({ ...form, page });
  const fetchModernListings = (params) => {
    navigate({
      pathname: "/modern/listings",
      search: `?${new URLSearchParams(params)}`,
    });
  };
  const anyFilter = () => Object.keys(form).find((key) => form[key].length);
  const handleMultiSelectEdit = (name, value) => setForm({ [name]: value });
  const handleSubmit = async (event) => {
    event.preventDefault();
    fetchModernListings(form);
  };
  const handleFormEdit = ({ target: { name, value } }) =>
    setForm({ [name]: value });
  const clearFilter = () => {
    const hrefSplit = window.location.href.split("?");

    if (hrefSplit.length > 1) {
      fetchModernListings({});
    }

    setForm({
      query: "",
      brands: [],
      states: [],
      order: "",
    });
  };
  const changeState = async (key, event) => {
    const modernListingId = modernListings[key].id;
    const { response, status } = await updateStateModernListing(
      modernListingId,
      event
    );

    if (status === 200) {
      const newState = [...modernListings];
      newState[key] = response;

      setModernListings(newState);
    } else {
      navigate({
        pathname: `/modern/listings/${modernListingId}/edit`,
        search: `?${new URLSearchParams({ validate_for_event: event })}`,
      });
    }
  };

  const downloadCsv = async () => {
    setLoadingExport(true);
    await exportModernListing(searchParams);
    setLoadingExport(false);
  };

  return (
    <div className="search-page">
      <div className="search-page__top">
        <h1>Modern Listings {!!totalCount && `(${totalCount})`}</h1>
        <Button
          className="small"
          variant="tertiary"
          disabled={loadingExport}
          onClick={downloadCsv}
        >
          <DownloadSimple className="mr-1" size={14} weight="bold" />
          export all
        </Button>
      </div>
      <form onSubmit={handleSubmit} action="/modern/listings">
        <div
          className="search-page__input-container"
          onClick={() => inputRef.current.focus()}
        >
          <MagnifyingGlass size={24} />
          <input
            ref={inputRef}
            name="query"
            value={form.query}
            onChange={handleFormEdit}
            placeholder="Search Listing"
          />
          <Button variant="primary">search</Button>
        </div>
      </form>
      <div className="search-page__filters">
        <StateSelectComponent
          value={form.states}
          onChange={handleMultiSelectEdit}
        />
        <MultiSelect
          label="Brands"
          name="brands"
          items={brands.map(({ name }) => name).sort()}
          value={form.brands}
          onChange={handleMultiSelectEdit}
          labelCover
        />
        <SortByFilter value={form.order} onChange={handleMultiSelectEdit} />
        <Button
          className="small"
          variant="primary"
          onClick={() => fetchModernListings(form)}
        >
          submit
        </Button>
        <Button
          className="small"
          variant="tertiary"
          disabled={!anyFilter()}
          onClick={clearFilter}
        >
          Clear Filters
        </Button>
      </div>
      <div className="search-page__listings">
        {modernListings?.map((modernListing, key) => (
          <div key={key} className="search-page__listings__item">
            {
              // <div className="search-page__listings__item__image"></div>
            }
            <div className="search-page__listings__item__content">
              <div className="search-page__listings__item__content__header">
                <a href={`/modern/listings/${modernListing.id}`}>
                  <h3>{getName(modernListing)}</h3>
                </a>
                {policy.edit && (
                  <Button
                    href={`/modern/listings/${modernListing.id}/edit`}
                    isSpa
                  >
                    Edit
                  </Button>
                )}
              </div>
              <div className="search-page__listings__item__content__status">
                <StateSelectComponent
                  value={modernListing.state}
                  possibleEvents={modernListing.possible_events}
                  onChange={(_, value) => changeState(key, value)}
                  readOnly={!policy.edit}
                  stateChange
                  singleValue
                />
              </div>
              <div className="search-page__listings__item__content__footer">
                <ActionInfo label="sku">{modernListing.sku}</ActionInfo>
                <ActionInfo
                  label="shopify id"
                  href={modernListing.store_admin_url}
                >
                  {modernListing.shopify_id}
                </ActionInfo>
                <ActionInfo className="border-0" label="launch date" noAction>
                  {dateToDisplay(modernListing.launch_date)}
                </ActionInfo>
              </div>
            </div>
          </div>
        ))}
      </div>
      {pagination && (
        <Pagination
          onClick={handlePaginate}
          pageCount={pagination.limit}
          page={pagination.page}
        />
      )}
    </div>
  );
};

const Root = (props) => {
  return (
    <Router>
      <Routes>
        <Route path="/modern/listings" element={<Index {...props} />} />
        <Route path="/modern/listings/:id/*" element={<Edit {...props} />} />
      </Routes>
    </Router>
  );
};

export default Root;
