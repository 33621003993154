import React, { useState, useRef } from "react";
import ReactCalendar from "react-calendar";
import { CaretLeft, CaretRight, CalendarBlank, X } from "phosphor-react";
import { TextInput } from "@hodinkee/lume-ui";
import classnames from "classnames";
import { dateToDisplay } from "global-helpers/listing";
import { detectClickingOutside } from "global-helpers/click-handler";
import "./style";

const convertDate = (date) => {
  if (!date) return;
  if (date instanceof Date) return date;

  return new Date(`${date}T12:00:00`);
};

export const Calendar = ({
  label,
  value,
  name,
  onChange,
  scheduledDates,
  disabledDates,
  disablePreviousDates,
}) => {
  const todayDate = new Date();
  const convertedDate = convertDate(value);
  const calendarContainerRef = useRef();
  const calendarInput = useRef();
  const [hidden, setHidden] = useState(true);
  const [activeStartDate, setActiveStartDate] = useState(
    convertedDate?.length ? convertedDate : todayDate
  );
  const [_wDay, month, _day, year] = (activeStartDate || todayDate)
    .toDateString()
    .split(" ");

  const onHideCalendar = () => setHidden(true);
  const goToToday = () => setActiveStartDate(todayDate);
  const clear = () => {
    calendarInput.current.focus();
    onChange("");
    setHidden(false);
  };

  const onChangeMonth = (monthQt) => {
    const newDate = new Date(activeStartDate);
    newDate.setMonth(newDate.getMonth() + monthQt);

    setActiveStartDate(newDate);
  };

  const onShowCalendar = () => {
    setActiveStartDate(convertedDate || todayDate);
    setHidden(false);
  };

  const isDisabledDate = (date) => {
    if (disablePreviousDates) {
      return (
        todayDate.toDateString() !== date.toDateString() && todayDate > date
      );
    }

    return disabledDates?.includes(date.toLocaleDateString("en-US"));
  };

  detectClickingOutside(calendarContainerRef, onHideCalendar);

  return (
    <div className="calendar-group">
      <TextInput
        name={name}
        ref={calendarInput}
        label={label || "Date"}
        onClick={onShowCalendar}
        onKeyDown={({ key }) => key == "Backspace" && clear()}
        value={dateToDisplay(convertedDate)}
        iconSrc={<CalendarBlank />}
      />
      {value && (
        <X size={12} onClick={clear} className="calendar-group__clear-icon" />
      )}

      {!hidden && (
        <div ref={calendarContainerRef} className="calendar-container">
          <div className="calendar-header">
            <div className="calendar-header__title">{`${month} ${year}`}</div>
            <div className="calendar-header__control">
              <button onClick={() => onChangeMonth(-1)}>
                <CaretLeft />
              </button>
              <button onClick={goToToday}>Today</button>
              <button onClick={() => onChangeMonth(1)}>
                <CaretRight />
              </button>
            </div>
          </div>
          <ReactCalendar
            activeStartDate={activeStartDate}
            showNeighboringMonth={false}
            showNavigation={false}
            onChange={onChange}
            value={convertedDate || new Date()}
            formatShortWeekday={(_, value) =>
              ["S", "M", "T", "W", "T", "F", "S"][value.getDay()]
            }
            tileDisabled={({ _, date }) => isDisabledDate(date)}
            tileContent={({ _, date }) => <div>{date.getDate()}</div>}
            tileClassName={({ _, date }) =>
              classnames({
                "react-calendar__tile--disabled": isDisabledDate(date),
                "react-calendar__tile--scheduled": scheduledDates?.includes(
                  date.toLocaleDateString("en-US")
                ),
              })
            }
          />
        </div>
      )}
    </div>
  );
};
