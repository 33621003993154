import React, { forwardRef, useImperativeHandle, useReducer } from "react";
import { Checkbox, Option, Select, TextInput } from "@hodinkee/lume-ui";
import { formatToCurrency, formatToNumber } from "global-helpers/currency";

export const WatchDetailsForm = forwardRef((props, _ref) => {
  const {
    listing,
    setShop,
    shops,
    specEditable,
    specsData,
    generateRequiredProps,
    specHodinkeeIneligible,
    readyOrListed,
  } = props;
  const [form, setForm] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      hodinkee_ineligible: listing.hodinkee_ineligible || "",
      sku: listing.sku || "",
      serial_number: listing.serial_number || "",
      brand_name: listing.brand_name || "",
      shop: listing.shop || "",
      _model_name: listing._model_name || "",
      nickname: listing.nickname || "",
      model_number: listing.model_number || "",
      merchandising_model_number: listing.merchandising_model_number || "",
      current_price_cents: listing.current_price_cents || "0",
      cogs_cents: listing.cogs_cents || "",
      retail_price_cents: listing.retail_price_cents || "0",
      status: listing.status || "",
    }
  );
  const handleFormEdit = (event) => {
    const { name, value } = event.target;
    setForm({ [name]: value });
  };
  const handlePriceEdit = (event) => {
    const { name, value } = event.target;
    setForm({ [name]: formatToNumber(value) * 100 });
  };
  const onHodinkeeIneligibleChange = () => {
    setForm({ hodinkee_ineligible: !form.hodinkee_ineligible });
    const crownAndCaliber = "crownandcaliber";
    // If setting hodinkee_ineligible to true, only valid shop value is "crownandcaliber".
    if (form.shop !== crownAndCaliber && !form.hodinkee_ineligible) {
      setForm({ shop: crownAndCaliber });
      setShop(crownAndCaliber);
    }
  };
  const selectShopDisabled = form.hodinkee_ineligible || readyOrListed;

  useImperativeHandle(_ref, () => ({
    getFormState: () => {
      form.current_price = form.current_price_cents / 100;
      return form;
    },
  }));

  return (
    <div className="edit-form__container__content">
      <div className="flex justify-between items-center">
        <h2>Watch Details</h2>
        <div className="mb-4">
          <Checkbox
            name="hodinkee_ineligible"
            // If hodinkee ineligible is true on spec, this checkbox should be disabled.
            value={`${specHodinkeeIneligible || form.hodinkee_ineligible}`}
            readOnly={specHodinkeeIneligible || readyOrListed}
            disabled={specHodinkeeIneligible || readyOrListed}
            onChange={onHodinkeeIneligibleChange}
            checked={form.hodinkee_ineligible}
          >
            Ineligible for H Shop?
          </Checkbox>
        </div>
      </div>
      <div className="edit-form__container__content__rows">
        <div className="edit-form__container__content__rows__row edit-form__container__content__rows__row--responsive">
          <TextInput
            name="sku"
            label="SKU"
            readOnly
            disabled
            value={form.sku}
            onChange={handleFormEdit}
          />
          <TextInput
            name="serial_number"
            label="Serial Number"
            readOnly
            disabled
            value={form.serial_number}
            onChange={handleFormEdit}
          />
          <Select
            {...generateRequiredProps("brand_name", form.brand_name, true)}
            readOnly={!!listing.lid}
            disabled={!!listing.lid}
            onChange={handleFormEdit}
          >
            {specsData.brands.map(({ name }) => (
              <Option value={name} key={name}>
                {name}
              </Option>
            ))}
          </Select>
          <Select
            {...generateRequiredProps("shop", form.shop, true)}
            readOnly={selectShopDisabled}
            disabled={selectShopDisabled}
            onChange={(e) => {
              handleFormEdit(e);
              setShop(e.target.value);
            }}
          >
            {Object.keys(shops).map((shop) => (
              <Option value={shop} key={shop}>
                {shops[shop]}
              </Option>
            ))}
          </Select>
        </div>
        <div className="edit-form__container__content__rows__row">
          <TextInput
            {...generateRequiredProps(
              "current_price_cents",
              form.current_price_cents,
              true
            )}
            onChange={handlePriceEdit}
            value={formatToCurrency(form.current_price_cents)}
            label="List Price (Required)"
          />
          <TextInput
            {...generateRequiredProps("cogs_cents", form.cogs_cents, true)}
            readOnly
            disabled
            onChange={handlePriceEdit}
            value={formatToCurrency(form.cogs_cents, true)}
            label="COGS (Required)"
          />
          <TextInput
            name="retail_price_cents"
            label="Retail Price"
            value={formatToCurrency(form.retail_price_cents, true)}
            onChange={handlePriceEdit}
          />
        </div>
        <div className="edit-form__container__content__rows__row edit-form__container__content__rows__row--responsive">
          {specEditable ? (
            <TextInput
              {...generateRequiredProps("_model_name", form._model_name, true)}
              onChange={handleFormEdit}
            />
          ) : (
            <Select
              {...generateRequiredProps("_model_name", form._model_name, true)}
              readOnly={!specEditable}
              disabled={!specEditable}
              onChange={handleFormEdit}
            >
              {specsData.models.map(({ name }) => (
                <Option value={name} key={name}>
                  {name}
                </Option>
              ))}
            </Select>
          )}
          <TextInput
            {...generateRequiredProps("model_number", form.model_number, true)}
            readOnly={!specEditable}
            disabled={!specEditable}
            onChange={handleFormEdit}
          />
          <TextInput
            {...generateRequiredProps(
              "merchandising_model_number",
              form.merchandising_model_number,
              true
            )}
            onChange={handleFormEdit}
          />
          <TextInput
            name="nickname"
            label="Nickname"
            value={form.nickname}
            onChange={handleFormEdit}
          />
        </div>
      </div>
    </div>
  );
});
