import React, { useState, useRef, useEffect } from "react";
import { MagnifyingGlass, Check } from "phosphor-react";
import { Checkbox } from "@hodinkee/lume-ui";
import classNames from "classnames";

const MultiSelectSearch = ({
  label,
  onCheckboxClick,
  selectedItems,
  items,
  close,
  hideSearchInput,
  singleValue,
}) => {
  const [searchText, setSearchText] = useState("");
  const searchInputRef = useRef(null);
  const filteredItems = Object.keys(items).filter((item) =>
    item.toLowerCase().includes(searchText.toLowerCase())
  );
  useEffect(() => {
    searchInputRef.current.focus(); // When component loaded, auto focus search input.
  }, []);

  // If only one item left in dropdown, on enter key press, toggle that item.
  const onKeyDown = (e) => {
    if (e.key === "Enter" && filteredItems.length === 1) {
      e.preventDefault();
      e.stopPropagation();
      onCheckboxClick(filteredItems[0]);
    }
  };
  return (
    <div
      className="multi-select-search"
      onKeyDown={(e) => e.key === "Escape" && close()}
    >
      <div className="search">
        <h6>{label}</h6>
        <div
          className={classNames({
            hidden: hideSearchInput,
            "search-input-group": !hideSearchInput,
          })}
        >
          <MagnifyingGlass className="search-icon" size={16} />
          <input
            ref={searchInputRef}
            value={searchText}
            placeholder={`Search ${label.toLowerCase()}`}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={onKeyDown}
          />
        </div>
        {filteredItems.map((value, key) => {
          const text = items[value];
          const checked = selectedItems.includes(value);

          return (
            <Checkbox
              key={key}
              name={text}
              value={value}
              onChange={() => onCheckboxClick(value)}
              checked={checked}
              className={classNames({
                flex: singleValue,
                "single-checked": singleValue,
                "single-checked--checked": singleValue && checked,
              })}
            >
              {singleValue ? (
                <span className="single-checked__label">
                  <span>{singleValue && checked && <Check size={14} />}</span>
                  <span>{text}</span>
                </span>
              ) : (
                text
              )}
            </Checkbox>
          );
        })}
      </div>
    </div>
  );
};

export default MultiSelectSearch;
