<template>
  <div class="listing-form"> <!-- CHANGE TO ID -->
    <div class="dashboard-header">
      <h1 class="header-text">
        <a :href="cancelLink" class="md-btn md-flat">
          <i class="fa fa-angle-left mr-2 pb-1" />
          Back to Watch Details
        </a>
        <div id="edit-listing-dropdown" class="pull-right btn-group dropdown">
          <b-button variant="dark" :disabled="state.waiting" @click="submitForm">
            <i class="fa fa-spinner fa-pulse fa-fw" v-if="state.waiting" />
            Save
          </b-button>
        </div>
      </h1>
    </div>
    <b-row>
      <b-col md="9">
        <div class="merchandising-name mt-4 mb-4">
          <h1>{{ state.listing.merchandising_name }}</h1>
        </div>
      </b-col>
      <b-col md="3" class="auto-margin mb-4">
        <div v-if="hasSpec" id="ccspec-card" class="card shadow-none pl-2 pr-2 auto-margin">
          <div class="text-muted">
            {{ state.listing.ccspec_number }}
            <a v-if="state.userManager" @click.prevent="removeCcspec">
              <i class="fa fa-circle-xmark pb-1" />
            </a>
          </div>
        </div>
      </b-col>
    </b-row>

    <div class="padding" v-if="state.errors || state.warnings || state.listerNotes">
      <Alerts :messages="state.errors" variant="danger" />
      <Alerts :messages="state.warnings" variant="warning" />
      <Alerts :messages="state.listerNotes" variant="info" />
    </div>

    <div class="grid">
      <div class="grid-sizer"></div>
      <div class="gutter-sizer"></div>
      <div class="grid-item grid-item--width2">
        <WatchDetails />
      </div>
      <div class="grid-item">
        <PrimaryImage @rebuildLayout="rebuildLayout" />
      </div>
      <div class="grid-item grid-item--width2">
        <ConditionReport @rebuildLayout="rebuildLayout" />
      </div>
      <div class="grid-item">
        <FeaturesAndSpecs />
      </div>
      <div class="grid-item grid-item--width2">
        <WatchStory @rebuildLayout="rebuildLayout" />
      </div>
      <div class="grid-item grid-item--width2">
        <StoriesAboutThisWatch />
      </div>
      <div class="grid-item grid-item--width2">
        <ListingFormVideo @rebuildLayout="rebuildLayoutDelayed" />
      </div>
      <div class="grid-item grid-item--width2">
        <ImageGallery
          imageType="product"
          @rebuildLayout="rebuildLayoutDelayed" />
      </div>
      <div class="grid-item grid-item--width2">
        <ImageGallery
          imageType="lifestyle"
          @rebuildLayout="rebuildLayoutDelayed"
          @imageUploaded="flagAsCustomized('lifestyle_images')" />
      </div>
      <div class="grid-item grid-item--width2">
        <BrandDetails />
      </div>
      <div class="grid-item grid-item--width2 grid-item--last">
        <b-button variant="dark" :disabled="state.waiting" @click="submitForm">
          <i class="fa fa-spinner fa-pulse fa-fw" v-if="state.waiting" />
          Save
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Alerts from './Alerts';
import WatchDetails from './WatchDetails';
import PrimaryImage from './PrimaryImage';
import ConditionReport from './ConditionReport';
import FeaturesAndSpecs from './FeaturesAndSpecs';
import WatchStory from './WatchStory';
import StoriesAboutThisWatch from './StoriesAboutThisWatch';
import ListingFormVideo from './ListingFormVideo';
import ImageGallery from './ImageGallery';
import BrandDetails from './BrandDetails';
import ListingFormInput from './ListingFormInput';
import store from '../stores/listingFormStore';
import layout from '../services/formLayoutBuilder';
import formUtils from '../services/formUtils';
import utils from '../services/utils';

export default {
  name: 'listing-form',
  components: {
    Alerts,
    WatchDetails,
    PrimaryImage,
    ConditionReport,
    FeaturesAndSpecs,
    WatchStory,
    StoriesAboutThisWatch,
    ListingFormVideo,
    ImageGallery,
    BrandDetails,
    ListingFormInput,
  },
  data: function () {
    return {
      state: store.state
    };
  },
  computed: {
    cancelLink() {
      return this.state.lid ? `/listings/${store.state.lid}` : "/";
    },
    hasSpec() { return formUtils.hasSpec(store.state) }
  },
  methods: {
    submitForm: store.submitForm,
    removeCcspec() { store.removeCcspec() },
    rebuildLayout: utils.throttle(() => {
      layout.build();
    }, 1500),
    rebuildLayoutDelayed() {
      setTimeout(() => { this.rebuildLayout(); }, 1000);
    },
    flagAsCustomized(field) { formUtils.flagFieldAsCustomized(field, store.state) }
  },
  mounted: function () {
    layout.build(true);
  },
}
</script>

<style scoped>
.grid-sizer { width: 23%; }
.grid-item { width: 25%; }
.gutter-sizer { width: 2%; }
.grid-item--width2 { width: 73%; }

.fa.edit-merchandising-name {
  font-size: xx-large;
  padding-bottom: 0.6rem;
}

#ccspec-card {
  height: auto;
  line-height: 2.3rem;
  font-size: 1.3rem;
  background-color: #dedede;
  border-color: #dedede;
  display: block;
  font-family: 'Roboto Mono', monospace;
  float: right;
  margin-bottom: 0;
}

#listing-form .required label:after {
  content: ' *';
  color: #ff5a00;
  font-weight: bold;
}

input.is-invalid,
select.is-invalid {
  background-color: #f9e1e1;
}

.custom-select {
  cursor: pointer;
}

.grid-item--last {
  margin-bottom: 5rem;
}
</style>
