import React, { forwardRef, useImperativeHandle, useReducer } from "react";
import { Calendar } from "components";
import { TextInput, Select, Option, Checkbox } from "@hodinkee/lume-ui";

export const ConditionReportForm = forwardRef((props, _ref) => {
  const { listing, generateRequiredProps } = props;
  const [form, setForm] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      condition: listing.condition || "",
      paper_date: listing.paper_date || "",
      max_wrist_size: listing.max_wrist_size || "",
      additional_accessories: listing.additional_accessories || "",
      box: listing.box || "no",
      papers: listing.papers || "no",
      manual: listing.manual || "no",
      qc_notes: listing.qc_notes || "",
      condition_notes: listing.condition_notes || "",
    }
  );
  const toggleCheckboxYesNo = (name) => {
    const value = form[name];
    setForm({ [name]: value === "no" ? "yes" : "no" });
  };
  const handleFormEdit = (event) => {
    const { name, value } = event.target;
    setForm({ [name]: value });
  };
  const handleChangeData = (value) =>
    handleFormEdit({
      target: { name: "paper_date", value },
    });

  useImperativeHandle(_ref, () => ({
    getFormState: () => form,
  }));

  return (
    <div className="edit-form__container__content">
      <h2>Condition Report</h2>
      <div className="edit-form__container__content__rows">
        <div className="edit-form__container__content__rows__row edit-form__container__content__rows__row--responsive">
          <Select
            {...generateRequiredProps("condition", form.condition)}
            onChange={handleFormEdit}
          >
            {["Excellent", "Very Good"].map((condition) => (
              <Option value={condition} key={condition}>
                {condition}
              </Option>
            ))}
          </Select>
          <Calendar
            label="Paper Date"
            name="paper_date"
            value={form.paper_date}
            onChange={handleChangeData}
          />
          <TextInput
            name="max_wrist_size"
            label="Max Wrist Size (in.)"
            value={form.max_wrist_size}
            onChange={handleFormEdit}
          />
        </div>
        <div className="edit-form__container__content__rows__row edit-form__container__content__rows__row--responsive">
          <TextInput
            name="additional_accessories"
            label="Additional Accessories"
            readOnly
            value={form.additional_accessories}
            onChange={handleFormEdit}
          />
          <div className="checkbox-group">
            <Checkbox
              name="box"
              value={form.box}
              onChange={() => toggleCheckboxYesNo("box")}
              checked={form.box === "yes"}
            >
              Box
            </Checkbox>
            <Checkbox
              name="papers"
              value={form.papers}
              onChange={() => toggleCheckboxYesNo("papers")}
              checked={form.papers === "yes"}
            >
              Papers
            </Checkbox>
            <Checkbox
              name="manual"
              value={form.manual}
              onChange={() => toggleCheckboxYesNo("manual")}
              checked={form.manual === "yes"}
            >
              Manual
            </Checkbox>
          </div>
        </div>
        <TextInput
          name="qc_notes"
          label="QC Notes"
          value={form.qc_notes}
          onChange={handleFormEdit}
        />
        <TextInput
          {...generateRequiredProps("condition_notes", form.condition_notes)}
          value={form.condition_notes}
          onChange={handleFormEdit}
        />
      </div>
    </div>
  );
});
