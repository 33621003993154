<template>
  <div id="listing-form-video" class="card shadow-sm content-card">
    <div>
      <span v-if="customized" class="inline"><i class="fa fa-pencil fa-sm green-text p-b-0-75 m-r-sm"></i></span>
      <h3 class="mb-4 inline">
        Video Embed
      </h3>
    </div>
    <div v-if="customized">
      <span
        class="field-dropdown-icon field-dropdown-icon-card-title"
        id="listing-form-video-customized-dropdown"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i class="fa fa-ellipsis-v"></i>
      </span>
      <div class="dropdown-menu" aria-labelledby="listing-form-video-customized-dropdown">
        <a class="dropdown-item" @click.prevent="resetEdits()">
          Reset Edits
        </a>
      </div>
    </div>
    <b-row>
      <b-col md="12" class="mt-3">
        <ListingFormInput
          v-model="listing.video_id"
          ref="listing_form_video"
          label="Media"
          :validate="!isValidVideoId()"
          :warning="warningMsg()"
          @keyup="parseVideoLink"
         />
      </b-col>
    </b-row>
    <b-row v-if="warningMsg()">
      <b-col md="12" class="mt-3" >
        {{warningMsg()}}
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3" v-if="isValidVideoId()">
        Video link:
        <a target="_blank" :href="generateVideoUrl">
            {{generateVideoUrl}}
        </a>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-3">
        <iframe 
          loading="lazy"
          v-if="isValidVideoId()"
          width="100%"
          max-width="560"
          height="315"
          :src="generateEmbedVideoUrl"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '../stores/listingFormStore';
import formUtils from '../services/formUtils';
import ListingFormInput from './ListingFormInput';

export default {
  name: 'listing-form-video',
  components: {
    ListingFormInput,
  },
  data() {
    return {
      state: store.state,
    };
  },
  computed: {
    listing() { return store.state.listing },
    customized() { return formUtils.isCustomized('video_id', store.state) },
    generateVideoUrl() {
      if(!this.isValidVideoId()) return "";
      const { video_id } = this.listing;
      return video_id.length === 11
        ? `https://www.youtube.com/watch?v=${video_id}`
        : `https://studio.brightcove.com/products/videocloud/media/videos/${video_id}`;
    },
    generateEmbedVideoUrl() {
      const { video_id } = this.listing;
      return video_id.length === 11
        ? `https://www.youtube.com/embed/${video_id}`
        : `https://players.brightcove.net/6058082952001/RcVomwxnO_default/index.html?videoId=${video_id}`;
    },
  },
  methods: {
    resetEdits() { formUtils.resetFieldToDefaultValue('video_id', store.state) },
    warningMsg() {
      if (!this.isValidVideoId() && this.listing.video_id && this.listing.video_id.length > 0) {
        return "This isn't a valid video ID"
      }
    },
    isValidVideoId() {
      const videoIdLength = this.listing?.video_id?.length;
      return (videoIdLength === 11 || videoIdLength === 13);
    },
    parseVideoLink() {
      const youtubeRegex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
      const brightcoveRegex = /^.*brightcove.com.*\/([^/]+)\/?$/gm;
      const youtubeParse = youtubeRegex.exec(this.listing.video_id);
      const brightcoveParse = brightcoveRegex.exec(this.listing.video_id);
      if(youtubeParse) {
        this.listing.video_id = youtubeParse[3];
      } else if(brightcoveParse) {
        this.listing.video_id = brightcoveParse[1];
      }
    },
  },
  watch: {
    'listing.video_id'() {
      this.$emit('rebuildLayout');
    }
  },
}
</script>
