import React, { useState } from "react";
import { Button, RadioGroup, Radio, Uploader } from "@hodinkee/lume-ui";

const DropshipBulkUpdatePage = () => {
  const [message, setMessage] = useState(null);
  const [submittable, setSubmittable] = useState(false);
  const [file, setFile] = useState(null);
  const [deleteAbsentListings, setDeleteAbsentListings] = useState("no");

  const handleFileChange = (file) => {
    // TODO: Frontend form validations to parse CSV and check required columns?
    setMessage(null);
    const firstFile = file?.[0];
    if (!firstFile) {
      setSubmittable(false);
      return;
    }
    setFile(firstFile);
    setSubmittable(true);
  }

  const sendDropshipFileForBulkUpdate = async (e) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("deleteAbsentListings", deleteAbsentListings);

    try {
      setSubmittable(false);
      const response = await fetch(`/listings/dropship_bulk_updates`, {
        method: "POST",
        credentials: "same-origin",
        mode: "cors",
        redirect: "follow",
        headers: {
          "x-csrf-token": document.querySelector('meta[name="csrf-token"]').content,
        },
        body: formData,
      });
      const data = await response.json();
      setMessage(data?.message);
      if (response.status !== 200) {
        console.error("LP Server error occurred while processing file", data, response);
      }
    } catch (error) {
      console.error("Error occurred preventing the request from completing", error);
    }
  };

  const onDeleteAbsentListingsChange = (e) => {
    setDeleteAbsentListings(e.target.value);
  }

  return (
    <div id="dropship-bulk-update">
      <h1 className="expressive-headline-1">Dropship Bulk Updates</h1>
      <p className="functional-body-1">
        This page is for bulk updates to dropship listings, specifically for the Swiss Watch Expo (SWE) integration.
        When uploading the .csv file that SWE provides, the system will create or update listings based on the data
        provided.
        <br/>
        <em>Note: If a listing is already "listed", only price will be updated (if applicable).</em>
      </p>
      <p>
        Do you want to also additionally delete dropship listings that are not present in the .csv file? Any dropship 
        listings that are in incomplete, pending review, ... statuses will be <b>deleted</b>. Any listings which are
        listed or pending sale will be "delisted".
      </p>
      <RadioGroup className="flex gap-5" name="deleteAbsentListings">
        <Radio value="yes" onChange={onDeleteAbsentListingsChange} checked={deleteAbsentListings === "yes"}>
          Yes
        </Radio>
        <Radio value="no" onChange={onDeleteAbsentListingsChange} checked={deleteAbsentListings === "no"}>
          No
        </Radio>
      </RadioGroup>
      <Uploader
        btnText="Attach the CSV file"
        header="Drag and drop a CSV file, or click below to select a file."
        maxFileSize={500}
        maxUploads={1}
        uploadActive={false}
        enableSecondaryBtn={false}
        onFileUpload={handleFileChange}
        subheader="Drag and drop a file, or click below to select an image."
        uploadProgress={100}
        validFileTypes={["csv", "text/plain", "text/csv", "application/csv"]}
      />
      <Button disabled={!submittable} className="my-4" onClick={sendDropshipFileForBulkUpdate}>Process file</Button>
      <p>{message}</p>
    </div>
  );
};

export default DropshipBulkUpdatePage;
