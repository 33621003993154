import React, { useState, useRef } from "react";
import classNames from "classnames";
import { DotsThreeVertical } from "phosphor-react";
import specsService from "../../services/specsService";
import { detectClickingOutside } from "global-helpers/click-handler";

import "./reset_edits_button.scss";

export const ResetEditsButton = ({
  options,
  className,
  resetKey,
  onReset,
  text = "Reset Edits",
  ...rest
}) => {
  const dropDownRef = useRef();
  const [showActions, setShowActions] = useState(false);
  const resetEdits = async () => {
    const defaultValue = await specsService.getEvergreenDefaultValue(resetKey);
    onReset(resetKey, defaultValue);
    setShowActions(false);
  };

  detectClickingOutside(dropDownRef, () => setShowActions(false));

  return (
    <div ref={dropDownRef} className="reset-edits">
      <button
        className={classNames("more-actions", className)}
        {...rest}
        onClick={() => setShowActions(!showActions)}
      >
        <DotsThreeVertical />
      </button>
      {showActions && (
        <button className="action" onClick={resetEdits}>
          {text}
        </button>
      )}
    </div>
  );
};
