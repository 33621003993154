import { MODERN_STATES, dateToDisplay } from "global-helpers/listing";
import { formatToCurrency } from "global-helpers/currency";

export const diffDate = (changeDate, today) => {
  const endDate = new Date(changeDate);

  const days = parseInt((endDate - today) / (1000 * 60 * 60 * 24));
  if (days > 0) return `(${days} days ago)`;

  const hours = parseInt((Math.abs(endDate - today) / (1000 * 60 * 60)) % 24);
  if (hours > 0) return `(${hours} hours ago)`;

  const minutes = parseInt(
    (Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60)) % 60
  );

  if (minutes > 0) return `(${minutes} minutes ago)`;

  const seconds = parseInt(
    (Math.abs(endDate.getTime() - today.getTime()) / 1000) % 60
  );

  return `(${seconds} seconds ago)`;
};

export const displayValue = (field, value) => {
  if (field === "launch_date") return `"${dateToDisplay(value)}"`;
  if (field === "state") return MODERN_STATES[value];
  if (["price_cents", "cogs_cents"].includes(field)) return formatToCurrency(value, true);
  if (["dek", "wwli", "backstory", "about_brand"].includes(field) && typeof value === "string") {
    return value.replace(/<[^>]*>?/gm, " ");
  }

  return parseValue(value);
};

const nameMap = {
  price_cents: "Price",
  cogs_cents: "COGS"
}

export const parseName = (name) => {
  return nameMap[name] || name
    .replace(/_/g, " ")
    .replace(/\b\w/g, (s) => s.toUpperCase())
    .trim();
}

export const parseValue = (value) =>
  `"${value instanceof Array ? value.join(", ") : value}"`;
