const cloudinaryURL = ({ path }) =>
  `https://res.cloudinary.com/crownandcaliber/${path}`;

export const sortFn = ({ sort_order }, { sort_order: next }) =>
  sort_order - next;

export const mapFn = (image, index) => ({
  ...image,
  url: cloudinaryURL(image),
  sort_order: index,
});

export const reduceFn = (images, image, _index) => {
  const lastEl = images[images.length - 1];

  if (!lastEl || lastEl.length == 2) return [...images, [image]];
  lastEl.push(image);

  return images;
};
