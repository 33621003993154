import React from "react";
import { parseName, displayValue } from "./helper";
import classNames from "classnames";

const Changes = ({ changes }) => {
  const changeKeys = Object.keys(changes);

  return changeKeys.map((changeKey, key) => {
    const [oldValue, newValue] = changes[changeKey];
    const isStateChange = changeKey === "state";

    return (
      <span key={changeKey} className="log__items__item__info__change">
        {parseName(changeKey)} from
        <span
          className={classNames("mx-1", {
            [`${oldValue} p-1`]: isStateChange,
          })}
        >
          {displayValue(changeKey, oldValue)}
        </span>
        to
        <span
          className={classNames("ml-1", {
            [`${newValue} p-1`]: isStateChange,
          })}
        >
          {displayValue(changeKey, newValue)}
        </span>
        {changeKeys.length > 2 && key < changeKeys.length - 2 && ", "}
        {changeKeys.length > 1 && key === changeKeys.length - 2 && ", and "}
      </span>
    );
  });
};

export default Changes;
