import React from "react";
import classNames from "classnames";
import { Chip } from "components";
import { MODERN_STATES } from "global-helpers/listing";

const CoverComponent = ({ item, onClick, children }) => (
  <Chip
    onClick={onClick}
    className={classNames("flex items-center text-white font-bold", item)}
  >
    {MODERN_STATES[item]}
    {children}
  </Chip>
);
export default CoverComponent;
