import cloudinaryServiceFactory from '../services/cloudinaryServiceFactory';
import portalService from '../services/portalService';

export default function ImageUploadsStore() {
  const _state = {
    files: [],
    listings: [],
    prefix: '10-10',
    uploadStatus: '',
  };

  const _cloudinaryService = {};

  function applyData(data) {
    Object.assign(_cloudinaryService, cloudinaryServiceFactory(data.cloudinaryAuth));
    updateState({prefix: data.skuPrefix || _state.prefix});
  }

  function fetchListings() {
    updateStatus(0, 'Fetching Listings...');
    return portalService.fetchListings(_state.listings.map(l => l.sku).join());
  }

  function mountListings({data}) {
    const mountedListings = _state.listings.map(l => {
      const record = data.find(r => r.sku === l.sku);

      return !record ? l : Object.assign({
        link: `${window.location.origin}/listings/${record.lid}`,
      }, record);
    });

    const missingListings = mountedListings.filter(l => !l.lid)
      .map(l => l.sku)
      .join(', ');

    const soldListings = mountedListings.filter(l => ['sold', 'pending_sale'].includes(l.state))
      .map(l => l.sku)
      .join(', ');

    const mountSuccess = missingListings.length == 0 && soldListings.length == 0;

    let uploadStatus;

    if (missingListings.length !== 0)
      uploadStatus = `Failed: Could not find listing(s) ${missingListings}`;
    else if (soldListings.length !== 0)
      uploadStatus = `Failed: Could not mount sold listing(s) ${soldListings}`;
    else
      uploadStatus = 'Listings mounted';

    const progressPercent = mountSuccess ? 10 : 0;

    updateState({
      listings: mountedListings,
      progressPercent,
      uploadStatus,
    });

    return mountSuccess;
  }

  function selectFiles(files) {
    files = files?.[0] // First element of this array is either one File or or Array of Files.
    if (!Array.isArray(files)) {
      files = [files] // If it's not an array, make it an array, to use consistent functions like .map and .filter
    }
    const pendingFiles = files.filter(f => !f?.name?.startsWith(".")); // Hidden files (starting with ".")
    const invalidFiles = validateFiles(pendingFiles);

    if (invalidFiles.length) {
      updateStatus(0, `Failed: Some files invalid - ${invalidFiles.join()}`);
      return Promise.resolve(_state);
    }

    updateState({
      files: pendingFiles,
      listings: uniqueListings(pendingFiles),
    });

    return fetchListings()
      .then(mountListings)
      .then(uploadImages)
      .then(res => {
        res && updateListings(_state.listings)
        return _state;
      });
  }

  function getSku(filename) {
    // This pattern looks for "10-10" or "10-21" followed by a "-" then 3 letters for the brand code,
    // and then "-" followed by a 6 character alphanumeric string for the unique ID
    const skuPattern = /(10-10|10-21)-[A-Z]{3}-[A-Z0-9]{6}/i;
    const match = filename.match(skuPattern);
    if (match) {
        return match[0];
    }
    // If no match is found, return a fallback default using the first 10 characters of the filename.
    return `${_state.prefix}-${filename.substr(0,10)}`;
  }

  function resolveImages(res) {
    res.forEach(({data}) => {
      const sku = getSku(data.original_filename);
      const listing = _state.listings.find(l => l.sku === sku);

      Object.assign(listing, {
        newImages: [...(listing.newImages || []), data],
      });
    });
  }

  function uniqueListings(files) {
    const skus = files.map(f => getSku(f.name));
    return [...new Set(skus)]
      .map(sku => ({ sku }));
  }

  function uploadImages(valid) {
    if (!valid)
      return false;

    updateStatus(10, 'Uploading images...');
    return new Promise((resolve) => {
      _cloudinaryService.uploadFiles(_state.files)
        .then(
          (res) => {
            resolveImages(res);
            resolve(true);
          },
          e => updateStatus(0, `Failed: ${e.message}`) );
    });
  }

  function updateListing({ lid, images = [], newImages = [] }) {
    const parseShotNumberFromFilename = (filename) => {
      // Skip the first 10 characters (3 for the brand, 1 for hyphen, 6 for SKU).
      const shotNumber = filename.substring(10);
      // Convert shot number to integer, if it fails return 0.
      return parseInt(shotNumber, 10) || 0;
  };
    // Sort by original_filename only if it exists. If it doesn't have original_filename, put it at the end.
    const combinedImages = [...newImages, ...images]
      .filter(i => i)
      .sort((a, b) => {
          if(a.original_filename && b.original_filename) {
              return parseShotNumberFromFilename(a.original_filename) - parseShotNumberFromFilename(b.original_filename);
          }
          if(a.original_filename) return -1;
          if(b.original_filename) return 1;
          return 0;
      })
      .map((l, i) => Object.assign({}, l, { sort_order: i + 1 }));
    return portalService.listing({
      lid,
      images: combinedImages,
    });
}


  function updateListings(pending) {
    const [next, ...remaining] = pending;
    const current = _state.listings.length - pending.length + 1;
    const progress = 25 + (70 * current / _state.listings.length);

    if (next) {
      updateStatus(progress, `Updating listings... ${current}/${_state.listings.length}`);
      return updateListing(next)
        .then((response) => {
          if (response.status !== 200)
            _state.listings.find(l => l.sku === next.sku).errors = response.body.errors;
          updateListings(remaining);
        })
        .catch((e) => {
          _state.listings.find(l => l.sku === next.sku).errors = e;
          updateListings(remaining);
        });
    } else {
      updateStatus(100, "Update complete.");
      setTimeout(() => updateStatus(0, null), 2000);
      return Promise.resolve(_state);
    }
  }

  function updateStatus(progressPercent, uploadStatus) {
    updateState({ progressPercent, uploadStatus });
  }

  function updateState(obj) {
    Object.assign(_state, obj);
  }

  function validateFiles(files) {
    return files.filter(f => !f.type.includes("image/"))
      .map(f => f.name);
  }

  return {
    state: _state,
    cloudinaryService: _cloudinaryService,

    applyData,
    selectFiles,
  };
}
