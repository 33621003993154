import React from "react";
import classNames from "classnames";
import { ArrowsOutSimple, Info } from "phosphor-react";

const NoImages = () => (
  <div className="no-images">
    <Info size={16} />
    <div className="text">No watch images saved</div>
  </div>
);

export const WatchImageCard = ({
  path,
  merchandisingName,
  onExpand,
  className,
}) => {
  return (
    <div className="watch-image-container">
      {path ? (
        <>
          <img
            src={`https://res.cloudinary.com/crownandcaliber/${path}`}
            className={classNames("watch-image", className)}
            alt={merchandisingName}
          />
          <div className="watch-image-container__icon-container">
            <ArrowsOutSimple
              size={32}
              onClick={onExpand}
              className="fullscreen-icon"
            />
          </div>
        </>
      ) : (
        <NoImages />
      )}
    </div>
  );
};
