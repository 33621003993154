import React, { useState, useEffect } from "react";
import { PencilSimple } from "phosphor-react";
import { diffDate } from "./helper";

import Changes from "./changes";

import "./log";

const Log = ({ versions }) => {
  const [expand, setExpand] = useState(false);
  const [today, setToday] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => setToday(new Date()), 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="edit-form__container__content">
      <h2>Audit Log</h2>
      <div className="log">
        <div className="log__items">
          {(expand ? versions : [...versions].splice(0, 3)).map(
            ({ change_time, changes, whodunnit }, index) => (
              <p key={index} className="log__items__item">
                <span className="log__items__item__icon">
                  <PencilSimple size={12} />
                </span>
                <span className="log__items__item__info">
                  <span className="mr-1">{whodunnit || "System"} changed the</span>
                  <Changes changes={changes} />
                  <span className="mr-1">on</span>
                  <span className="mr-1">
                    {new Date(change_time).toLocaleString("en-US")}
                  </span>
                  <span>{diffDate(change_time, today)}</span>
                </span>
              </p>
            )
          )}
        </div>
        {versions.length > 3 && (
          <button className="log__show" onClick={() => setExpand(!expand)}>
            {expand ? "show less" : "show all entries"}
          </button>
        )}
      </div>
    </div>
  );
};

export default Log;
