import React from "react";
import classNames from "classnames";
import { CaretDown, CaretUp } from "phosphor-react";
import { Chip } from "components";
import "./label_cover";

const MultiBox = ({
  coverComponent,
  readOnly,
  expanded,
  setExpanded,
  label,
  coverVariant,
  hideCaret,
}) => (
  <Chip
    onClick={() => !readOnly && setExpanded(!expanded)}
    className={classNames("label-cover", coverVariant)}
  >
    <div className="label-cover__left">
      <div>{coverComponent || label}</div>
    </div>
    {!hideCaret && (
      <div className="label-cover__right">
        {expanded ? (
          <CaretUp size={16} weight="bold" />
        ) : (
          <CaretDown size={16} weight="bold" />
        )}
      </div>
    )}
  </Chip>
);

export default MultiBox;
