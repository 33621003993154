/**
 * Given integer cent amount, return string represatation of dollar amount.
 * @param {Number} price in cents. [eg. 100000]
 * @returns price in dollars as string. [eg. $1000]
 */
export const formatToCurrency = (price, withCents = false) => {
  if (withCents) {
    return `$${(1 * formatToNumber(price / 100)).toFixed(2).replace(/\.0+$/, "").toLocaleString("en-US")}`;
  }

  return `$${Math.round(formatToNumber(price / 100)).toLocaleString("en-US")}`;
};

/**
 * Given string dollar amount, return integer cent amount.
 * @param {String} price in dollars. [eg. $1000]
 * @returns integer representation of price in cents. [eg. 100000]
 */
export const formatToNumber = (price) => {
  if (!price) return 0;

  return Number(price.toString().replace(/[^0-9.]+/g, ""));
};
