import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  useRef,
} from "react";
import classNames from "classnames";
import { CornersOut, X } from "phosphor-react";


export const ImageModal = forwardRef((props, _ref) => {
  const { path, alt } = props;
  const [visible, setVisible] = useState(false);
  const imageModalRef = useRef(null);
  const iconProps = { size: 24, color: "white" };
  useEffect(() => {
    const closeOnEsc = (e) => {
      e.key === "Escape" && close();
    };
    window.addEventListener("keydown", closeOnEsc);
    return () => {
      window.removeEventListener("keydown", closeOnEsc);
    };
  }, []);
  const close = () => {
    setVisible(false);
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }
  const toggleFullscreen = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      await imageModalRef.current.requestFullscreen();
    }
  };
  useImperativeHandle(_ref, () => ({
    setVisible: (v) => setVisible(v),
  }));
  return (
    <div
      className={classNames("image-modal", { hidden: !visible })}
      ref={imageModalRef}
      onClick={close}
    >
      <div className="image-modal-controls bg-black">
        <CornersOut {...iconProps} onClick={toggleFullscreen} />
        <X {...iconProps} onClick={close} />
      </div>
      <div className="image-container">
        <img
          src={`https://res.cloudinary.com/crownandcaliber/${path}`}
          className="watch-fullscreen-image"
          alt={alt}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
});
