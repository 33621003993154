<template>
  <div id="watch-story" class="card shadow-sm content-card">
    <h3 class="mb-4">
      Watch story
    </h3>
    <b-row>
      <b-col md="12">
        <ListingFormEditor
          v-model="listing.dek"
          label="Dek"
          v-on="$listeners"
          :required="isRequired('dek')"
          :customized="isCustomized('dek')"
          @resetEdits="resetEdits('dek')"
          @textInput="flagAsCustomized('dek')"/>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col md="12">
        <ListingFormEditor
          v-model="listing.wwli"
          label="Why We Love It"
          v-on="$listeners"
          :required="isRequired('wwli')"
          :customized="isCustomized('wwli')"
          @resetEdits="resetEdits('wwli')"
          @textInput="flagAsCustomized('wwli')" />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col md="12">
        <ListingFormEditor
          v-model="listing.backstory"
          label="The Backstory"
          v-on="$listeners"
          :disabled="hasSpec"
          :required="isRequired('backstory')" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '../stores/listingFormStore'
import ListingFormEditor from './ListingFormEditor.vue';
import formUtils from '../services/formUtils';

export default {
  name: 'watch-story',
  components: {
    ListingFormEditor,
  },
  data() {
    return {
      state: store.state,
    };
  },
  computed: {
    listing() { return store.state.listing },
    hasSpec() { return formUtils.hasSpec(store.state) },
  },
  methods: {
    isRequired(field) { return formUtils.isRequired(field, store.state) },
    isCustomized(field) { return formUtils.isCustomized(field, store.state) },
    resetEdits(field) { formUtils.resetFieldToDefaultValue(field, store.state) },
    flagAsCustomized(field) { formUtils.flagFieldAsCustomized(field, store.state) }
  }
}

</script>
