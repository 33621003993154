<template>
  <b-form-group :label="labelName">
    <i v-if="disabled" class="fa fa-lock field-icon locked-field-icon" />
    <b-form-input :id="inputId"
      v-model="localValue"
      :disabled="disabled"
      :reset-after="true"
      :state="validity"
      @keypress="validateNumber"
      @keyup.native="$emit('keyup', localValue)"
      />
  </b-form-group>
</template>

<script>
import priceUtils from '../services/priceUtils';

export default {
  name: 'price',
  props: {
    value: {},
    id: {},
    label: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validity: {
      type: String,
    },
  },
  created() {
    if (!Number((this.localValue || '').toString().replace(/\D/g,'')))
      this.localValue = '';
  },
  data() { return {localValue: priceUtils.formatToCurrency(this.value)} },
  computed: {
    inputId,
    labelName,
    validCheck,
  },
  methods: {
    validateNumber: (event) => {
      const charCode = String.fromCharCode(event.keyCode);
      if (!/^[0-9.,$]*$/.test(charCode)) {
        event.preventDefault();
      }
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (!Number((newVal || '').toString().replace(/\D/g,'')))
        this.localValue = '';
      else
        this.localValue = priceUtils.formatToCurrency(newVal);
    },
    localValue(newVal, oldVal) {
      this.$emit('input', this.localValue);
    },
  },
}

function inputId() {
  if (this.id){
    return this.id;
  }
  return `listing_${this.label}`.toLowerCase().replace(/\s/g, '_');
}

function labelName() {
  const asterisk = this.required ? ' *' : '';
  return this.label + asterisk;
}

function validCheck() {
  if (this.validate && !this.valid)
    return 'invalid'
}

</script>
