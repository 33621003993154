import React from "react";
import { ArrowsDownUp } from "phosphor-react";
import { MultiSelect } from "components";

const SortByFilter = ({ value, onChange }) => (
  <MultiSelect
    label="Sort By"
    coverComponent={<ArrowsDownUp size={16} weight="bold" />}
    name="order"
    value={value}
    onChange={onChange}
    singleValue
    labelCover
    hideSearchInput
    hideCaret
    items={{
      "updated_at desc": "Recently Updated",
      "created_at desc": "Newest Listing",
      "created_at asc": "Oldest Listing",
      "brand asc": "Brand (A to Z)",
      "brand desc": "Brand (Z to A)",
      "launch_date asc": "Launch Date (Ascending)",
      "launch_date desc": "Launch Date (Descending)",
    }}
  />
);

export default SortByFilter;
