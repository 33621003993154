import axios from 'axios';

const uploadPreset = 'd98lcgt7';

export default cloudinaryServiceFactory;

function cloudinaryServiceFactory(auth) {
  const _auth = auth;

  const acceptedFileTypes = [ 'image/jpg', 'image/jpeg', 'image/png', 'image/gif' ];
  const acceptedFileTypesMsg = 'Image format is invalid. Allowed types: .jpg, .png, .gif';


  function cloudinaryPost(endpoint, payload, handleSuccess, handleError) {
    const baseURI = 'https://api.cloudinary.com/v1_1';
    const fullURL = `${baseURI}/${_auth.cloud_name}/${endpoint}`;
    const req = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    return axios.post(fullURL, payload, req);
  }

  function cropImage(img, data) {
    const cropOpts = [
      'c_crop',
      `h_${data.height}`,
      `w_${data.width}`,
      `x_${data.x}`,
      `y_${data.y}`,
    ].join();

    return `https://res.cloudinary.com/${_auth.cloud_name}/${cropOpts}/${img.path}`;
  }

  function mapResponse(res) {
    const path = `v${res.version}/${res.public_id}`;
    return Object.assign({}, res, {
      id: null,
      cropped_path: null,
      path: path,
      url: `https://res.cloudinary.com/${_auth.cloud_name}/${path}`,
      sort_order: null,
    }, res);
  }

  function uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', _auth.api_key);
    formData.append('folder', 'listings_portal');

    return cloudinaryPost('upload', formData)
  }

  function uploadFiles(files) {
    const errors = Array.from(files)
      .map(validateFileType)
      .filter(e => e)

    if (errors.length) {
      return Promise.reject( new Error(acceptedFileTypesMsg))
    }

    const promises = Array.from(files).map(uploadFile);
    return Promise.all(promises)
  }

  function url(path) {
    return `https://res.cloudinary.com/${_auth.cloud_name}/${path}`;
  }

  function validateFileType(file) {
    return !acceptedFileTypes.includes(file.type) && 'invalid file type';
  }

  return {
    cropImage,
    mapResponse,
    uploadFiles,
    url,
  };
}
