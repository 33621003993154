<template>
  <b-form-group :label="labelName">
    <i v-if="disabled" class="fa fa-lock field-icon locked-field-icon" />
    <i v-if="required && !localValue.length"
      class="fa fa-exclamation-triangle orange-font tt-error field-icon"
      :id="`tooltip-target-${inputId}`"
      v-b-tooltip.html
      title="Can't be blank" />
    <multiselect :id="inputId"
      v-model="localValue"
      :multiple="true"
      :options="displayOptions"
      :disabled="disabled"
      :reset-after="false"
      :taggable="taggable"
      :class="inputClasses"
      @tag="addOption"
      @change="$emit('change', localValue)"
    >
      <template slot="option" slot-scope="props">
        <span>{{props.option.label ? props.option.label : props.option}}</span>
      </template>
    </multiselect>
  </b-form-group>

</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'listing-form-multiselect',
  props: {
    value: {},
    id: {},
    label: {},
    options: {},
    taggable: {},
    disabled: {},
    required: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Multiselect,
  },
  data() { return {localValue: this.value} },
  computed: {
    displayOptions() {
      const missing = this.missingValues(this.value, this.options);
      return [...(this.options || []).filter(x => x), ...missing];
    },
    inputId() {
      if (this.id)
        return this.id;
      return `listing_${this.label}`.toLowerCase().replace(/\s/g, '_');
    },
    inputClasses() {
      let desiredClass = '';
      if (this.required && !this.localValue.length)
        desiredClass += 'field-warning-border'
      if (!this.$slots.default)
        desiredClass += ' no-slot-field'
      return desiredClass;
    },
    labelName() {
      const asterisk = this.required ? ' *' : '';
      return this.label + asterisk;
    },
  },
  methods: {
    addOption (newOption) {
      this.displayOptions.push(newOption);
      this.localValue.push(newOption);
    },
    missingValues(arr1=[], arr2=[]) {
      return  arr1.filter(x => !arr2.includes(x));
    }
  },
  watch: {
    value(newVal, _oldVal) {
      this.localValue = newVal;
    },
    localValue(newVal, oldVal) {
      this.$emit('input', this.localValue);
    },
  },
}

</script>
