import React from "react";
import { Button, FileUploadButton } from "components";

const PhotoGalleryUpload = ({ upload }) => (
  <FileUploadButton
    upload={upload}
    label="Drag & Drop files here"
    bottomContent={
      <div className="text-center">
        <p>or</p>
        <Button variant="primary" onClick={() => {}}>
          Browse Files
        </Button>
      </div>
    }
  />
);

export default PhotoGalleryUpload;
