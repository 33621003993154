/**
 * Let N be length of initial. If initial is null, undefined, or empty list, return new list of 4 empty strings.
 * Otherwise, return array of (N strings) + (4 - N empty strings).
 * @param {*} initial
 * @returns array of length 4
 */
export const getInitialRelatedArticles = (initial) => {
  if (initial?.length === 4)
    return initial;
  (initial ??= []).push(...Array(4 - initial.length).fill(""));
  return initial;
};

export const parseVideoLink = (link) => {
  const youtubeParse = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm.exec(link);
  const brightcoveParse = /^.*brightcove.com.*\/([^/]+)\/?$/gm.exec(link);
  if (youtubeParse) {
    return youtubeParse[3];
  } else if (brightcoveParse) {
    return brightcoveParse[1];
  }
  return null;
}

export const validVideoId = (id) => {
  return id && (id.length === 11 || id.length === 13);
}

export const generateVideoUrl = (id) => {
  if (!validVideoId(id)) return null;
  return id.length === 11
    ? `https://www.youtube.com/watch?v=${id}`
    : `https://studio.brightcove.com/products/videocloud/media/videos/${id}`;
}

export const generateEmbedVideoUrl = (id) => {
  if (!validVideoId(id)) return null;
  return id.length === 11
    ? `https://www.youtube.com/embed/${id}`
    : `https://players.brightcove.net/6058082952001/RcVomwxnO_default/index.html?videoId=${id}`;
}
