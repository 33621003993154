import priceUtils from './priceUtils';
import he from "he";

const genderMap = [
  { record: 'men', form: "men's" },
  { record: 'women', form: "women's" },
];

const keysWithZeros = ['water_resistance'];

const keys = [
  '_model_name',
  'aftermarket_additions',
  'aftermarket_replacements',
  'age', // from ccspec
  'alternate_reference_numbers',
  'analog_digital',
  'approximate_age',
  'band_materials',
  'base_caliber',
  'bezel_features',
  'bezel_insert_colors',
  'bezel_insert_materials',
  'bezel_materials',
  'body',
  'box',
  'bracelet_name',
  'brand_name',
  'calendar_type',
  'case_back',
  'case_materials',
  'case_shape',
  'case_size',
  'case_thickness',
  'ccspec_number',
  'cogs_cents',
  'complications',
  'condition_notes',
  'condition',
  'crystal_material',
  'current_price_cents',
  'customized_fields',
  'dial_color',
  'diamond_dial',
  'dropship_cost_cents',
  'edition_type',
  'external_sku',
  'gender',
  'jewels',
  'limited_quantity',
  'lug_width',
  'manual',
  'manuf_caliber',
  'manuf_diamonds',
  'max_wrist_size',
  'merchandising_model_number',
  'merchandising_name',
  'model_number',
  'movement_type',
  'nickname',
  'papers',
  'power_reserve',
  'retail_price_cents',
  'secondary_description',
  'serial_number',
  'sku',
  'state',
  'style',
  'water_resistance',
  'years_stopped',
  'year',
  'years_released',
  'shop',
  'dek',
  'wwli',
  'backstory',
  'about_brand',
  'paper_date',
  'additional_accessories',
  'qc_notes',
  'related_articles',
  'hodinkee_ineligible',
  'video_id',
];

const keyMap = {
  body: 'custom_description',
  cogs_cents: 'net_price_precise',
  dropship_cost_cents: 'dropship_cost_precise',
  current_price_cents: 'list_price_precise',
  retail_price_cents: 'retail_price',
  state: 'status',
};

const fromModelMappers = {
  band_materials: defaultTo([]),
  bezel_materials: defaultTo([]),
  box: yesNoToBool,
  case_materials: defaultTo([]),
  case_size: parseFloat,
  case_thickness: toString,
  cogs_cents: priceUtils.priceFromCents,
  current_price_cents: priceUtils.priceFromCents,
  dial_color: split,
  dropship_cost_cents: priceUtils.priceFromCents,
  gender: mapGender,
  jewels: toString,
  limited_quantity: toLimitedQuantity,
  lug_width: toString,
  manual: yesNoToBool,
  papers: yesNoToBool,
  power_reserve: toString,
  related_articles: defaultTo(['', '', '', '']),
  retail_price_cents: priceUtils.priceFromCents,
  style: defaultTo([]),
  water_resistance: toString,
  years_stopped: toString,
  year: toString,
  years_released: toString,
};

function toString(v) {
  return (v || '').toString();
}

export default {
  fromModel,
  toModel,
};

function boolToYesNo(bool) {
  return bool && 'yes' || (bool === false && 'no') || null;
}

function capitalize(str) {
  if (typeof (str) === 'string')
    return str.charAt(0).toUpperCase().concat(str.slice(1));

  return str;
}

function fromModel(attrs = {}) {
  return keys.reduce((obj, k) => {
    const mappedKey = keyMap[k] || k;
    return Object.assign(obj, { [mappedKey]: vueValue(k, attrs[k]) });
  }, {
    dropship_cost: Math.round(priceUtils.priceFromCents(attrs.dropship_cost_cents)),
    list_price: Math.round(priceUtils.priceFromCents(attrs.current_price_cents)),
    net_price: Math.round(priceUtils.priceFromCents(attrs.cogs_cents)),
  });
}

function toPrecision(precision) {
  return v => (v ? parseFloat(v).toFixed(precision).toString() : '');
}

function defaultTo(defaultValue) {
  return v => v || defaultValue;
}

function pricing(state) {
  if (state.lastPriceAdjusted === 'net')
    return { cogs: state.listing.net_price_precise };
  if (state.lastPriceAdjusted === 'dropship')
    return { dropship_cost: state.listing.dropship_cost_precise };

  return state.newListing
    ? { initial_list_price: state.listing.list_price_precise }
    : { current_price: state.listing.list_price_precise };
}

function split(val) {
  return val ? val.split(',') : [];
}

function toLimitedQuantity(val) {
  if (!val) return '';

  return toPrecision(0)(val);
}

function toModel(state) {
  return Object.assign({}, state.listing, {
    _model_name: state.modelName,
    body: state.listing.custom_description,
    box: boolToYesNo(state.listing.box),
    brand_name: state.brandName,
    case_size: toPrecision(2)(state.listing.case_size),
    ccspec_number: state.listing.ccspec_number,
    dial_color: (state.listing.dial_color || [])
      .map(x => x.trim()).join(','),
    gender: mapGender(state.listing.gender, true),
    images: state.images,
    lifestyle_images: state.lifestyleImages,
    lid: state.lid,
    manual: boolToYesNo(state.listing.manual),
    // manuf_diamonds: boolToYesNo(state.listing.manuf_diamonds),
    model_number: state.modelNumber,
    papers: boolToYesNo(state.listing.papers),
    dek: unescapeHTML(state.listing.dek),
    wwli: unescapeHTML(state.listing.wwli),
    backstory: unescapeHTML(state.listing.backstory),
    about_brand: unescapeHTML(state.listing.about_brand),
  }, pricing(state));
}

function mapGender(input, toRecord) {
  const genderKeys = ['record', 'form'];
  if (toRecord) genderKeys.reverse();
  const [key, valKey] = genderKeys;
  const match = genderMap.find(x => x[key] === (input || '').toLowerCase());
  const output = match ? match[valKey] : input;
  return toRecord ? output : capitalize(output);
}


function vueValue(key, val = null) {
  if(val === 0 && keysWithZeros.includes(key))
    return '0';
  const f = fromModelMappers[key];
  return f ? f(val) : val;
}

function yesNoToBool(val) {
  const map = { yes: true, no: false };
  return map[val] === undefined ? null : map[val];
}

function unescapeHTML(val) {
  if (!val) {
    return null;
  }

  return he.decode(val).replace(/\s+<\//gm, '</').trim();
}
