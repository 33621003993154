<template>
  <div class="imageCropper">
    <VueCropper
      ref="cropper"
      :zoomOnTouch="false"
      :zoomOnWheel="false"
      :src="image.url"
    />
    <b-button-group size="sm" class="padding">
      <b-button variant="primary" @click="crop">Crop</b-button>
      <b-button @click="onCancel">Cancel</b-button>
    </b-button-group>
  </div>
</template>

<script>
import VueCropper from './vueCropper'

export default {
  name: 'listing-image-cropper',
  props: ['image', 'onCrop', 'onCancel'],
  components: {
    VueCropper,
  },
  methods: {
    crop() {
      this.onCrop(this.$refs.cropper.getData(true));
    },
  },
}
</script>

<style>
.imageCropper .btn-primary{
  border-width: 1px;
}
</style>
