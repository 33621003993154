import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { CaretLeft, X } from "phosphor-react";
import {
  Button,
  ConditionReportForm,
  EvergreenContentForm,
  FeaturesAndSpecsForm,
  LoadingModal,
  WatchDetailsForm,
  RelatedContentForm,
  PhotoGallery,
  Chip,
} from "components";
import WatchLoader from "@images/watch_loader.svg";
import specsService from "../services/specsService";
import { snakeToTitleCase, validation, protectedStates } from "../util";
import ValidationMsg from "../pages/modern/validation_msg";
import "./modern/styles";

const Header = ({
  listing,
  merchandising_name,
  saveListing,
  ccSpecNumber,
  removeSpec,
}) => {
  return (
    <div className="header flex-1 mb-3">
      <a className="edit__header__a" href={`listings/${listing.lid}`}>
        <CaretLeft size={16} weight="bold" className="mr-[2px]" />
        Back to listing details
      </a>

      <div className="flex justify-between">
        <div>
          <h1 className="page-title">{merchandising_name}</h1>
          <div className="state-chip-container">
            <Chip
              className={classNames("state-chip", listing.state)}
              onClick={(e) => e.preventDefault()}
            >
              <div>{listing.state.replace(/_/g, " ")}</div>
            </Chip>
          </div>
        </div>

        <div className="pre-owned-actions">
          <div className="action-buttons mb-3 text-right">
            <Button href={`listings/${listing.lid}`}>View Listing</Button>
            <Button variant="primary" onClick={saveListing}>
              Save
            </Button>
          </div>
          {ccSpecNumber && (
            <div className="spec-chip-container">
              <Chip className="spec-chip" onClick={(e) => e.preventDefault()}>
                <div className="spec-num">Spec: {ccSpecNumber}</div>
                {!protectedStates.includes(listing.state) && (
                  <div className="close-wrap">
                    <X size={11} onClick={removeSpec} />
                  </div>
                )}
              </Chip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const EditPage = ({
  listing,
  merchandising_name,
  images,
  lifestyle_images,
  specs_data,
  specs_config,
  user,
  shops,
  global_values,
  required_fields,
}) => {
  // State
  const [shop, setShop] = useState(listing.shop);
  const [validationError, setValidationError] = useState([]);
  const [customizedFields, setCustomizedFields] = useState(
    listing.customized_fields
  );
  const [ccSpecNumber, setCcSpecNumber] = useState(listing.ccspec_number);
  const [featuredImage, setFeaturedImage] = useState(images[0]);
  const [specHodinkeeIneligible, setSpecHodinkeeIneligible] = useState(false);
  // Refs
  const loadingModalRef = useRef(null);
  const featuresAndSpecsRef = useRef(null);
  const watchDetailsRef = useRef(null);
  const conditionReportRef = useRef(null);
  const evergreenContentRef = useRef(null);
  const relatedContentRef = useRef(null);
  const productImagesRef = useRef(null);
  // Misc. Vars
  const hasSpec = !!ccSpecNumber && /CCS-...-.*/.test(ccSpecNumber);
  const hasEditPermissions = ["editor", "manager", "admin"].includes(user.role);
  const specEditable = !listing.lid || (hasEditPermissions && !hasSpec);
  const readyOrListed = ["listed", "ready_to_list"].includes(listing.state);

  const isRequired = (name) => {
    return (
      required_fields.common.includes(name) ||
      required_fields[shop]?.includes(name)
    );
  };
  const saveListing = async () => {
    try {
      loadingModalRef?.current?.setLoading(true);
      setValidationError([]);
      const requestBody = {
        ...featuresAndSpecsRef.current.getFormState(),
        ...watchDetailsRef.current.getFormState(),
        ...conditionReportRef.current.getFormState(),
        ...evergreenContentRef.current.getFormState(),
        ...relatedContentRef.current.getFormState(),
        ...productImagesRef.current.getFormState(),
        lid: listing.lid,
        ccspec_number: ccSpecNumber,
        customized_fields: customizedFields,
      };

      const errors = readyOrListed
        ? validation({ ...requestBody, state: listing.state }, isRequired)
        : [];

      if (errors.length > 0) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return setValidationError(errors);
      }
      await putListing(requestBody);
    } catch (e) {
      console.error("Error occured while updating listing.");
      console.error(e);
    } finally {
      loadingModalRef?.current?.setLoading(false);
    }
  };
  // Run once, when the component is first mounted.
  useEffect(function initialize() {
    (async () => {
      specsService.init({ ...specs_config, ccSpecNumber });
      const ineligibility = await specsService.getSpecHodinkeeIneligibility();
      setSpecHodinkeeIneligible(ineligibility);
    })();
  }, []);

  const generateRequiredProps = (name, value, alwaysRequired = false) => {
    const required = alwaysRequired || isRequired(name);
    const empty = !value || (Array.isArray(value) && value.length === 0);
    const label = snakeToTitleCase(name);
    const helperText = () => {
      if (!empty || !isRequired(name)) return;

      return readyOrListed
        ? "Please delist SKU to remove this field."
        : "This field is required.";
    };

    return {
      required,
      name,
      value,
      label: required ? `${label} (Required)` : label,
      variant: empty && required ? "error" : null,
      helperText: helperText(),
    };
  };
  return (
    <div className="edit">
      <LoadingModal ref={loadingModalRef}>
        <div className="card">
          <img src={WatchLoader} />
        </div>
      </LoadingModal>
      <div id="edit-form" noValidate>
        <Header
          listing={listing}
          ccSpecNumber={ccSpecNumber}
          removeSpec={() => setCcSpecNumber(null)}
          merchandising_name={merchandising_name}
          saveListing={saveListing}
        />
        <ValidationMsg
          title="Validation Error:"
          messages={validationError}
          variant="error"
          className="rounded-lg"
        />
        <div className="edit-form">
          <div className="edit-form__container edit-form__container--main">
            <WatchDetailsForm
              ref={watchDetailsRef}
              listing={listing}
              setShop={setShop}
              shops={shops}
              specEditable={specEditable}
              specsData={specs_data}
              generateRequiredProps={generateRequiredProps}
              specHodinkeeIneligible={specHodinkeeIneligible}
              readyOrListed={readyOrListed}
            />
            <ConditionReportForm
              ref={conditionReportRef}
              listing={listing}
              generateRequiredProps={generateRequiredProps}
            />
            <EvergreenContentForm
              ref={evergreenContentRef}
              listing={listing}
              hasSpec={hasSpec}
              isRequired={isRequired}
              customizedFields={customizedFields}
              ccSpecNumber={ccSpecNumber}
              setCustomizedFields={setCustomizedFields}
            />
            <RelatedContentForm
              ref={relatedContentRef}
              listing={listing}
              customizedFields={customizedFields}
              ccSpecNumber={ccSpecNumber}
              setCustomizedFields={setCustomizedFields}
            />
            <PhotoGallery
              ref={productImagesRef}
              images={images}
              lifestyleImages={lifestyle_images}
              state={listing.state}
              merchandisingName={merchandising_name}
              customizedFields={customizedFields}
              ccSpecNumber={ccSpecNumber}
              setCustomizedFields={setCustomizedFields}
              setFeaturedImage={setFeaturedImage}
            />
            <Button variant="primary" onClick={saveListing}>
              Save
            </Button>
          </div>
          <div className="edit-form__container edit-form__container--side">
            <FeaturesAndSpecsForm
              ref={featuresAndSpecsRef}
              hasSpec={hasSpec}
              listing={listing}
              featuredImage={featuredImage}
              merchandisingName={merchandising_name}
              globalValues={global_values}
              generateRequiredProps={generateRequiredProps}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const putListing = async (requestBody) => {
  const response = await fetch(`/listings/${requestBody.lid}`, {
    method: "PUT",
    credentials: "same-origin",
    mode: "cors",
    redirect: "follow",
    headers: {
      "Content-Type": "text/plain;charset=UTF-8",
      "x-csrf-token": document.querySelector('meta[name="csrf-token"]').content,
    },
    body: JSON.stringify({
      listing: requestBody,
    }),
  });
  if (response.status === 200) {
    window.location.href = `/listings/${requestBody.lid}`;
  } else {
    console.error("Error occurred while updating listing.", response);
  }
};

export default EditPage;
