import React, { useState } from "react";
import DOMPurify from "dompurify";
import classNames from "classnames";
import { CopySimple, ArrowSquareUpRight } from "phosphor-react";
import { copyToClipBoard } from "./helpers";
import "./styles";

export const ActionInfo = ({
  label,
  children,
  href,
  className,
  noAction,
  isShowPage,
  renderHTML,
}) => {
  const [showFeedback, setShowFeedback] = useState(false);

  const handleShowFeedback = () => {
    setShowFeedback(true);
    setTimeout(() => setShowFeedback(false), 2000);
  };
  const handleOnClick = () =>
    !showFeedback && copyToClipBoard(children, handleShowFeedback);

  return (
    <div
      className={classNames("action-info", className, {
        "action-info--grid": !isShowPage,
        "action-info--show": isShowPage,
      })}
    >
      <div className="action-info__info">
        <div className="action-info__info__label">{label}</div>
        {renderHTML ?
          <div
            className="action-info__info__value"
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(children)}}
          />
          :
          <div className="action-info__info__value">{children}</div>
        }
      </div>
      {!noAction && (
        <div className="action-info__action">
          {href ? (
            <a href={href} rel="nofollow" target="_blank">
              <ArrowSquareUpRight size={16} />
            </a>
          ) : (
            <CopySimple onClick={handleOnClick} size={16} />
          )}
          {showFeedback && (
            <div className="action-info__action__feedback">Copied!</div>
          )}
        </div>
      )}
    </div>
  );
};
