import React from "react";
import DesktopSidebar from "./desktop_sidebar";
import MobileSidebar from "./mobile_sidebar";

export const Index = (props) => (
  <div id="sidebar">
    <MobileSidebar id="mobile-sidebar" {...props} />
    <DesktopSidebar id="desktop-sidebar" {...props} />
  </div>
);

export default Index;
