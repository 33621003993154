import React, { forwardRef, useImperativeHandle, useReducer } from "react";
import { TextInput, Select, Option } from "@hodinkee/lume-ui";
import { MultiSelect, ItemOptions } from "components";
import InputWrapper from "./input_wrapper";

const FeatureSpec = forwardRef(({ listing, globalValues, isShowPage }, ref) => {
  const [form, setForm] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      style: listing.style || [],
      band_materials: listing.band_materials || [],
      lug_width: listing.lug_width || "",
      bezel_materials: listing.bezel_materials || "",
      bezel_insert_materials: listing.bezel_insert_materials || "",
      bezel_insert_colors: listing.bezel_insert_colors || "",
      case_size: listing.case_size || "",
      crystal_material: listing.crystal_material || "",
      case_thickness: listing.case_thickness || "",
      case_materials: listing.case_materials || "",
      case_back: listing.case_back || "",
      dial_colors: listing.dial_colors || "",
      lume: listing.lume ? 1 : 0,
      display_type: listing.display_type || "",
      edition_type: listing.edition_type || "",
      limited_quantity: listing.limited_quantity || "",
      movement_type: listing.movement_type || "",
      manuf_caliber: listing.manuf_caliber || "",
      base_caliber: listing.base_caliber || "",
      power_reserve: listing.power_reserve || "",
      jewels: listing.jewels || "",
      complications: listing.complications || "",
      calendar_type: listing.calendar_type || "",
      complications: listing.complications || "",
      bezel_features: listing.bezel_features || "",
      water_resistance:
        listing.water_resistance === undefined ? "" : listing.water_resistance,
      case_shape: listing.case_shape || "",
    }
  );
  const handleMultiSelectEdit = (name, value) => {
    setForm({ [name]: value });
  };
  const handleFormEdit = ({ target: { name, value, type } }) => {
    if (type === "number" && value < 0) {
      return onChangeMultiSelect(name, "");
    }

    onChangeMultiSelect(name, value);
  };

  const onChangeMultiSelect = (name, value) => setForm({ [name]: value });

  useImperativeHandle(ref, () => ({
    getFormState: () => form,
  }));

  return (
    <div className="edit-form__container__content">
      <h2>Features & Specs</h2>
      <div className="edit-form__container__content__rows">
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Style*"
            value={form.style}
            isShowPage={isShowPage}
            required
          >
            <MultiSelect
              name="style"
              items={globalValues.style}
              onChange={handleMultiSelectEdit}
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Band Materials*"
            value={form.band_materials}
            isShowPage={isShowPage}
          >
            <MultiSelect
              name="band_materials"
              items={globalValues.band_materials}
              onChange={handleMultiSelectEdit}
              required
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Lug Width"
            value={form.lug_width}
            isShowPage={isShowPage}
          >
            <TextInput
              name="lug_width"
              onChange={handleFormEdit}
              type="number"
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label={`Bezel Material${listing.smartwatch ? "" : "*"}`}
            value={form.bezel_materials}
            isShowPage={isShowPage}
          >
            <MultiSelect
              name="bezel_materials"
              items={globalValues.bezel_materials}
              onChange={handleMultiSelectEdit}
              required={listing.smartwatch}
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Bezel Insert Materials"
            value={form.bezel_insert_materials}
            isShowPage={isShowPage}
          >
            <MultiSelect
              name="bezel_insert_materials"
              items={globalValues.bezel_insert_materials}
              onChange={handleMultiSelectEdit}
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Bezel Insert Colors"
            value={form.bezel_insert_colors}
            isShowPage={isShowPage}
          >
            <MultiSelect
              name="bezel_insert_colors"
              items={globalValues.bezel_insert_colors}
              onChange={handleMultiSelectEdit}
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Bezel Features"
            value={form.bezel_features}
            isShowPage={isShowPage}
          >
            <MultiSelect
              name="bezel_features"
              items={globalValues.bezel_features}
              onChange={handleMultiSelectEdit}
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Crystal Material*"
            value={form.crystal_material}
            isShowPage={isShowPage}
          >
            <Select name="crystal_material" onChange={handleFormEdit} required>
              <ItemOptions items={globalValues.crystal_materials} />
            </Select>
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Water Resistance (m)*"
            value={form.water_resistance}
            isShowPage={isShowPage}
          >
            <TextInput
              name="water_resistance"
              onChange={handleFormEdit}
              type="number"
              required
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Case Materials*"
            value={form.case_materials}
            isShowPage={isShowPage}
          >
            <MultiSelect
              name="case_materials"
              items={globalValues.case_materials}
              onChange={handleMultiSelectEdit}
              required
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Case Size (mm)*"
            value={form.case_size}
            isShowPage={isShowPage}
          >
            <TextInput
              name="case_size"
              onChange={handleFormEdit}
              type="number"
              required
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Case Shape*"
            value={form.case_shape}
            isShowPage={isShowPage}
          >
            <Select name="case_shape" onChange={handleFormEdit} required>
              <ItemOptions items={globalValues.case_shape} />
            </Select>
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Case Back*"
            value={form.case_back}
            isShowPage={isShowPage}
          >
            <Select name="case_back" onChange={handleFormEdit} required>
              <ItemOptions items={globalValues.case_back} />
            </Select>
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Case Thickness (mm)"
            value={form.case_thickness}
            isShowPage={isShowPage}
          >
            <TextInput
              name="case_thickness"
              onChange={handleFormEdit}
              type="number"
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label={`Dial Colors${listing.smartwatch ? "" : "*"}`}
            value={form.dial_colors || []}
            isShowPage={isShowPage}
          >
            <MultiSelect
              name="dial_colors"
              items={globalValues.dial_colors}
              onChange={handleMultiSelectEdit}
              required={listing.smartwatch}
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Lume*"
            value={form.lume}
            isShowPage={isShowPage}
            type="bool"
          >
            <Select name="lume" onChange={handleFormEdit} required>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Display Type*"
            value={form.display_type}
            isShowPage={isShowPage}
          >
            <Select name="display_type" onChange={handleFormEdit} required>
              <ItemOptions items={globalValues.analog_digital} />
            </Select>
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Edition Type"
            value={form.edition_type}
            isShowPage={isShowPage}
          >
            <Select name="edition_type" onChange={handleFormEdit}>
              <ItemOptions items={["", ...(globalValues.edition_type || [])]} />
            </Select>
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Limited Quantity"
            value={form.limited_quantity}
            isShowPage={isShowPage}
          >
            <TextInput
              name="limited_quantity"
              onChange={handleFormEdit}
              type="number"
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Movement Type*"
            value={form.movement_type}
            isShowPage={isShowPage}
          >
            <Select name="movement_type" onChange={handleFormEdit} required>
              <ItemOptions items={globalValues.movement_type} />
            </Select>
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Manufacturer Caliber"
            value={form.manuf_caliber}
            isShowPage={isShowPage}
          >
            <TextInput name="manuf_caliber" onChange={handleFormEdit} />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Base Caliber"
            value={form.base_caliber}
            isShowPage={isShowPage}
          >
            <TextInput name="base_caliber" onChange={handleFormEdit} />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Power Reserve (hours)"
            value={form.power_reserve}
            isShowPage={isShowPage}
          >
            <TextInput
              name="power_reserve"
              onChange={handleFormEdit}
              type="number"
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Jewels"
            value={form.jewels}
            isShowPage={isShowPage}
          >
            <TextInput name="jewels" onChange={handleFormEdit} />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Complications"
            value={form.complications}
            isShowPage={isShowPage}
          >
            <MultiSelect
              name="complications"
              items={globalValues.complications}
              onChange={handleMultiSelectEdit}
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Calendar Type"
            value={form.calendar_type}
            isShowPage={isShowPage}
          >
            <Select name="calendar_type" onChange={handleFormEdit}>
              <ItemOptions
                items={["", ...(globalValues.calendar_type || [])]}
              />
            </Select>
          </InputWrapper>
        </div>
      </div>
    </div>
  );
});

export default FeatureSpec;
