import axios from 'axios';

const portalService = {
  fetchListings,
  generatedDescription,
  listing: listingEndpoint,
};

export default portalService;

function portalServiceRequest(endpoint, method='GET', body={}) {
  const req = {
    url: endpoint,
    method,
    credentials: 'same-origin',
    data: JSON.stringify(body),
    listing: "body",
    headers: {
      'Accept': '*/*',
      'Content-Type': 'text/plain;charset=UTF-8',
      'x-csrf-token': csrfToken(),
    },
  };

  return axios.request(req)
}

function portalServiceResponse(endpoint, method='GET', { success, error, body }) {
  portalServiceRequest(endpoint, method, body)
    .then(({data}) => success(data))
    .catch(error);
}

function csrfToken() {
  return document.querySelector('meta[name="csrf-token"]').content;
}

function generatedDescription(listing, success) {
  const body = new FormData();
  body.append('listing', listing);
  const opts = {
    body: { listing },
    success,
  };

  const lidArg = listing.lid ? `?lid=${listing.lid}` : '';
  portalServiceResponse(`/listings/description${lidArg}`, 'POST', opts);
}

function fetchListings(skus) {
  return portalServiceRequest(`/image_uploads/fetch_listings?skus=${skus}`)
}

function listingEndpoint(listing) {
  if (listing.lid){
    return portalServiceRequest(`/listings/${listing.lid}`, 'PUT', {listing});
  } else {
    return portalServiceRequest('/listings', 'POST', {listing})
  }
}
