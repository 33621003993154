import React from "react";
import { CaretDown, X } from "phosphor-react";
import classNames from "classnames";
import { Chip } from "components";

const MultiBox = ({
  readOnly,
  setExpanded,
  expanded,
  value,
  mappedItems,
  singleValue,
  label,
  handleItemSelect,
}) => (
  <div
    className="multi-box"
    onClick={() => !readOnly && setExpanded(!expanded)}
  >
    <div className="left">
      <div className={classNames("multi-title", { large: value.length === 0 })}>
        {label}
      </div>
      <div className="selected-items">
        {singleValue
          ? value && <div className="single-value">{mappedItems[value]}</div>
          : value[0] && (
              <Chip className="multi-select-item">
                {mappedItems[value[0]]}
                {value.length > 1 && ` +${value.length - 1}`}
                {!readOnly && (
                  <X
                    size={8}
                    weight="bold"
                    onClick={() =>
                      value.length < 2 && handleItemSelect(value[0])
                    }
                  />
                )}
              </Chip>
            )}
      </div>
    </div>
    <div className="right">
      <CaretDown size={16} weight="bold" />
    </div>
  </div>
);

export default MultiBox;
