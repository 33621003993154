<template>
  <b-form-group :label="labelName" >
    <i v-if="disabled" class="fa fa-lock field-icon locked-field-icon" />
    <i v-if="required && !localValue && !(isWaterResistance && localValue === '0')"
      class="fa fa-exclamation-triangle orange-font tt-error field-icon"
      :id="`tooltip-target-${inputId}`"
      v-b-tooltip.html
      title="Can't be blank" />

    <b-form-input :id="inputId"
      type="number"
      min="0"
      @keypress="validateNumber"
      :disabled="disabled"
      v-model="localValue"
      :class="inputClasses" />
  </b-form-group>
</template>

<script>
export default {
  name: 'numeric',
  props: {
    value: {
      type: String,
      default: '',
    },
    id: {},
    label: {},
    sublabel: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validity: {
      type: String,
    },
  },
  created() {
    if (!Number(this.localValue.toString().replace(/\D/g,'')) && !this.isWaterResistance)
      this.localValue = '';
  },
  data() { return {localValue: this.value} },
  computed: {
    isWaterResistance() {
      return this.label === 'Water Resistance (m)';
    },
    inputId() {
      if (this.id)
        return this.id;
      return `listing_${this.label}`.toLowerCase().replace(/\s/g, '_');
    },
    labelName() {
      const asterisk = this.required ? ' *' : '';
      return this.label + asterisk;
    },
    inputClasses() {
      let desiredClass = '';
      if (this.required && !this.localValue)
        desiredClass += 'field-warning-border'
      else if (this.numberErrors)
        desiredClass += 'is-invalid'
      if (!this.$slots.default)
        desiredClass += ' no-slot-field'
      return desiredClass;
    }
  },
  methods: {
    validateNumber: (event) => {
      const charCode = String.fromCharCode(event.keyCode);
      if (!/^[0-9.,]*$/.test(charCode)) {
        event.preventDefault();
      }
    },
  },
  watch: {
    value(newVal, _oldVal) {
      if (this.isWaterResistance && newVal === '0')
        this.localValue = '0';
      else {
        const number = Number((newVal || '').toString().replace(/\D/g,''))
        // we should store the blank value only in case of number is 0
        this.localValue = !number ? '' : newVal;
      }
    },
    localValue(newVal, oldVal) {
      this.$emit('input', this.localValue);
    },
  },
}
</script>
