<template>
  <b-form-group :label="labelName" v-bind:class="{ required: required }">
    <i v-if="disabled" class="fa fa-lock field-icon locked-field-icon" />
    <i v-if="required && !localValue"
      class="fa fa-exclamation-triangle orange-font tt-error field-icon"
      :id="`tooltip-target-${inputId}`"
      v-b-tooltip.html
      title="Can't be blank" />
    <b-input-group>
      <b-form-select :id="inputId"
        v-model="localValue"
        :disabled="disabled"
        :options="displayOptions"
        :reset-after="true"
        :required="required"
        :state="validCheck"
        :class="inputClasses"
        @change="$emit('change', localValue)"
      />
      <template #append v-if="$slots.default">
        <slot />
      </template>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: 'listing-form-input',
  props: {
    value: {
      type: String,
      default: '',
    },
    id: {},
    label: {},
    options: {},
    alt: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Boolean,
      default: false,
    },
    hodinkee_ineligible: {
      type: Boolean,
      default: false,
    },
    blank_element: {
      type: Boolean,
      default: false
    }
  },
  data() { return { localValue: this.value || '' }; },
  computed: {
    displayOptions() {
      let options;
      if (this.value && !this.hasValueInOptions(this.value, this.options)) {
        options = [this.value, ...(this.options || [])];
      } else {
        options = this.options;
      }

      if (this.blank_element && options[0] !== '')
        options.unshift('');

      return options;
    },
    disabledValue() {
      return this.alt || this.localValue;
    },
    inputId() {
      return this.id || `listing_${this.label}`.toLowerCase().replace(/\s/g, '_');
    },
    labelName() {
      const asterisk = this.required ? ' *' : '';
      return this.label + asterisk;
    },
    validCheck() {
      if (this.validate && this.required && !this.value)
        return 'invalid'
    },
    inputClasses() {
      let desiredClass = '';
      if (this.required && !this.localValue)
        desiredClass += 'field-warning-border'
      if (!this.$slots.default)
        desiredClass += ' no-slot-field'
      return desiredClass;
    }
  },
  methods: {
    hasValueInOptions(value, options = []) {
      return value && (
        options.includes(value) || options.find(o => o.value === value)
      );
    }
  },
  watch: {
    hodinkee_ineligible(newValue) {
      if (newValue) {
        this.localValue = 'crownandcaliber'
        this.$emit('change', 'crownandcaliber')
      }
    },
    value(newVal, _oldVal) {
      this.localValue = newVal;
    },
    localValue(newVal, oldVal) {
      this.$emit('input', this.localValue);
    },
  },
}
</script>
