import React, { useState } from "react";
import classNames from "classnames";
import { CaretUp, CaretDown } from "phosphor-react";
import { Collapse } from "react-collapse";
import {
  linkIsActive,
  isAnyChildrenActive,
  getVisibleLinksData,
} from "./helpers";

const CollapsibleItem = ({ children, isActive, text }) => {
  const [isOpened, setIsOpened] = useState(isActive);

  return (
    <li>
      <div className="item-element" onClick={() => setIsOpened(!isOpened)}>
        {isOpened ? <CaretUp size={24} /> : <CaretDown size={24} />}
        {text}
      </div>
      <Collapse isOpened={isOpened}>{children}</Collapse>
    </li>
  );
};

const TopLinks = ({ role, links, className }) => (
  <ul className={className}>
    {getVisibleLinksData(links).map(
      ({ href, text, Icon, children, hiddenForViewers = false }) =>
        children ? (
          <CollapsibleItem
            key={href}
            text={text}
            isActive={isAnyChildrenActive(children)}
          >
            <TopLinks
              links={children}
              role={role}
              className="submenu"
            />
          </CollapsibleItem>
        ) : (
          (role !== "viewer" || hiddenForViewers === false) && (
            <li
              key={href}
              className={classNames({
                active: linkIsActive(href),
              })}
            >
              <a href={href}>
                <Icon size={24} />
                {text}
              </a>
            </li>
          )
        )
    )}
  </ul>
);

export default TopLinks;
