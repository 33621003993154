import React, { forwardRef, useImperativeHandle, useReducer } from "react";
import { RichEditor } from "components";
import InputWrapper from "./input_wrapper";

const EvergreenContent = forwardRef(({ listing, isShowPage, comingSoon }, ref) => {
  if (comingSoon) {
    return (
      <div className="edit-form__container__content">
        <h2>Evergreen Content</h2>
        <div className="coming-soon">
          <span>Coming Soon...</span>
        </div>
      </div>
    );
  }

  const [form, setForm] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      dek: listing.dek || "",
      wwli: listing.wwli || "",
      backstory: listing.backstory || "",
      about_brand: listing.about_brand || "",
    }
  );

  useImperativeHandle(ref, () => ({
    getFormState: () => form,
  }));

  return (
    <div className="edit-form__container__content">
      <h2>Evergreen Content</h2>
      <div className="edit-form__container__content__rows">
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Dek"
            value={form.dek}
            type="html"
            isShowPage={isShowPage}
          >
            <RichEditor
              name="dek"
              text="Dek"
              isRequired
              onChange={(value) => setForm({ dek: value })}
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="Why We Love It"
            value={form.wwli}
            type="html"
            isShowPage={isShowPage}
          >
            <RichEditor
              name="wwli"
              text="Why We Love It"
              isRequired
              onChange={(value) => setForm({ wwli: value })}
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="The Backstory"
            value={form.backstory}
            type="html"
            isShowPage={isShowPage}
          >
            <RichEditor
              name="backstory"
              text="The Backstory"
              isRequired
              onChange={(value) => setForm({ backstory: value })}
            />
          </InputWrapper>
        </div>
        <div className="edit-form__container__content__rows__row">
          <InputWrapper
            label="About This Brand"
            value={form.about_brand}
            type="html"
            isShowPage={isShowPage}
          >
            <RichEditor
              name="about_brand"
              text="About This Brand"
              isRequired
              className="read-only"
              readOnly
              onChange={(value) => setForm({ about_brand: value })}
            />
          </InputWrapper>
        </div>
      </div>
    </div>
  );
});

export default EvergreenContent;
