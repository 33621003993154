import React from "react";
import classNames from "classnames";
import { CardLink } from "./card_link";

export const StateCard = ({
  title,
  name,
  icon,
  cCount,
  dCount,
  hCount,
  href,
  modernLayout,
  ...rest
}) => {
  const disabled = (modernLayout && hCount < 1) || (dCount + cCount + hCount < 1);

  return (
    <CardLink
      href={href}
      className={classNames("state-card", name)}
      disabled={disabled}
      {...rest}
    >
      <CardLink.Icon cardTitle={title} modernLayout={modernLayout} />
      <CardLink.Title>{title}</CardLink.Title>
      <CardLink.Separator />
      <div className="flex flex-wrap w-full justify-between">
        {!modernLayout && (
          <>
            <div className="mr-4 mb-2">
              <div className="desktop-label-5 mb-1">HODINKEE</div>
              <div className="desktop-label-3">{hCount}</div>
            </div>
            <div className="mr-4 mb-2">
              <div className="desktop-label-5 mb-1">C&C</div>
              <div className="desktop-label-3">{cCount}</div>
            </div>
            <div className="mr-4">
              <div className="desktop-label-5 mb-1">Dual Listed</div>
              <div className="desktop-label-3">{dCount}</div>
            </div>
          </>
        )}
        {modernLayout && (
          <>
            <div className="flex flex-col sm:w-full">
              <span className="desktop-label-3">{hCount}</span>
            </div>
          </>
        )}
      </div>
    </CardLink>
  );
};
