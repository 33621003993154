<template>
  <b-form-group :label="labelName" v-bind:class="{ required: required }">
    <template slot="label">
      <i v-if="customized" class="fa fa-pencil fa-sm green-text"></i>
      {{ labelName }}
    </template>
    <i v-if="disabled"
      class="fa fa-lock field-icon locked-field-icon"
      :class="customized ? 'field-icon-with-margin' : ''" />
    <i v-if="required && !localValue"
      class="fa fa-exclamation-triangle orange-font tt-error field-icon"
      :class="customized ? 'field-icon-with-margin' : ''"
      :id="`tooltip-target-${inputId}`"
      v-b-tooltip.html
      title="Can't be blank" />
    <span v-if="customized"
      class="field-dropdown-icon"
      :id="`${inputId}-customized-dropdown`"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false">
      <i class="fa fa-ellipsis-v"></i>
    </span>
    <div v-if="customized" class="dropdown-menu" :aria-labelledby="`${inputId}-customized-dropdown`">
      <a class="dropdown-item"
        href="#"
        @click.prevent="$emit('resetEdits')">Reset Edits</a>
    </div>
    <b-input-group @keydown="handleKeydown" @contextmenu="$emit('textInput');">
      <vue-editor :id="inputId"
        v-model="localValue"
        :disabled="disabled"
        :required="required"
        :editorToolbar="toolbar"
        :class="customClassIncluded"
        @change="$emit('change', localValue)"
        @text-change="$emit('rebuildLayout')" />
      <b-tooltip
        :show="!!warning"
        :disabled="!warning"
        :target="inputId"
        variant="danger"
        triggers="manual">
        {{ warning }}
      </b-tooltip>
    </b-input-group>
  </b-form-group>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: 'listing-form-editor',
  components: {
    VueEditor
  },
  props: {
    value: {},
    id: {},
    label: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    warning: {},
    required: {
      type: Boolean,
      default: false,
    },
    customized: {
      type: Boolean,
      default: false,
    },
    customClass: '',
  },
  data() { return {localValue: this.value} },
  computed: {
    inputId() {
      if (this.id)
        return this.id;
      return `listing_${this.label}`.toLowerCase().replace(/\s/g, '_');
    },
    labelName() {
      if (!this.label)
        return '';

      const asterisk = this.required ? ' *' : '';
      return this.label + asterisk;
    },
    customClassIncluded() {
      let desiredClass = this.customClass || '';
      if (this.disabled)
        desiredClass += ' disabled'
      if (this.required && !this.localValue)
        desiredClass += ' field-warning-border'
      if (!this.$slots.default)
        desiredClass += ' no-slot-field'
      return desiredClass;
    },
    toolbar() {
      if (this.disabled)
        return [[]]

      return [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["link"],
        ["clean"]
      ];
    },
  },
  methods: {
    handleKeydown(event) {
      const input = String.fromCharCode(event.keyCode);
      if (/[a-zA-Z0-9-_ ]/.test(input) || event.keyCode === 13)
        this.$emit('textInput');
    }
  },
  watch: {
    value(newVal, _oldVal) {
      this.localValue = newVal;
    },
    localValue(newVal, oldVal) {
      this.$emit('input', this.localValue);
    }
  },
}

</script>
