<template>
  <div id="stories-about-this-watch" class="card shadow-sm content-card">
    <span>
      <span v-if="customized" class="inline"><i class="fa fa-pencil fa-sm green-text p-b-0-75 m-r-sm"></i></span>
      <span class="inline">
        <h3 class="mb-4">
          Stories about this watch
       </h3>
      </span>
    </span>
    <span v-if="customized"
      class="field-dropdown-icon field-dropdown-icon-card-title"
      id="related-articles-customized-dropdown"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false">
      <i class="fa fa-ellipsis-v"></i>
    </span>
    <div v-if="customized" class="dropdown-menu" aria-labelledby="related-articles-customized-dropdown">
      <a class="dropdown-item"
        href="#"
        @click.prevent="resetEdits()">Reset Edits</a>
    </div>
    <b-row>
      <b-col cols="12" xl="6" class="mb-4">
        <div class="related-article-index">
          1.
        </div>
        <b-form-group class="related-article-field">
          <ListingFormInput v-model="listing.related_articles[0]"
            ref="related_article_0"
            label=""
            @textInput="flagAsCustomized('related_articles')" />
        </b-form-group>
      </b-col>

      <b-col cols="12" xl="6" class="mb-4">
        <div class="related-article-index">
          2.
        </div>
        <b-form-group class="related-article-field">
          <ListingFormInput v-model="listing.related_articles[1]"
            ref="related_article_1"
            label=""
            @textInput="flagAsCustomized('related_articles')" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xl="6" class="mb-4">
        <div class="related-article-index">
          3.
        </div>
        <b-form-group class="related-article-field">
          <ListingFormInput v-model="listing.related_articles[2]"
            ref="related_article_2"
            label=""
            @textInput="flagAsCustomized('related_articles')" />
        </b-form-group>
      </b-col>

      <b-col cols="12" xl="6" class="mb-4">
        <div class="related-article-index">
          4.
        </div>
        <b-form-group class="related-article-field">
          <ListingFormInput v-model="listing.related_articles[3]"
            ref="related_article_3"
            label=""
            @textInput="flagAsCustomized('related_articles')" />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '../stores/listingFormStore';
import ListingFormInput from './ListingFormInput';
import formUtils from '../services/formUtils';

export default {
  name: 'stories-about-this-watch',
  components: {
    ListingFormInput
  },
  data() {
    return {
      state: store.state,
    };
  },
  computed: {
    listing() { return store.state.listing },
    customized() { return formUtils.isCustomized('related_articles', store.state) },
  },
  methods: {
    resetEdits() { formUtils.resetFieldToDefaultValue('related_articles', store.state) },
    flagAsCustomized(field) { formUtils.flagFieldAsCustomized(field, store.state) }
  }
}

</script>

<style scoped>
  .related-article-index {
    float: left;
    margin-right: 0.5rem;
    line-height: 3rem;
    height: 3rem;
    font-family: "Roboto Mono";
    font-weight: 700;
    font-size: 0.9rem;
  }
</style>
