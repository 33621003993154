<template>
  <label :for="id" class="listing-form-checkbox">
    <input
      :id="id"
      :name="id"
      :checked="value"
      :disabled="disabled"
      type="checkbox"
      @change="$emit('change', localValue)"
      v-model="localValue"
    />
    {{label}}
  </label>
</template>

<script>
export default {
  name: 'listing-form-checkbox',
  props: {
    "id": { type: String, required: true },
    "label": { type: String, required: true },
    "disabled": { type: Boolean },
    "value": { type: Boolean },
  },
  watch: {
    value(newVal) {
      this.localValue = newVal;
    },
    localValue() {
      this.$emit('input', this.localValue);
    },
  },
  data() { return {localValue: this.value} },
}
</script>
