import React from "react";
import classNames from "classnames";
import { CardLink, StateCard } from "../components";

const DashboardPage = ({
  needsContentUnlistedCount,
  needsContentListedCount,
  updateToDualListedCount,
  modernLayout,
  states,
}) => {
  states = JSON.parse(states);
  const baseURL = modernLayout ? "/modern/listings" : "/listings";

  return (
    <div id="dashboard-page" className="pb-8 font-serif">
      <h1 className="modern-dashboard-title">
        {modernLayout ? "Modern Dashboard" : "Pre-Owned Dashboard"}
      </h1>
      {!modernLayout && (
        <>
          <h4 className="modern-dashboard-subtitle">Advanced Filters</h4>
          <div className="gap-6 top-links mb-6 md:grid grid two-wide-desktop">
            <CardLink
              href="/listings?advanced_filters[]=needs_content"
              className="top-card"
            >
              <CardLink.Icon cardTitle="Needs Content" />
              <CardLink.Title>Needs Content</CardLink.Title>
              <CardLink.Separator />
              <div className="flex flex-col sm:flex-row">
                <div className="flex flex-col sm:w-1/2">
                  <span className="desktop-label-5 mb-1">Unlisted</span>
                  <span className="desktop-label-3">
                    {needsContentUnlistedCount}
                  </span>
                </div>
                <div className="flex flex-col sm:w-1/2">
                  <span className="desktop-label-5 mb-1">Listed</span>
                  <span className="desktop-label-3">
                    {needsContentListedCount}
                  </span>
                </div>
              </div>
            </CardLink>
            <CardLink
              href="/listings?advanced_filters[]=update_to_dual_listed"
              className="top-card"
            >
              <CardLink.Icon cardTitle="Update to Dual Listed" />
              <CardLink.Title>Update to Dual Listed</CardLink.Title>
              <CardLink.Separator />
              <div className="flex flex-row">
                <div className="flex flex-col sm:w-1/2">
                  <span className="desktop-label-5 mb-1">Ready</span>
                  <span className="desktop-label-3">
                    {updateToDualListedCount}
                  </span>
                </div>
              </div>
            </CardLink>
          </div>
        </>
      )}
      <h4 className="modern-dashboard-subtitle">Listings by Status</h4>
      <div
        className={classNames({
          "modern-states": modernLayout,
          "grid gap-6 states sm:modern-n-wide": !modernLayout,
        })}
      >
        {states.map((state) => (
          <StateCard
            key={state.name}
            href={`${baseURL}?states[]=${state.name}`}
            modernLayout={modernLayout}
            {...state}
          />
        ))}
      </div>
    </div>
  );
};

export default DashboardPage;
