<template>
  <div id="watch-details" class="card shadow-sm content-card">
    <div id="watch-details-title-container">
      <h3 class="mb-4">
        Watch details
      </h3>
      <ListingFormCheckbox v-model="listing.hodinkee_ineligible"
        id="hodinkee_ineligible"
        label="H Shop ineligible"
        :disabled="state.ccspecHodinkeeIneligible || lockedToBeListed"
        :value="listing.hodinkee_ineligible" />
    </div>

    <!-- row 1: sku, serial number, brand name, shop -->
    <b-row>
      <b-col md="3">
        <ListingFormInput v-model="listing.sku"
          label="SKU"
          :disabled="true" />
      </b-col>

      <b-col md="3">
        <ListingFormInput v-model="listing.serial_number"
          label="Serial Number"
          :disabled="true" />
      </b-col>

      <b-col md="3">
        <ListingFormSelect v-model="state.brandName"
          label="Brand Name"
          :disabled="!state.newListing"
          :options="brandOptions"
          :validate="!state.valid"
          @input="handleBrandChange"
          required />
      </b-col>
      <b-col md="3">
        <ListingFormSelect v-model="listing.shop"
        label="Shop"
        :hodinkee_ineligible="listing.hodinkee_ineligible"
        :options="state.shops"
        :disabled="listing.hodinkee_ineligible || lockedToBeListed"
        required />
      </b-col>
    </b-row>

    <!-- row 2: model name, nickname, model number, merchandising model number -->
    <b-row class="mt-3">
      <b-col md="3">
        <ListingFormSelect v-model="state.modelName"
          v-if="!customModel && modelOptions.length"
          ref="modelName"
          label="Model Name"
          :disabled="!canEditSpecifications"
          :options="modelOptions"
          :validate="!state.valid"
          @input="handleModelNameChange"
          required
        >
          <b-button
            id="toggleModelInput"
            class="fa fa-plus btn-light"
            v-if="canEditSpecifications"
            @click="toggleCustomModel" />
          <b-tooltip target="toggleModelInput" title="Add new model name" />
        </ListingFormSelect>

        <ListingFormInput v-model="state.modelName"
          v-if="customModel || !modelOptions.length"
          ref="modelName"
          label="Model Name"
          :warning="noModelsWarning"
          :disabled="!canEditSpecifications"
          :validate="!state.valid"
          @input="handleModelNameChange"
          required
        >
          <b-button
            class="fa fa-list btn-light"
            id="toggleModelSelect"
            v-if="canEditSpecifications && modelOptions.length && customModel"
            @click="toggleCustomModel" />
          <b-tooltip target="toggleModelSelect" title="Select model name" />
      </ListingFormInput>
      </b-col>

      <b-col md="3">
        <ListingFormInput v-model="listing.nickname"
          ref="nickname"
          label="Nickname"
          @input="handleNicknameChange" />
      </b-col>
      <b-col md="3">
        <ListingFormSelect v-model="state.modelNumber"
          v-if="!customModelNumber && modelNumberOptions.length"
          ref="modelNumber"
          label="Model Number"
          :disabled="!canEditSpecifications"
          :options="modelNumberOptions"
          :validate="!state.valid"
          @input="handleModelNumberChange"
          required
        >
          <b-button
            id="toggleModelInput"
            class="fa fa-plus btn-light"
            v-if="canEditSpecifications"
            @click="toggleCustomModelNumber" />
          <b-tooltip target="toggleModelInput" title="Add new model name" />
      </ListingFormSelect>

      <ListingFormInput v-model="state.modelNumber"
        v-if="customModelNumber || !modelNumberOptions.length"
        ref="modelNumber"
        label="Model Number"
        :warning="noModelNumbersWarning"
        :disabled="!canEditSpecifications"
        :validate="!state.valid"
        @input="handleModelNumberChange"
        required
      >
        <b-button
          class="fa fa-list btn-light"
          id="toggleModelNumberSelect"
          v-if="canEditSpecifications && modelNumberOptions.length && customModelNumber"
          @click="toggleCustomModelNumber" />
        <b-tooltip target="toggleModelNumberSelect" title="Select model number" />
      </ListingFormInput>
      </b-col>

      <b-col md="3">
        <ListingFormInput v-model="listing.merchandising_model_number"
          label="Merchandising Model Number"
          @input="handleMerchModelNumberChange"
          required
        />
      </b-col>
    </b-row>

    <!-- row 3: list price, cogs (net price), retail price, external SKU -->
    <b-row class="mt-3">
      <b-col md="3">
        <Price label="List Price"
          :required="true"
          :value="localList"
          :validity="presentPrice"
          :disabled="!managerUser"
          @keyup="handleListPriceChange"
        />
      </b-col>

      <b-col md="3">
        <Price label="COGS"
          :required="true"
          :value="localNet"
          :validity="presentPrice"
          :disabled="true"
        />
      </b-col>

      <b-col md="3">
        <Price label="Retail Price" v-model="listing.retail_price" />
      </b-col>

      <b-col md="3">
        <ListingFormInput v-model="listing.external_sku"
          v-if="isDropship"
          label="External SKU"
          :disabled="true" />
      </b-col>
    </b-row>

    <!-- row 3: list price, cogs (net price), retail price, external SKU -->
    <b-row>
      <b-col md="3">
        <!-- :validity="presentPrice" -->
        <Price label="Dropship Cost"
          v-if="isDropship"
          :required="true"
          :value="localDropshipCost"
          @keyup="handleDropshipCostChange"
        />
      </b-col>
      <b-col md="3">
        <div class="flex">
          <ListingFormInput label="CC Spec"
            v-model="listing.ccspec_number"
            v-if="isDropship && !lockedToBeListed"
            :disabled="!managerUser"
          >
            <b-button
              id="submitCcSpec"
              class="btn-light"
              v-if="managerUser"
              @click="fetchCcSpecData"
            >🔄</b-button>
            <b-tooltip
              target="submitCcSpec"
              title="Fill listing with data for a given CC Spec (fill field to the left, then click this button)"
            />
          </ListingFormInput>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '../stores/listingFormStore';
import ListingFormInput from './ListingFormInput';
import ListingFormSelect from './ListingFormSelect';
import ListingFormCheckbox from './ListingFormCheckbox';
import Price from './Price';
import priceUtils from '../services/priceUtils';
import formUtils from '../services/formUtils';
import utils from '../services/utils';

export default {
  name: 'watch-details',
  components: {
    ListingFormInput,
    ListingFormSelect,
    ListingFormCheckbox,
    Price,
  },
  data() {
    return {
      state: store.state,
      customModel: false,
      customModelNumber: false,
    };
  },
  computed: {
    brandOptions() { return (store.state.brands || []).map(b => b.name) },
    isDropship() { return store?.state?.listing?.sku?.startsWith('10-21-') },
    lockedToBeListed() { return ['listed', 'ready_to_list'].includes(store.state.listing.status) },
    modelOptions() { return (store.state.models || []).map(m => m.name) },
    modelNumberOptions() { return (store.state.modelNumbers || []).map(m => m.model_number) },
    listing() { return store.state.listing },
    options() { return store.state.globalAttributes },
    editorUser() { return ["editor", "manager", "admin"].includes(store.state.user.role) },
    managerUser() { return ["manager", "admin"].includes(store.state.user.role) },
    canEditSpecifications() { return this.state.newListing || (this.editorUser && !this.hasSpec) },
    hasSpec() { return formUtils.hasSpec(store.state) },
    noModelsWarning() {
      return this.state.brandName
        && this.state.newListing
        && this.state.modelsLoaded
        && !this.modelOptions.length
        && 'There are no matching model names for this brand.';
    },
    noModelNumbersWarning() {
      return this.state.modelName
        && this.state.modelNumbersLoaded
        && !this.modelNumberOptions.length
        && 'There are no matching model numbers for this model.';
    },
    localDropshipCost() {
      return priceUtils.formatToCurrency(this.listing.dropship_cost_precise);
    },
    localList() {
      return priceUtils.formatToCurrency(this.listing.list_price_precise);
    },
    localNet() {
      return priceUtils.formatToCurrency(this.listing.net_price_precise);
    },
    presentPrice() {
      if (!this.state.valid && (!this.listing.list_price_precise || !this.listing.net_price_precise ))
        return 'invalid';
    },
  },
  methods: {
    handleBrandChange: store.handleBrandChange,
    handleDropshipCostChange: store.handleDropshipCostChange,
    handleModelNameChange: utils.debounce((val) => {
      store.handleModelNameChange(!this.customModel && val);
    }, 800),
    handleModelNumberChange: utils.debounce((val) => {
      store.handleModelNumberChange(!this.customModelNumber && val);
    }, 800),
    handleMerchModelNumberChange: utils.debounce((val) => {
      store.handleMerchModelNumberChange(!this.customModelNumber && val);
    }, 800),
    handleNicknameChange: utils.debounce((val) => {
      store.handleNicknameChange();
    }, 800),
    toggleCustomModel() { this.customModel = !this.customModel; },
    toggleCustomModelNumber() { this.customModelNumber = !this.customModelNumber; },
    handleListPriceChange: store.handleListPriceChange,
    fetchCcSpecData: store.fetchCcSpecData,
  },
};

</script>
