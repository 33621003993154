import React from "react";
import TopLinks from "./top_links";
import HodinkeeLogoLink from "./hodinkee_logo_link";
import BottomLinks from "./bottom_links";

const DesktopSidebar = ({ fullName, role, ...rest }) => (
  <div {...rest}>
    <div className="p-8">
      <HodinkeeLogoLink />
    </div>
    <nav>
      <TopLinks
        className="mb-0 first-level"
        role={role}
      />
      <ul className="mb-0">
        <BottomLinks fullName={fullName} />
      </ul>
    </nav>
  </div>
);

export default DesktopSidebar;
