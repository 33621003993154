const requiredFields = [
  "dek",
  "wwli",
  "backstory",
  "about_brand",
  "related_articles",
];

export const protectedStates = [
  "listed",
  "ready_to_list",
];

export const validation = (listing, isRequired) => {
  const currentRelatedArticlesCount = listing.related_articles.reduce(
    (i, ra) => (isEmpty(ra) ? i : i + 1),
    0
  );

  const validationErrors =
    currentRelatedArticlesCount < 1
      ? ["Stories About This Watch Articles can't be empty"]
      : [];

  return [
    ...validationErrors,
    ...requiredFields
      .filter((field) => isRequired(field) && isEmpty(listing[field]))
      .map((field) => `${field} is required`),
  ];
};
export const snakeToTitleCase = (str) => {
  return str
    .split("_")
    .filter((x) => x.length > 0)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(" ");
};

export const isEmpty = (htmlStr) => {
  return !htmlStr || htmlStr === "<p><br></p>";
};
