<template>
  <b-row id="alerts" >
    <br />
      <b-alert :variant="variant" :show="!!displayAlerts.length" >
        <ul>
          <li v-for="(msg, i) in displayAlerts" :key="i">
            {{ convertedMsg(msg) }}
          </li>
        </ul>
      </b-alert>
  </b-row>
</template>

<script>
import utils from '../services/utils';

export default {
  name: 'alerts',
  props: ['messages', 'variant'],
  computed: {
    displayAlerts() {
      if (Array.isArray(this.messages)) return this.messages;

      return Object.keys(this.messages).reduce((all, k) => {
        const next = translateError(k, this.messages[k]);
        return next ? [...all, ...next] : all
      }, []);
    },
  },
  methods: {
    convertedMsg(msg) {
      if (msg.match(/^Condition.?/))
        return msg.replace('Condition', 'Condition Rating');
      else if (msg.match(/^Wwli.?/))
        return msg.replace('Wwli', 'Why We Love It');
      else if (msg.match(/^Backstory.?/))
        return msg.replace('Backstory', 'The Backstory');
      else if (msg.match(/^About Brand.?/))
        return msg.replace('About Brand', 'About the Brand');

      return msg;
    }
  }
}

function translateError(key, message) {
  if (message && !!message.length)
    return key === 'messages' ? message : [`${utils.humanize(key)}: ${message}`];
}
</script>
