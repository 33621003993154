import React, { useState, useEffect } from "react";
import { CaretLeft } from "phosphor-react";
import { getName } from "global-helpers/listing";
import { Button } from "components";
import { updateStateModernListing } from "global-helpers/listing";
import { useNavigate } from "react-router-dom";

import StateSelectComponent from "../state_select";
import { Link } from "react-router-dom";

import "./header.scss";

const Header = ({ modernListing, isShowPage, onSave, policy }) => {
  const [currentState, setCurrentState] = useState(modernListing.state);
  const [events, setEvents] = useState(modernListing.possible_events);
  const navigate = useNavigate();

  const changeState = async (event) => {
    const { response, status } = await updateStateModernListing(
      modernListing.id,
      event
    );

    if (status === 200) {
      if (response.state) setCurrentState(response.state);
      if (response.possible_events) setEvents(response.possible_events);
    } else {
      navigate({
        pathname: `/modern/listings/${modernListing.id}/edit`,
        search: `?${new URLSearchParams({ validate_for_event: event })}`,
      });
    }
  };
  useEffect(() => {
    setCurrentState(modernListing.state);
    setEvents(modernListing.possible_events);
  }, [modernListing.state]);

  return (
    <div className="edit__header">
      <Link
        className="edit__header__a"
        to={
          isShowPage
            ? `/modern/listings`
            : `/modern/listings/${modernListing.id}`
        }
      >
        <CaretLeft size={16} />
        {isShowPage ? "BACK TO MODERN LISTINGS" : "BACK TO LISTING DETAILS"}
      </Link>

      <div className="edit__header__container">
        <div className="edit__header__container__info">
          <h1>{getName(modernListing)}</h1>
        </div>

        <div className="edit__header__container__actions">
          <div className="edit__header__container__actions__wrapper">
            {isShowPage ? (
              policy.edit && (
                <Button
                  variant="primary"
                  href={`/modern/listings/${modernListing.id}/edit`}
                  isSpa
                >
                  Edit
                </Button>
              )
            ) : (
              <>
                <Button
                  variant="secondary"
                  href={`/modern/listings/${modernListing.id}`}
                  isSpa
                >
                  view listing
                </Button>
                <Button variant="primary" onClick={onSave}>
                  save
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      {isShowPage && (
        <StateSelectComponent
          value={currentState}
          possibleEvents={events}
          onChange={(_, value) => changeState(value)}
          singleValue
          readOnly={!policy.edit}
          stateChange
        />
      )}
    </div>
  );
};

export default Header;
